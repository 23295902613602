import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import CreativeEditorSDK, {
  BlockAPI,
  Configuration,
  DesignBlockType,
  DesignUnit,
  EditorAPI,
  PageFormatDefinition,
  RGBA,
  SceneAPI,
  SettingsBool,
} from '@cesdk/cesdk-js';
import {
  ICloudinaryUploadResponse,
  ILayoutEditorCanvas,
  ILayoutEditorSource,
  IRegionAction,
  ISmilRegion,
} from '@desquare/interfaces';
import {
  getRandomArbitrary,
  IRegionBlock,
  IUpdateBlockInput,
  IUpdateCanvas,
  IUpdateSelectedBlockInput,
  cesdkRgbaToHex,
  ZPositionMovement,
  hsvToRgb,
} from '@desquare/utils';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { CloudinaryService } from '../cloudinary/cloudinary.service';
import { LayoutType } from '@desquare/enums';

const lic =
  'eyJhbGciOiAiUlMyNTYiLCJ0eXAiOiAiSldUIn0=.ewogICJ2ZXJzaW9uIjogMSwKICAiZXhwIjogMjUzMjc3MzQ0NywKICAic3ViIjogIkRlU3F1YXJlIiwKICAidHlwZSI6ICJQcm9kdWN0aW9uIiwKICAicHJvZHVjdFZlcnNpb24iOiAxLAogICJwbGF0Zm9ybXMiOiBbIioiXQp9.pGd35gqY+dC0e0WSkUu7qFEglDSTJTgQj9ZIMAcAs7UNckBfQ0jx3TXYznfGpAVKelYiUx9snJQGN8U08RXCNOBk4FlOsMtykwcOl8oS4YwirgSs8FexAb4z310J7pE4MF/THCs+bG11DSKn33AUoim/VReNimhn5Ga1gHczhU8gj4BpaXEYVXQNn8/2KbZa+OWVyfTZV1pyjanTU21IXrDqzEJtgspQ8RysjJ6EkVz6oUQA0aJHGP4kWP5ou7VjxRxcXiHr+bkwZYbx2S3Z98zA+PtX5QtcQVJXwfoZSq8oZOUCVa/0Mf+WNL/x0mVmeUActzFB9gp9vJUqgzuRDmYa+9aKcogpKqxLtYhXEPjW1Q7oZdqVQbHfzpMmgP+IKMddMoL7aUi2+nn8hfNE3FjLJWiysp/hnuU2KQzoJqNkVgSXZBJJXr2uA4qDx6evRPeQvsej/5wVRV02dwx6cFJLPttvScGz7GemlOrHTcnGmzjeUb7TVy6044VhJGjB';

const BACKGROUND_BLOCK_NAME = 'BACKGROUND_BLOCK_NAME';
const DEFAULT_TOUCH_REGION_OPACITY = 0.6;
const ACTION_META_KEY = 'ACTION';

enum DesignBlockTypeEnum {
  Scene = '//ly.img.ubq/scene',
  Stack = '//ly.img.ubq/stack',
  Camera = '//ly.img.ubq/camera',
  Page = '//ly.img.ubq/page',
  Image = '//ly.img.ubq/image',
  Video = '//ly.img.ubq/video',
  Text = '//ly.img.ubq/text',
  Sticker = '//ly.img.ubq/sticker',
  VectorPath = '//ly.img.ubq/vector_path',
  RectShape = '//ly.img.ubq/shapes/rect',
  LineShape = '//ly.img.ubq/shapes/line',
  StarShape = '//ly.img.ubq/shapes/star',
  PolygonShape = '//ly.img.ubq/shapes/polygon',
  EllipseShape = '//ly.img.ubq/shapes/ellipse',
  Group = '//ly.img.ubq/group',
}

@Injectable({
  providedIn: null,
  // note: providedIn: null makes the service to be only instantiated if it
  // was injected into the component (added to the providers array).
  // If the component's children uses this service it will use the same
  // instance with the parent. More info: https://stackoverflow.com/a/72561645
})
export class LayoutEditorService implements OnDestroy {
  // These required variables are instantiated in the
  // initCreativeEditor() function
  creativeEditor!: CreativeEditorSDK;
  ceBlock!: BlockAPI;
  ceScene!: SceneAPI;
  ceEditor!: EditorAPI;
  unsubscribeEditor!: () => void;

  // this type assertion looks ugly but its the only known way as of now to make this work
  // REGION_BLOCK_TYPE =
  //   DesignBlockTypeEnum.Group as unknown as DesignBlockType.Group;
  REGION_BLOCK_TYPE =
    DesignBlockTypeEnum.Text as unknown as DesignBlockTypeEnum.Text;

  private subs = new SubSink();

  lastSelectedBlock$ = new BehaviorSubject<number | null>(null);

  // TODO here: convert this later as piped streams from lastSelectedBlock$
  lastSelectedRegionBlock$ = new BehaviorSubject<IRegionBlock | null>(null);

  canvas$ = new BehaviorSubject<ILayoutEditorCanvas | null>(null);

  layoutType: LayoutType = LayoutType.Standard;

  constructor(private cloudinaryService: CloudinaryService) {}

  ngOnDestroy(): void {
    // This activates when closing the component (like leaving the page)
    // where the service is injected, make sure the service should be
    // added in the providers: https://angular.io/guide/providers#limiting-provider-scope-with-components

    // console.log('Layout Editor Service Destroyed'); // DEBUG
    this.closeCreativeEditor();
  }

  /**
   * This checks if the block is a region block.
   * - a region block is a designage concept, currently the region block
   * is of type text block in cesdk but this may change.
   *
   * @param targetBlockId
   * @returns
   */
  isRegionBlock(targetBlockId: number) {
    const blockApi = this.ceBlock;

    return blockApi.getType(targetBlockId) === this.REGION_BLOCK_TYPE;
  }

  /**
   * Initializes the creative editor. The variables creativeEditor, ceBlock,
   * ceScene is dependent on this function, this function should run first before
   * using any other functions within the service. Also this subscribes the editor
   *
   * @param containerRef - reference to the container element in the template
   * @param config - (optional) configuration of the editor
   */
  async initCreativeEditor(
    containerRef: ElementRef,
    config?: Configuration,
    layoutType: LayoutType = LayoutType.Standard
  ) {
    this.layoutType = layoutType;
    this.creativeEditor = await CreativeEditorSDK.create(
      containerRef.nativeElement,
      config ?? this.getDefaultLayoutEditorConfiguration()
    );
    await this.creativeEditor.createDesignScene(this.getPageFormats().FullHD);
    this.ceBlock = this.creativeEditor.engine.block;
    this.ceScene = this.creativeEditor.engine.scene;
    this.ceEditor = this.creativeEditor.engine.editor;

    await this.configureEditorApi(this.ceEditor);
    await this.configureBlockApi(this.ceBlock);

    // init subscriptions
    this.unsubscribeEditor = this.initEditorSubscription();
    this.initSelectedBlockSubscription();
  }

  /**
   * configure editor api for layout editor
   * @param editorApi
   */
  // private async configureEditorApi(editorApi: EditorAPI) {
  private async configureEditorApi(editorApi: EditorAPI) {
    // TODO: Temporary until IMG.LY fixes the types
    // hide the rotation control for the blocks
    editorApi.setSettingBool('controlGizmo/showRotateHandles', false);
    editorApi.setSettingBool('page/title/show', false);
  }

  /**
   * configure block api for layout editor
   * @param blockApi
   */
  private async configureBlockApi(blockApi: BlockAPI) {
    // set first page block (canvas) color to black
    blockApi.setFillSolidColor(this.getFirstPageBlock(), 0, 0, 0);
  }

  getFirstPageBlock() {
    return this.ceScene.getPages()[0];
  }

  getDefaultLayoutEditorConfiguration() {
    const config: Configuration = {
      license: lic,
      theme: 'dark', // 'light' or 'dark'
      ui: {
        // this is for the canvas dimensions
        pageFormats: this.getPageFormats(),
        // this disables the default ui of creative editor
        hide: true,
      },
    };
    return config;
  }

  getPageFormats() {
    const result: { [id: string]: PageFormatDefinition } = {
      // PageFormatDefinition
      FullHD: {
        default: true,
        width: 1920,
        height: 1080,
        unit: 'Pixel',
      },
      /*Portrait: {
        width: 1080,
        height: 1920,
        unit: DesignUnit.Pixel,
      },*/
    };
    return result;
  }

  /**
   * Unsubscribes from the editor then disposes the editor instance within the service.
   */
  closeCreativeEditor() {
    this.subs.unsubscribe();
    this.unsubscribeEditor();
    this.creativeEditor.dispose();
  }

  /** this function returns a void function to unsubscribe from the editor */
  initEditorSubscription() {
    const engine = this.creativeEditor.engine;

    return engine.event.subscribe([], (events) => {
      events.forEach((event) => {
        // console.log('Event: ', event.type, event.block); // DEBUG

        switch (event.type) {
          case 'Updated': {
            if (engine.block.isValid(event.block)) {
              // this.preventPageFromBeingSelected(event.block);

              // prevent background from being selected
              if (engine.block.getName(event.block) === BACKGROUND_BLOCK_NAME) {
                if (engine.block.getBool(event.block, 'selected')) {
                  engine.block.setBool(event.block, 'selected', false);
                }
                return;
              }

              // observe page block
              this.canvas$.next({
                width: this.ceBlock.getWidth(this.getFirstPageBlock()),
                height: this.ceBlock.getHeight(this.getFirstPageBlock()),
                color: cesdkRgbaToHex(
                  this.ceBlock.getFillSolidColor(this.getFirstPageBlock())
                ),
              });

              // fix block properties
              this.setFixedTextBlockProperties(event.block);

              this.setSelectedBlock(event.block);
            }
            break;
          }

          case 'Created': {
            if (engine.block.isValid(event.block)) {
              // this.preventPageFromBeingSelected(event.block);

              this.setSelectedBlock(event.block);
            }
            break;
          }

          case 'Destroyed': {
            this.clearSelectedBlockVariable();
            break;
          }

          default:
            break;
        }
      });
    });
  }

  /**
   * This function prevents a runtime error that occurs when the page
   * block is selected then loading a different scene
   * @param eventBlock
   */
  preventPageFromBeingSelected(eventBlock: number) {
    const blockApi = this.ceBlock;
    if (
      eventBlock === this.getFirstPageBlock() &&
      blockApi.getBool(eventBlock, 'selected')
    ) {
      blockApi.setBool(eventBlock, 'selected', false);

      this.clearSelectedBlockVariable();
    }
  }

  setSelectedBlock(blockId: number) {
    const engine = this.ceBlock;

    // check selected property
    // note: only region blocks can be selected in the layout editor
    if (this.isRegionBlock(blockId) && engine.getBool(blockId, 'selected')) {
      // As of now we don't allow multiple select so we clear the other selected
      this.clearAllOtherSelectedBlocks(blockId);
      // set selected block
      this.lastSelectedBlock$.next(blockId);
    }
  }

  /**
   * This is primarily used in the subscription of block changes so that it always activates.
   * This is to make sure these properties always are fixed
   * @param targetBlockId
   * @returns
   */
  setFixedTextBlockProperties(targetBlockId: number) {
    const blockApi = this.ceBlock;

    // guard block type, should be DesignBlockType.Text
    if (!this.isRegionBlock(targetBlockId)) return;

    // fix rotation
    // blockApi.setFloat(targetBlockId, 'rotation', 0);
    // note: no longer needed since v1.11, they have added settings to hide the rotation control

    // fix font size
    blockApi.setFloat(targetBlockId, 'text/fontSize', 16);

    // text is always the blocks name
    blockApi.setString(
      targetBlockId,
      'text/text',
      blockApi.getName(targetBlockId)
    );

    // round off block dimensions
    blockApi.setWidth(
      targetBlockId,
      Math.round(blockApi.getWidth(targetBlockId))
    );
    blockApi.setHeight(
      targetBlockId,
      Math.round(blockApi.getHeight(targetBlockId))
    );

    // round off block positions
    blockApi.setPositionX(
      targetBlockId,
      Math.round(blockApi.getPositionX(targetBlockId))
    );
    blockApi.setPositionY(
      targetBlockId,
      Math.round(blockApi.getPositionY(targetBlockId))
    );
  }

  initSelectedBlockSubscription() {
    const engine = this.creativeEditor.engine;
    if (!engine) return console.error('no engine');

    this.subs.sink = this.lastSelectedBlock$.subscribe((selectedBlock) => {
      if (selectedBlock) {
        const eventBlockType = engine.block.getType(selectedBlock);
        // console.log('Block type: ', eventBlockType); // DEBUG

        if (eventBlockType === this.REGION_BLOCK_TYPE) {
          // extract to create a IRegionBlock object
          this.lastSelectedRegionBlock$.next({
            id: selectedBlock,
            name: this.ceBlock.getName(selectedBlock),
            width: this.ceBlock.getWidth(selectedBlock),
            height: this.ceBlock.getHeight(selectedBlock),
            top: this.ceBlock.getPositionY(selectedBlock),
            left: this.ceBlock.getPositionX(selectedBlock),
            action: this.getAction(selectedBlock),
          });
        }

        // console.log('all props', engine.block.findAllProperties(selectedBlock)); // DEBUG

        // console.log(
        //   'all children of First Page: ',
        //   this.ceBlock.getChildren(this.getFirstPageBlock())
        // ); // DEBUG
      } else {
        // clear these variables is selected block = null
        this.lastSelectedRegionBlock$.next(null);
      }
    });
  }

  getActionMeta(blockId: number) {
    try {
      return this.ceBlock.getMetadata(blockId, ACTION_META_KEY);
    } catch {
      return '';
    }
  }
  getAction(blockId: number) {
    const meta = this.getActionMeta(blockId);
    if (meta.length > 0) {
      return JSON.parse(meta) as IRegionAction;
    } else {
      return undefined;
    }
  }
  setAction(blockId: number, action?: IRegionAction) {
    console.log('setAction', action);
    this.ceBlock.setMetadata(
      blockId,
      ACTION_META_KEY,
      action ? JSON.stringify(action) : ''
    );
  }

  // saveRegionBlockName(name: string) {
  //   const blockApi = this.ceBlock;
  //   const targetBlock = this.lastSelectedRegionBlock$.value;

  //   if (!targetBlock) return console.error('selectedRegionBlock is null');
  //   blockApi.setName(targetBlock, name);

  //   // set text to block name
  //   blockApi.setString(targetBlock, 'text/text', name);
  // }

  /**
   * This is a workaround wrapper function to block API's createBlock().
   * Caused by a runtime error which causes the block id to go outside
   * the max Int range ([-2147483648, 2147483647]]). Remove this
   * when imgly fixes the blockId generation
   * @param blockType
   * @returns block Id
   */
  createBlock(blockType: DesignBlockType): number {
    const blockApi = this.ceBlock;

    while (true) {
      let blockId = blockApi.create(blockType);
      if (blockId < 2147483647 && blockId > -2147483648) return blockId;
      // console.log('REDO BLOCK ID'); // DEBUG
      blockApi.destroy(blockId);
    }
  }

  /**
   * This is a workaround wrapper function to block API's group().
   * Caused by a runtime error which causes the block id to go outside
   * the max Int range ([-2147483648, 2147483647]]). Remove this
   * when imgly fixes the blockId generation
   * @param blockIds
   * @returns group block Id
   */
  group(blockIds: number[]): number {
    const blockApi = this.ceBlock;

    while (true) {
      let groupId = blockApi.group(blockIds);
      if (groupId < 2147483647 && groupId > -2147483648) return groupId;
      // console.log('REDO GROUP ID'); // DEBUG
      blockApi.ungroup(groupId);
    }
  }

  fixLayoutBlocksDefaults() {
    if (this.layoutType === LayoutType.Interactive) {
      const blockApi = this.ceBlock;
      const blocks = this.getRegionBlocks();
      for (const b of blocks) {
        blockApi.setOpacity(b.id, DEFAULT_TOUCH_REGION_OPACITY);
      }
    }
  }
  createBackgroundBlock(url: string) {
    this.fixLayoutBlocksDefaults();

    const blockApi = this.ceBlock;
    const pageBlockId = this.getFirstPageBlock();

    const bgBlockId = blockApi.create('graphic');
    const bgRect = blockApi.createShape('rect');
    const bgFill = blockApi.createFill('image');

    blockApi.appendChild(pageBlockId, bgBlockId);

    // identify block as background
    blockApi.setName(bgBlockId, BACKGROUND_BLOCK_NAME);

    // set zorder
    this.updateBlock({
      id: bgBlockId,
      zPositionMovement: ZPositionMovement.bottom,
    });

    // set background image
    blockApi.setString(bgFill, 'fill/image/imageFileURI', url);
    blockApi.setShape(bgBlockId, bgRect);
    blockApi.setFill(bgBlockId, bgFill);
    blockApi.setKind(bgBlockId, 'image');

    // set correct size
    blockApi.setEnum(bgBlockId, 'position/x/mode', 'Percent');
    blockApi.setEnum(bgBlockId, 'position/y/mode', 'Percent');
    // blockApi.setEnum(bgBlockId, 'width/mode', 'Percent');
    // blockApi.setEnum(bgBlockId, 'height/mode', 'Percent');
    blockApi.setFloat(
      bgBlockId,
      'width',
      blockApi.getFloat(pageBlockId, 'width')
    );
    blockApi.setFloat(
      bgBlockId,
      'height',
      blockApi.getFloat(pageBlockId, 'height')
    );

    return bgBlockId;
  }
  getBackgroundBlockId() {
    const blockApi = this.ceBlock;
    return blockApi.findByName(BACKGROUND_BLOCK_NAME);
  }
  deleteBackground() {
    const blockApi = this.ceBlock;
    const bgBlockIds = this.getBackgroundBlockId();
    if (bgBlockIds.length > 0) {
      for (const bgBlockId of bgBlockIds) {
        blockApi.destroy(bgBlockId);
      }
    }
  }

  createRegionBlock() {
    const blockApi = this.ceBlock;
    const pageBlockId = this.getFirstPageBlock();

    const regionBlockId = this.createBlock(this.REGION_BLOCK_TYPE);

    blockApi.appendChild(pageBlockId, regionBlockId);

    // generate region name
    blockApi.setName(regionBlockId, this.generateRegionName());

    if (this.layoutType === LayoutType.Interactive) {
      blockApi.setOpacity(regionBlockId, DEFAULT_TOUCH_REGION_OPACITY);
    }

    // set color to random
    this.randomizeBlockColor(regionBlockId);

    this.initTextBlockProps(regionBlockId);

    // clear previous then select newly created block
    this.clearAllOtherSelectedBlocks();
    blockApi.setBool(regionBlockId, 'selected', true);
  }

  generateRegionName(): string {
    const baseName =
      this.layoutType === LayoutType.Interactive ? 'touch' : 'region';

    const blocks = this.getRegionBlocks();
    let nameAlreadyPresent = true;
    let i = blocks.length;
    let regionName = '';
    while (nameAlreadyPresent) {
      regionName = `${baseName}_${i}`;
      nameAlreadyPresent = !!blocks.find((b) => b.regionName === regionName);
      i = i + 1;
    }
    //let regionName = `${baseName}_${blockApi.getChildren(pageBlockId).length}`;

    return regionName;
  }

  initTextBlockProps(targetBlockId: number) {
    const blockApi = this.ceBlock;

    // guard block type, should be DesignBlockType.Text
    if (!this.isRegionBlock(targetBlockId)) return;

    // set text horizontal and vertical alignment to center
    blockApi.setEnum(targetBlockId, 'text/horizontalAlignment', 'Center');
    blockApi.setEnum(targetBlockId, 'text/verticalAlignment', 'Center');

    // set default dimensions (width x height)
    blockApi.setFloat(targetBlockId, 'width', 500);
    blockApi.setFloat(targetBlockId, 'height', 500);
  }

  randomizeBlockColor(block: number) {
    const blockApi = this.ceBlock;

    // note: the reason behind using HSV then randomizing rather than
    // { r: randomize(0, 255), g: randomize(0, 255), b: randomize(0, 255)}
    // is to produce more vibrant colors

    // random hsv color
    const h = getRandomArbitrary(0, 360);
    const s = 0.75;
    const v = 0.75;

    // convert to RGBA
    const { r, g, b } = hsvToRgb(h, s, v);
    const a = 1;

    // console.log(`r: ${r} g: ${g} b: ${b} a: ${a}`); // DEBUG

    // check if block type is correct
    if (
      blockApi.getType(block) ===
      (DesignBlockTypeEnum.Text as unknown as DesignBlockTypeEnum.Text)
    ) {
      // for block type: text
      blockApi.setBackgroundColorEnabled(block, true);
      blockApi.setBackgroundColorRGBA(block, r, g, b, a);
    }
  }

  deleteRegionBlock() {
    const blockApi = this.ceBlock;
    const targetBlock = this.lastSelectedRegionBlock$.value?.id;

    if (!targetBlock) return console.error('no selected region block');

    blockApi.destroy(targetBlock);
  }

  /**
   * this function extracts data from a block (design block of img.ly)
   * to create an ISmilRegion object
   *
   * @param blockId - this is actually just the block id
   * @returns ISmilRegion object
   */
  extractToISmilRegion(blockId: number): ISmilRegion {
    const blockApi = this.ceBlock;
    const blockName = blockApi.getName(blockId);
    const blockPositionX = blockApi.getPositionX(blockId);
    const blockPositionY = blockApi.getPositionY(blockId);
    const blockWidth = blockApi.getWidth(blockId);
    const blockHeight = blockApi.getHeight(blockId);
    const blockZIndex = blockApi
      .getChildren(this.getFirstPageBlock())
      .indexOf(blockId);

    const action =
      this.layoutType === LayoutType.Interactive
        ? this.getAction(blockId)
        : undefined;

    return {
      id: blockId,
      regionName: blockName,
      left: blockPositionX,
      top: blockPositionY,
      width: blockWidth,
      height: blockHeight,
      zIndex: blockZIndex,
      action,
    };
  }

  /**
   * this function extracts the canvas' (first page block's) properties, then turns it into a ILayoutEditorCanvas object
   *
   * @returns ILayoutEditorCanvas object
   */
  extractToILayoutEditorCanvas(): ILayoutEditorCanvas {
    const blockApi = this.ceBlock;
    const pageBlockId = this.getFirstPageBlock();
    const width = blockApi.getWidth(pageBlockId);
    const height = blockApi.getHeight(pageBlockId);
    const color = cesdkRgbaToHex(blockApi.getFillSolidColor(pageBlockId));

    return {
      width,
      height,
      color,
    };
  }

  updateBlock(region: IUpdateBlockInput) {
    const {
      id,
      regionName,
      left,
      top,
      width,
      height,
      zPositionMovement,
      action,
    } = region;
    const blockApi = this.ceBlock;

    // TODO here: ask for the default values for each of the props
    // for now if no prop is supplied no update will happen

    // guard
    // if (!regionName || !left || !top || !width || !height)
    //   return console.error('a prop of region is missing');

    // note: "!= null" checks for both null and undefined
    if (regionName != null) blockApi.setName(id, regionName);
    if (left != null) blockApi.setPositionX(id, left);
    if (top != null) blockApi.setPositionY(id, top);
    if (width != null) blockApi.setWidth(id, width);
    if (height != null) blockApi.setHeight(id, height);
    if (zPositionMovement != null) {
      this.updateBlockZPosition(id, zPositionMovement);
    }
    if (action != null) {
      this.setAction(id, action);
    }

    // set fixed properties
    this.setFixedTextBlockProperties(id);
  }

  updateSelectedRegionBlock(input: IUpdateSelectedBlockInput) {
    const selectedRegionBlockId = this.lastSelectedRegionBlock$.value?.id;

    if (!selectedRegionBlockId) return;
    // console.error('no selected region block'); // DEBUG

    const region: ISmilRegion = { id: selectedRegionBlockId, ...input };
    // console.log('REGION: ', region); // DEBUG

    this.updateBlock(region);
  }

  async saveSceneToCloudinary(publicId?: string) {
    const scene = await this.ceScene.saveToString();

    const blobScene = new Blob([scene], {
      type: 'application/octet-stream', //'text/plain',
    });

    const uploadResponse = await this.cloudinaryService.directUploadPromise(
      blobScene,
      publicId,
      undefined
    );

    // console.log('UPLOAD RESPONSE: ', uploadResponse); // DEBUG
    return uploadResponse;
  }
  async saveSceneToString(removeBackgrountIfPresent = true) {
    if (removeBackgrountIfPresent) {
      this.deleteBackground();
    }
    return this.ceScene.saveToString();
  }

  /** returns list of region blocks (does not contain virtual background block) */
  getRegionBlocks(): ISmilRegion[] {
    const backgroundBlockId = this.getBackgroundBlockId();
    let allBlocks = this.ceBlock.getChildren(this.getFirstPageBlock());

    if (backgroundBlockId.length > 0) {
      allBlocks = allBlocks.filter((x) => !backgroundBlockId.includes(x));
    }
    return allBlocks.map((blockId) => this.extractToISmilRegion(blockId));
  }

  /**
   * The return value of this function is optional, we can use this as a
   * void function just to load the scene into the editor instance directly
   *
   * @param sceneUrl
   * @returns A handle to the loaded scene
   */
  async loadSceneFromCloudinary(sceneUrl: string) {
    const sceneBlobString = await this.cloudinaryService.getSceneBlob(sceneUrl);

    // TODO here: there is a method called .loadFromUrl() inside the Scene Api
    // maybe we don't need to fetch the blob using the cloudinary service
    // this.ceScene.loadFromURL(sceneUrl)
    return this.ceScene.loadFromString(sceneBlobString);
  }

  loadFromString(sceneBlobString: string) {
    return this.ceScene.loadFromString(sceneBlobString);
  }

  toLayoutEditorSource(
    cloudinaryUploadResponse: ICloudinaryUploadResponse,
    canvas: ILayoutEditorCanvas
  ): ILayoutEditorSource {
    return {
      cloudinaryPublicId: cloudinaryUploadResponse.public_id,
      sceneUrl: cloudinaryUploadResponse.url, // TODO here: ask if we use the .secureUrl instead
      regionBlocks: this.getRegionBlocks(),
      canvas,
    };
  }

  clearSelectedBlockVariable() {
    if (!this.lastSelectedBlock$.value) return;

    this.lastSelectedBlock$.next(null);
  }

  /**
   * Deselects all selected blocks if there is any, if the optional param
   * is not provided it will deselect all blocks
   *
   * @param excludedBlock - (optional) exclude this block from deselecting
   */
  clearAllOtherSelectedBlocks(excludedBlock?: number) {
    const selectedBlocks = this.ceBlock.findAllSelected();
    // deselect previous block(s)
    if (selectedBlocks.length > 0) {
      selectedBlocks.forEach((blockId) => {
        // set selected to false if excluded block is undefined
        // or its not the excluded block
        if (!excludedBlock || excludedBlock !== blockId) {
          this.ceBlock.setBool(blockId, 'selected', false);
          this.clearSelectedBlockVariable();
        }
      });
    }
  }

  getCanvas(): ILayoutEditorCanvas {
    const blockApi = this.ceBlock;
    const pageBlockId = this.getFirstPageBlock();

    return {
      width: blockApi.getWidth(pageBlockId),
      height: blockApi.getHeight(pageBlockId),
      color: cesdkRgbaToHex(blockApi.getFillSolidColor(pageBlockId)),
    };
  }

  updateCanvas(canvas: IUpdateCanvas) {
    const { width, height, color } = canvas;
    const blockApi = this.ceBlock;
    if (!blockApi) return;
    // console.error('no blockApi'); // DEBUG

    // the canvas of the layout editor is actually just
    // the first page block of imgly's Creative Editor
    const pageBlockId = this.getFirstPageBlock();

    blockApi.setWidthMode(pageBlockId, 'Absolute');
    blockApi.setHeightMode(pageBlockId, 'Absolute');

    // update dimensions
    if (width) blockApi.setWidth(pageBlockId, width);
    if (height) blockApi.setHeight(pageBlockId, height);

    // note: uncomment this code below in the future if ever multiple pages
    // will be used, the property scene/pageDimensions/ only changes the dimensions
    // of newly created pages, and the code above only changes the dimensions
    // of the first page
    // const scene = this.ceScene.get();
    // if (scene !== null) {
    //   if (width) blockApi.setFloat(scene, 'scene/pageDimensions/width', width);
    //   if (height)
    //     blockApi.setFloat(scene, 'scene/pageDimensions/height', height);

    //   console.log(blockApi.getFloat(scene, 'scene/pageDimensions/width')); // DEBUG
    //   console.log(blockApi.getFloat(scene, 'scene/pageDimensions/height')); // DEBUG
    // }

    // update color
    if (color) {
      const [r, g, b, a] = color;
      blockApi.setFillSolidColor(pageBlockId, r, g, b, a);
    }
  }

  updateCanvasColor(color: RGBA) {
    const blockApi = this.ceBlock;
    if (!blockApi) return;

    const [r, g, b, a] = color;

    blockApi.setFillSolidColor(this.getFirstPageBlock(), r, g, b, a);
  }

  updateBlockZPosition(targetBlockId: number, movement: ZPositionMovement) {
    const blockApi = this.ceBlock;
    const pageBlockId = this.getFirstPageBlock();

    // note: the blockArray (children blocks of the page block) determines which
    // goes on top of each other. blockArrayIndex = zIndex, the higher the index,
    // the higher it is in terms of zPosition
    const blockArray = blockApi.getChildren(pageBlockId);
    // console.log('BLOCK ARRAY: ', blockArray); // DEBUG

    const targetBlockCurrentZIndex = blockArray.indexOf(targetBlockId);

    const maxZIndex = blockArray.length - 1;
    const minZIndex = 0;

    switch (movement) {
      case 'UP': {
        // if block goes outside max, stop
        if (targetBlockCurrentZIndex + 1 > maxZIndex) break;

        blockApi.insertChild(
          pageBlockId,
          targetBlockId,
          targetBlockCurrentZIndex + 1
        );
        break;
      }
      case 'DOWN': {
        // if block goes outside min, stop
        if (targetBlockCurrentZIndex - 1 < minZIndex) break;

        blockApi.insertChild(
          pageBlockId,
          targetBlockId,
          targetBlockCurrentZIndex - 1
        );
        break;
      }
      case 'TOP': {
        blockApi.insertChild(pageBlockId, targetBlockId, maxZIndex);
        break;
      }
      case 'BOTTOM': {
        blockApi.insertChild(pageBlockId, targetBlockId, minZIndex);
        break;
      }

      default:
        break;
    }
  }
}
