import { Injectable } from '@angular/core';
import { IZoneResolution, IResizeCropMethod } from '@desquare/interfaces';
import {
  ResizeCropMethods,
  ResizeCropMethodURIs,
  ZoneResolutions,
} from '@desquare/enums';
import {
  AssetType,
  Channel,
  ChannelToPlaylistFragment,
  LayoutDetailedFragment,
  PlaylistDetailedFragment,
} from '@designage/gql';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { LayoutDataService } from '../layout/layout-data.service';
import { BehaviorSubject } from 'rxjs';

interface IPlaylistAspect {
  playlistId: string;
  displayAspect: IZoneResolution;
}

@Injectable({
  providedIn: 'root',
})
export class AspectResizeCropService {
  zoneResolutions: IZoneResolution[] = [
    {
      resolution: ZoneResolutions.FULL_HD,
      width: 1920,
      height: 1080,
      aspect: 56.25,
      orientation: 'landscape',
      default: false,
    },
    {
      resolution: ZoneResolutions.HD,
      width: 1280,
      height: 720,
      aspect: 56.25,
      orientation: 'landscape',
      default: false,
    },
    {
      resolution: ZoneResolutions.TABLET_PC,
      width: 1280,
      height: 800,
      aspect: 62.5,
      orientation: 'landscape',
      default: false,
    },
    {
      resolution: ZoneResolutions.LEGACY,
      width: 800,
      height: 600,
      aspect: 75,
      orientation: 'landscape',
      default: false,
    },
    // {
    //   resolution: ZoneResolutions.HD_PORTRAIT,
    //   width: 720,
    //   height: 1280,
    //   aspect: 177.77,
    //   default: false,
    // },
    // {
    //   resolution: ZoneResolutions.TABLET_PORTRAIT,
    //   width: 800,
    //   height: 1280,
    //   aspect: 160,
    //   default: false,
    // },
  ];

  resizeCropMethods: IResizeCropMethod[] = [
    {
      rcMethodName: ResizeCropMethods.NONE_BEAUTY,
      rcMethodUri: ResizeCropMethodURIs.NONE_URI,
    },
    {
      rcMethodName: ResizeCropMethods.PAD_BEAUTY,
      rcMethodUri: ResizeCropMethodURIs.PAD_URI,
    },
    {
      rcMethodName: ResizeCropMethods.FILL_CROP_BEAUTY,
      rcMethodUri: ResizeCropMethodURIs.FILL_CROP_URI,
    },
    {
      rcMethodName: ResizeCropMethods.FILL_AI_CROP_BEAUTY,
      rcMethodUri: ResizeCropMethodURIs.FILL_AI_CROP_URI,
    },
  ];

  playlistAspects: IPlaylistAspect[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    layoutService: LayoutDataService
  ) {
    this.initVariables();
  }

  initVariables() {
    this.playlistAspects =
      this.localStorageService.retrieve(
        localStorageKeys.DEFAULT_PLAYLIST_DISPLAYS
      ) || [];
  }

  getResolutions() {
    return this.zoneResolutions;
  }

  getResizeCropMethods() {
    const array = this.resizeCropMethods.map((a) => a.rcMethodName);
    return array;
  }
  getResizeCropUri(assetType: AssetType, methodName: string) {
    const index = this.resizeCropMethods.findIndex(
      (i) => i.rcMethodName === methodName
    );
    let uri: string = this.resizeCropMethods[index].rcMethodUri;
    if (assetType === AssetType.Image && uri === 'c_pad') {
      // This is for coloring the background of the image. In the future it can be selectable colors, blur, auto color based on image etc.
      uri += ',b_black';
    }
    return uri;
  }

  updatePlaylistAspect(aspect: IZoneResolution) {
    const activePlaylistId = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PLAYLIST_ID
    );
    if (this.playlistAspects.length) {
      const index = this.playlistAspects.findIndex(
        (playlistAspect) =>
          playlistAspect.playlistId &&
          playlistAspect.playlistId === activePlaylistId
      );
      if (index >= 0) {
        this.playlistAspects[index].displayAspect = aspect;
      } else {
        this.playlistAspects.push({
          playlistId: activePlaylistId,
          displayAspect: aspect,
        });
      }
    } else {
      this.playlistAspects = [
        { playlistId: activePlaylistId, displayAspect: aspect },
      ];
    }

    this.localStorageService.store(
      localStorageKeys.DEFAULT_PLAYLIST_DISPLAYS,
      this.playlistAspects
    );
  }

  getPlaylistAspect() {
    const activePlaylistId = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PLAYLIST_ID
    );
    const index = this.playlistAspects.findIndex(
      (playlistAspect) =>
        playlistAspect.playlistId &&
        playlistAspect.playlistId === activePlaylistId
    );
    return this.playlistAspects[index]
      ? this.playlistAspects[index].displayAspect
      : this.zoneResolutions[0];
  }

  getRegionResolution(
    layout: LayoutDetailedFragment | undefined,
    regionName: string
  ) {
    if (layout) {
      if (regionName === '' && layout.source) {
        const height = layout.source?.canvas?.height;
        const width = layout.source?.canvas?.width;
        return this.getZone(width!, height!, regionName);
      } else {
        for (const region of layout.source?.regionBlocks || []) {
          if (region && regionName === region.regionName) {
            const { width, height } = region;
            return this.getZone(width!, height!, regionName);
          }
        }
      }
    }
    // default
    return this.getZone(1920, 1080, '-');
  }
  getZone(width: number, height: number, label?: string) {
    const orientation = height > width ? 'portrait' : 'landscape';
    const resolution = this.isFullHD(width, height)
      ? ZoneResolutions.FULL_HD
      : ZoneResolutions.CUSTOMIZE;
    const z: IZoneResolution = {
      label,
      resolution,
      height,
      width,
      orientation,
      default: false,
      aspect: width / height,
    };
    return z;
  }

  isFullHD(width: number, height: number) {
    return (
      (width === 1920 && height === 1080) || (width === 1080 && height === 1920)
    );
  }

  /** all layout regions for all assigned channels */
  customZoneResolutions: IZoneResolution[] = [];
  /** regions assigned to current playlist */
  assignedZoneResolutions: IZoneResolution[] = [];

  getChannelRegion(playlist: PlaylistDetailedFragment, channelId: string) {
    const c = playlist?.channelRegions?.find((x) => x.channelId === channelId);
    return c?.region;
  }

  /**
   * collect info about assigned channels layout and regions for preview
   * @param playlist
   */
  setCurrentPlaylistRegions(playlist: PlaylistDetailedFragment) {
    this.customZoneResolutions = [];
    this.assignedZoneResolutions = [];
    // don't add twice a layout
    const addedLayoutIds: string[] = [];

    for (const channel of playlist.channels) {
      const channelRegion = this.getChannelRegion(playlist, channel.id);
      const { layout } = channel;
      // if there's no layout or this layout has already been worked, then skip it
      // NOTE: if a playlist is assigned to 2 different regions of same layout this will make the 2nd region not appear in the assigned regions... fixable with an extra cycle
      if (!layout || addedLayoutIds.includes(layout?.id)) continue;
      addedLayoutIds.push(layout.id);

      const canvas = layout?.source?.canvas;
      if (canvas && canvas.height && canvas.width) {
        const { width, height } = canvas;
        if (!this.isFullHD(width, height)) {
          const r = this.getZone(width, height, channel.layout?.name);
          this.customZoneResolutions.push(r);

          if (!channelRegion || channelRegion === '') {
            this.assignedZoneResolutions.push(r);
          }
        }
      }
      const layoutRegions = channel.layout?.source?.regionBlocks;
      if (layoutRegions) {
        for (const region of layoutRegions) {
          if (region?.width && region.height && region.regionName) {
            const regionLabel = `${layout.name} / ${region.regionName}`;
            const r = this.getZone(region.width, region.height, regionLabel);
            this.customZoneResolutions.push(r);
            if (region.regionName === channelRegion) {
              this.assignedZoneResolutions.push(r);
            }
          }
        }
      }
    }
  }
}
