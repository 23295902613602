import { Component } from '@angular/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslateModule],
  selector: 'app-playlist-revert-version-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="false"
    closeButtonText="PROCEED"
    dismissButtonText="CANCEL"
  >
    <p>{{ 'REVERT_TO_VERSION_PROMPT' | translate }}</p>
  </app-confirm-dialog> `,
})
export class PlaylistRevertVersionDialogComponent {}
