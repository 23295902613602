import { Injectable } from '@angular/core';
import { Maybe } from '@designage/gql';
import { localStorageKeys } from '@desquare/constants';
import { LocalStorageService } from 'ngx-webstorage';

export enum PlaylistTabs {
  PLAYLIST = 'PLAYLIST',
  PREVIEW = 'PREVIEW',
  PUBLISH_DESTINATION = 'PUBLISH_DESTINATION',
  CONTENT_GALLERY = 'CONTENT_GALLERY',
  SAVE = 'SAVE',
  SCHEDULE = 'SCHEDULE',
  DELETE = 'DELETE',
  HISTORY = 'HISTORY',
}
/** View status for AssetItem */
export type PlaylistAssetContent = {
  contentId: string;
  isOpen: boolean;
  isPinned: boolean;
};

/** view status for Sequence (Asset) */
export type PlaylistAsset = {
  assetId: string;
  isCollapsed: boolean;
  /** this are the MEDIAs (pictures/videos...) inside a SEQUENCE */
  contents: PlaylistAssetContent[];
};

/** settings for View/UI user experience */
export type PlaylistViewSetting = {
  playlistId: string;
  currentTab: PlaylistTabs;
  /** SEQUENCES */
  assets: PlaylistAsset[];
};

@Injectable({
  providedIn: 'root',
})
/** manages open/closed/pinned status of a playlist page and other page informations */
export class PlaylistViewService {
  playlistSettings!: PlaylistViewSetting[];
  activePlaylistId!: string;

  currentPlaylistSettings!: PlaylistViewSetting;

  /**
   * TODO: this property shouldn't be needed anymore once the playlist manage component is refactored to use reactive forms
   * This property is being used as a proxy for managing the state of unsaved changes to a playlist which
   * is then used by the `PlaylistManageDeactivateGuard` do determine whether we can get out of the route.
   * We went with this approach to avoid prop drilling so the data flow looks something like:
   * ```
   * <playlist-manage />    -----| # Reads the state on the playlist-view-service via canDeactivate()
   *                             |
   * <playlist-settings />       |----> playlist-view-service --->  PlaylistManageDeactivateGuard
   *                             |
   * <playlist-form />      -----| # Sets the state on the playlist-view-service
   * ```
   * Currently, since the existing code is using Template-Driven Forms, the way we can get input changes is thru prop drilling
   * and emitting events. This is messy as the form becomes more complex. Once it is refactored to Reactive Forms, we can listen
   * for `valueChanges` from the root form itself to determine changes and simplify all of this.
   **/
  hasUnsavedPlaylistChanges = false;
  constructor(private localStorageService: LocalStorageService) {
    this.currentPlaylistSettings = this.newPlaylist();
    this.initVariables();
  }

  initVariables() {
    this.activePlaylistId = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PLAYLIST_ID
    );
    this.playlistSettings =
      this.localStorageService.retrieve(localStorageKeys.PLAYLIST_SETTINGS) ||
      [];
  }

  newPlaylist() {
    this.currentPlaylistSettings = {
      assets: [],
      playlistId: 'new',
      currentTab: PlaylistTabs.PREVIEW,
    };
    return this.currentPlaylistSettings;
  }
  initCurrentPlaylistSettings() {
    this.activePlaylistId = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PLAYLIST_ID
    );
    this.currentPlaylistSettings = this.getActivePlaylistSettings();
  }

  saveAllToLocalStorage(settings: PlaylistViewSetting[]) {
    this.localStorageService.store(
      localStorageKeys.PLAYLIST_SETTINGS,
      settings
    );
  }

  saveViewSettings(newSetting: PlaylistViewSetting) {
    const playlistIndex = this.playlistSettings.findIndex(
      (setting) => setting.playlistId === newSetting.playlistId
    );

    if (this.playlistSettings.length) {
      if (playlistIndex >= 0) {
        this.playlistSettings[playlistIndex] = newSetting;
      } else {
        this.playlistSettings.push(newSetting);
      }
    } else {
      this.playlistSettings = [newSetting];
    }

    this.saveAllToLocalStorage(this.playlistSettings);
  }

  getActivePlaylistSettings() {
    this.activePlaylistId = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PLAYLIST_ID
    );
    if (this.activePlaylistId) {
      return this.getViewSettings(this.activePlaylistId);
    } else {
      return this.currentPlaylistSettings;
    }
  }

  getViewSettings(playlistId: string) {
    const settings = this.playlistSettings.find(
      (setting) => setting.playlistId === playlistId
    );
    if (settings) {
      this.currentPlaylistSettings = settings;
    } else {
      this.newPlaylist();
      if (this.activePlaylistId) {
        this.currentPlaylistSettings.playlistId = this.activePlaylistId;
      }
    }
    return this.currentPlaylistSettings;
  }

  deletePlaylistSettings(playlistId: string) {
    const settingsToRemove = this.playlistSettings.find(
      (setting) => setting.playlistId === playlistId
    );

    if (settingsToRemove) {
      this.playlistSettings.splice(
        this.playlistSettings.indexOf(settingsToRemove),
        1
      );
      this.saveAllToLocalStorage(this.playlistSettings);
    }
  }

  getActivePlaylistAsset(assetId: string) {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    if (activePlaylistSetting) {
      return activePlaylistSetting.assets.find(
        (asset) => asset.assetId === assetId
      );
    }
    return null;
  }

  deleteAsset(assetId: string) {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    if (activePlaylistSetting) {
      const i = activePlaylistSetting.assets.findIndex(
        (asset) => asset.assetId === assetId
      );
      if (i) {
        activePlaylistSetting.assets.splice(i, 1);
        this.saveViewSettings(activePlaylistSetting);
      }
    }
  }

  deleteContent(assetId: string, contentId: Maybe<string>) {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    if (activePlaylistSetting) {
      const asset = activePlaylistSetting.assets.find(
        (x) => x.assetId === assetId
      );
      if (asset) {
        const content = asset.contents.find((c) => c.contentId === contentId);
        if (content) {
          asset.contents.splice(asset.contents.indexOf(content), 1);
          this.saveViewSettings(activePlaylistSetting);
        }
      }
    }
  }

  /** this refers to SEQUENCE */
  isAssetCollapsed(assetId: string) {
    // Show collapsed asset when active playlistId is null (create new playlist flow).
    const asset = this.getActivePlaylistAsset(assetId);
    // if this.activePlaylistId is not set return a default true
    return !this.activePlaylistId ? true : asset ? asset.isCollapsed : false;
  }

  /** this refers to MEDIA CONTENT */
  isContentOpen(assetId: string, contentId: string) {
    const asset = this.getActivePlaylistAsset(assetId);
    if (asset) {
      const content = asset.contents.find(
        (content) => content.contentId === contentId
      );
      return content ? content.isOpen : false;
    }
    return false;
  }

  /** this refers to MEDIA ASSET */
  isContentPinned(assetId: string, contentId: string) {
    const asset = this.getActivePlaylistAsset(assetId);
    if (asset) {
      const content = asset.contents.find(
        (content) => content.contentId === contentId
      );
      return content ? content.isPinned : false;
    }
    return false;
  }

  isAnythingPinned() {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    let ret = false;

    activePlaylistSetting?.assets.forEach((seq) => {
      seq.contents.forEach((content) => {
        if (content.isPinned) {
          ret = true;
        }
      });
    });

    return ret;
  }

  unpinAll() {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    activePlaylistSetting?.assets.forEach((sequence) => {
      sequence.contents.forEach((media) => {
        media.isPinned = media.isOpen = false;
      });
      sequence.isCollapsed = true;
    });
    if (activePlaylistSetting?.assets?.length) {
      activePlaylistSetting.assets[0].isCollapsed = false;
    }
    if (activePlaylistSetting) {
      this.saveViewSettings(activePlaylistSetting);
    }
  }

  /** toggle SEQUENCE collapse status */
  toggleAssetCollapsed(assetId: string) {
    const collapsed = !this.isAssetCollapsed(assetId);
    this.setAssetCollapsed(assetId, collapsed);
  }
  setAssetCollapsed(assetId: string, isCollapsed: boolean) {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    if (activePlaylistSetting) {
      const asset = activePlaylistSetting.assets.find(
        (asset) => asset.assetId === assetId
      );

      // Somebody clicked to open or close a Sequence, this means that a sequence SHOULD exist. if it does not exist let's add it!
      if (asset) {
        asset.isCollapsed = !asset.isCollapsed;
      } else {
        activePlaylistSetting.assets.push({
          assetId,
          contents: [],
          isCollapsed: false,
        });
      }
      this.saveViewSettings(activePlaylistSetting);
    }
  }

  setContentOpenStatus(assetId: string, contentId: string, isOpen: boolean) {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    if (activePlaylistSetting) {
      let settingsHaveChanged = false;

      /** SEQUENCE */
      let asset = activePlaylistSetting.assets.find(
        (asset) => asset.assetId === assetId
      );
      if (!asset) {
        // somebody clicked on it, so it DOES exist
        const newAsset: PlaylistAsset = {
          assetId: assetId,
          isCollapsed: false,
          contents: [],
        };
        activePlaylistSetting.assets.push(newAsset);
        asset = newAsset;
      }
      if (asset) {
        // isOpen: FALSE
        // you close/collapse a panel and unpin
        // isOpen: TRUE
        // you open a panel, close all unpinned ones
        if (isOpen) {
          asset.contents.forEach((c) => {
            c.isOpen = c.isPinned;
            settingsHaveChanged = true;
          });
        }
        let content = asset.contents.find(
          (content) => content.contentId === contentId
        );
        if (!content && isOpen) {
          // somebody clicked on it, it DOES exist
          const newContent: PlaylistAssetContent = {
            contentId,
            isOpen,
            isPinned: false,
          };
          asset.contents.push(newContent);
          content = newContent;
        }
        if (content) {
          content.isOpen = isOpen;
          content.isPinned = false;
          settingsHaveChanged = true;
        } else {
          asset.contents.push({
            contentId,
            isOpen,
            isPinned: false,
          });
          settingsHaveChanged = true;
        }
      }
      if (settingsHaveChanged) {
        this.saveViewSettings(activePlaylistSetting);
      }
    }
  }

  setAssetContentPinned(assetId: string, contentId: string, isPinned: boolean) {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    if (activePlaylistSetting) {
      const asset = activePlaylistSetting.assets.find(
        (asset) => asset.assetId === assetId
      );
      if (asset) {
        const content = asset.contents.find(
          (content) => content.contentId === contentId
        );
        if (content) {
          content.isPinned = isPinned;
          console.log('ispinned:', isPinned);
          this.saveViewSettings(activePlaylistSetting);
        }
      }
    }
  }

  setPlaylistCurrentTab(tab: PlaylistTabs) {
    const activePlaylistSetting = this.getActivePlaylistSettings();
    if (activePlaylistSetting) {
      activePlaylistSetting.currentTab = tab;
      this.saveViewSettings(activePlaylistSetting);
    }
  }

  getPlaylistCurrentTab() {
    return this.getActivePlaylistSettings()?.currentTab || PlaylistTabs.PREVIEW;
  }
}
