<div
  *ngIf="!readOnly"
  [ngClass]="{ expanded: isSelected }"
  [class.content-row-expanded-simple]="simpleUiActive"
  class="content-row content-row-expanded"
>
  <div
    class="d-flex align-items-center justify-content-end name-field"
    [class.item-disabled]="assetItem.disabled"
    (click)="onRowClick()"
  >
    <div class="align-items-center icons item-header">
      <div>
        <span
          *ngIf="!assetItem.disabled"
          [ngStyle]="activityStatus.style"
          [ngbTooltip]="activityStatus.status"
          (click)="onRowClick()"
          class="status-indicator"
        ></span>
      </div>
      <div class="content-type d-flex align-items-center">
        <ng-container *ngTemplateOutlet="typeIndicator"></ng-container>
      </div>
    </div>
    <div class="name-container overflow-hidden flex-grow-1">
      <input
        #nameInput
        *ngIf="isEditingName"
        class="form-control form-control-dark edit-content-name"
        autocomplete="off"
        [(ngModel)]="assetItem.name"
        (blur)="toggleEdit()"
        (keydown.enter)="toggleEdit()"
        (keydown.esc)="toggleEdit()"
        (click)="$event.stopPropagation()"
        (mousedown)="$event.stopPropagation()"
      />
      <div class="content-name" [class.input-group]="isEditingName">
        <ng-container *ngIf="!isEditingName">
          <h4
            class="text-truncate"
            (click)="editName(); $event.stopPropagation()"
          >
            {{ assetItem.name }}
          </h4>
          <span *ngIf="!readOnly" class="icon">
            <i
              (click)="editName(); $event.stopPropagation()"
              class="ri-pencil-fill text-primary"
            ></i>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <h5
        ngbTooltip="{{ 'PLAYLIST_DISABLE_ITEM_TT' | translate }}"
        class="badge bg-danger"
        *ngIf="assetItem.disabled"
      >
        {{ 'DISABLED' | translate }}
      </h5>
      <div
        ngbTooltip="{{ 'PLAYLIST_ENABLE_ITEM_SWITCH_TT' | translate }}"
        class="deactivate-switch form-check form-switch"
        (click)="$event.stopPropagation(); onDisableSwitch()"
      >
        <input
          class="form-check-input"
          type="checkbox"
          name="disableContentItem"
          [checked]="!assetItem.disabled"
        />
      </div>
      <i class="mx-2 ri-arrow-up-s-line"></i>
      <div
        class="pin-container"
        (click)="onRowPin(); $event.stopPropagation()"
        ngbTooltip="{{ 'PIN_PL_ROW' | translate }}"
        [disableTooltip]="isRowPinned"
        [ngClass]="isRowPinned ? 'pin-pinned' : 'pin-unpinned'"
      >
        <i
          [ngClass]="isRowPinned ? 'ti-pin2' : 'ti-pin-alt'"
          class="mx-2 ti"
        ></i>
      </div>
    </div>
  </div>

  <!-- <div class="name-field item-header">
    <div class="name-container">
      <input
        #nameInput
        *ngIf="isEditingName"
        class="form-control form-control-dark content-name"
        autocomplete="off"
        [(ngModel)]="assetItem.name"
        (blur)="toggleEdit()"
        (keydown.enter)="toggleEdit()"
        (click)="$event.stopPropagation()"
        (mousedown)="$event.stopPropagation()"
      />
      <div class="content-name" [class.input-group]="isEditingName">
        <div class="content-name">
          <span *ngIf="!isEditingName" class="me-1 text" (click)="editName()">
            {{ assetItem.name }}
          </span>
          <span *ngIf="!readOnly" class="icon">
            <i
              *ngIf="!isEditingName"
              (click)="editName(); $event.stopPropagation()"
              class="ri-pencil-fill text-primary"
            ></i>
          </span>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="item-header"></div> -->
  <!-- CONTENT PREVIEW -->
  <div class="content-preview">
    <div class="image-container">
      <ng-container *ngIf="isVideo || isImage">
        <img
          class="media-preview"
          [src]="thumbnailUrl"
          (click)="openPreviewDialog(); $event.stopPropagation()"
        />
      </ng-container>
      <ng-container *ngIf="isHtml">
        <iframe
          class="html-preview-container"
          scrolling="no"
          [src]="htmlUrl | safe : 'resourceUrl'"
        ></iframe>
      </ng-container>
      <ng-container *ngIf="isIFrame">
        <div class="d-flex align-items-center flex-column mw-100">
          <div class="d-flex">
            <div
              style="font-size: 3rem"
              class="align-self-center ti-html5"
            ></div>
            <h3 class="">HTML / IFrame Widget</h3>
          </div>
          <p
            class="pt-2 d-inline-block text-truncate mw-100"
            ngbTooltip="{{ assetItem.uri }}"
          >
            {{ assetItem.uri }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>

  <designage-content-row-simple-details
    *ngIf="simpleUiActive"
    style="grid-area: gSchedule"
    [(assetItem)]="assetItem"
    [assetType]="assetType"
    [isStartDateChecked]="isStartDateChecked"
    [campaignStartInput]="campaignStartInput"
    [validCampaignStartFormat]="validCampaignStartFormat"
    [isEndDateChecked]="isEndDateChecked"
    [campaignEndInput]="campaignEndInput"
    [notAllDays]="notAllDays"
    [validCampaignEndFormat]="validCampaignEndFormat"
    [showResizeCrop]="showResizeCrop"
    [disableResizeCrop]="disableResizeCrop"
    [isResizeCropChecked]="isResizeCropChecked"
    [resizeCropMethod]="resizeCropMethod"
    [resizeCropMethods]="resizeCropMethods"
    [showTransition]="showTransition"
    [transitionEffect]="transitionEffect"
    [transitionEffects]="transitionEffects"
    [transitionDuration]="transitionDuration"
    [topRightControls]="topRightControls"
    [scheduleDays]="scheduleDays"
    [contentDuration]="contentDuration"
    [(timeDurationInput)]="timeDurationInput"
    [validDurationFormat]="validDurationFormat"
    [validDurationValue]="validDurationFormat"
    [timeDurationString]="timeDurationString"
    (onCheckBoxStartDate)="onCheckBoxStartDate()"
    (onCampaignStartClose)="onCampaignStartClose($event)"
    (onCheckBoxEndDate)="onCheckBoxEndDate()"
    (onCampaignEndClose)="onCampaignEndClose($event)"
    (onCheckBoxResizeCrop)="onCheckBoxResizeCrop()"
    (setResizeCrop)="setResizeCrop($event)"
    (setSelectedTransition)="setSelectedTransition($event)"
    (setTransitionDuration)="setTransitionDuration($event)"
    (onDurationInputClose)="onDurationInputClose()"
    ngModel
    ngDefaultControl
  ></designage-content-row-simple-details>

  <div *ngIf="!simpleUiActive" class="content-schedule">
    <div class="start-date">
      <div class="start-date-toggle" (click)="onCheckBoxStartDate()">
        <input
          class="checkbox start-date-checkbox"
          type="checkbox"
          [checked]="isStartDateChecked"
        />
        <label class="form-label start-date-label detail-info"
          >{{ 'DO_NOT_PLAY_BEFORE' | translate }}:</label
        >
      </div>
      <div class="start-date-picker date-picker">
        <designage-datepicker
          [disabled]="!isStartDateChecked"
          [(date)]="campaignStartInput"
          [maxDate]="campaignEndInput"
          (dateChange)="onCampaignStartClose()"
        ></designage-datepicker>
        <div *ngIf="!validCampaignStartFormat" class="date-error text-danger">
          {{ 'INVALID_DATE_FORMAT_ERROR' | translate }}
        </div>
      </div>
    </div>
    <div class="end-date">
      <div class="end-date-toggle" (click)="onCheckBoxEndDate()">
        <input
          class="checkbox end-date-checkbox"
          type="checkbox"
          [checked]="isEndDateChecked"
        />
        <label class="form-label end-date-label detail-info"
          >{{ 'DO_NOT_PLAY_AFTER' | translate }}:</label
        >
      </div>
      <div class="end-date-picker date-picker">
        <designage-datepicker
          [disabled]="!isEndDateChecked"
          [(date)]="campaignEndInput"
          [minDate]="campaignStartInput"
          (dateChange)="onCampaignEndClose()"
        ></designage-datepicker>
        <div *ngIf="!validCampaignEndFormat" class="date-error text-danger">
          {{ 'INVALID_DATE_FORMAT_ERROR' | translate }}
        </div>
      </div>
    </div>
    <div class="weekdays">
      <label class="form-label detail-info d-block"
        >{{ 'PLAY_SEQUENCE_ON' | translate }}:</label
      >
      <div class="">
        <ng-container *ngTemplateOutlet="topRightControls"></ng-container>
      </div>
    </div>

    <div class="content-duration">
      <label class="ps-2 form-label detail-info"
        >{{ 'DURATION' | translate }}:</label
      >
      <label
        *ngIf="assetItem.type === assetType.Video"
        class="ps-2 form-label detail-info"
        >{{ timeDurationString | duration }}</label
      >
      <div *ngIf="assetItem.type !== assetType.Video" class="time-picker-box">
        <designage-timepicker
          [(time)]="timeDurationInput"
          (click)="$event.stopPropagation()"
          (timeChange)="onDurationInputClose()"
        ></designage-timepicker>
        <span
          *ngIf="!validDurationFormat"
          class="me-1 time-error text-danger"
          >{{ 'DURATION_FORMAT_ERROR' | translate }}</span
        >
        <span *ngIf="!validDurationValue" class="time-error text-danger">{{
          'DURATION_BELOW_MIN_ERROR' | translate
        }}</span>
      </div>
    </div>

    <div class="gridResize" *ngIf="showResizeCrop">
      <div class="start-date-toggle" (click)="onCheckBoxResizeCrop()">
        <input
          [disabled]="disableResizeCrop"
          class="checkbox start-date-checkbox"
          type="checkbox"
          [checked]="!disableResizeCrop && isResizeCropChecked"
        />
        <label class="form-label detail-info">
          {{ 'RESIZE_CROP' | translate }}:
          <i
            *ngIf="disableResizeCrop"
            class="ri-error-warning-line text-danger"
            ngbTooltip="{{ 'EXCEEDED_100MB' | translate }}"
          ></i>
        </label>
      </div>
      <div ngbDropdown>
        <input
          type="button"
          [disabled]="disableResizeCrop || !isResizeCropChecked"
          class="form-control form-control-dark dropdown"
          ngbDropdownToggle
          value="{{ resizeCropMethod | translate }}"
        />
        <div
          ngbDropdownMenu
          class="form-control form-control-dark shadowed-box text-white"
        >
          <ng-container *ngFor="let method of resizeCropMethods">
            <span
              ngbDropdownItem
              *ngIf="method !== resizeCropMethod"
              class="form-control form-control-dark dropdown-items"
              (click)="setResizeCrop(method)"
              >{{ method | translate }}</span
            ></ng-container
          >
        </div>
      </div>
    </div>

    <div class="gridTransitionEffect" *ngIf="showTransition">
      <label class="ps-2 form-label detail-info"
        >{{ 'TRANSITION' | translate }}:</label
      >
      <div class="duration-row">
        <div ngbDropdown>
          <input
            type="button"
            class="form-control form-control-dark dropdown"
            ngbDropdownToggle
            value="{{ transitionEffect | translate }}"
          />
          <div
            ngbDropdownMenu
            class="form-control form-control-dark shadowed-box text-white"
          >
            <ng-container *ngFor="let effect of transitionEffects">
              <span
                ngbDropdownItem
                *ngIf="effect !== transitionEffect"
                class="form-control form-control-dark dropdown-items"
                (click)="setSelectedTransition(effect)"
                >{{ effect | translate }}</span
              ></ng-container
            >
          </div>
        </div>
        <div
          style="height: 2rem; min-width: 6rem"
          class="w-100 d-flex ms-2"
          *ngIf="transitionEffect != 'CUT'"
          (mousedown)="$event.stopPropagation()"
        >
          <input
            class="form-control form-control-dark"
            type="number"
            [(value)]="transitionDuration"
            (change)="setTransitionDuration($event.target.value)"
            min="0.25"
            [(max)]="contentDuration"
            step="0.25"
            placeholder="0.00"
          />
          <label
            style="width: auto; margin: auto 0 0 0"
            class="px-1 border-0 form-control form-control-dark"
            >s</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="control-buttons d-flex justify-content-end align-items-center">
    <button
      *ngIf="
        (assetItem.type === assetType.Image ||
          assetType.Iframe ||
          assetType.Video) &&
        assetItem?.media?.metadata?.format != 'pdf'
      "
      type="button"
      class="m-2 border btn btn-sm btn-outline-primary border-primary"
      ngbTooltip="{{ 'EDIT_ITEM_CONTENT_TOOLTIP' | translate }}"
      (click)="onEditContent(); $event.stopPropagation()"
    >
      {{ 'EDIT_ITEM_CONTENT' | translate }}
    </button>
    <button
      type="button"
      class="m-2 border btn btn-sm btn-outline-primary border-primary"
      (click)="onReplaceContent(); $event.stopPropagation()"
    >
      {{ 'REPLACE_CONTENT' | translate }}
    </button>
    <button
      *ngIf="isInteractive && !isInteractionTargetMedia"
      type="button"
      class="m-2 border btn btn-sm btn-outline-primary border-primary"
      (click)="onEditInteractiveActions(); $event.stopPropagation()"
    >
      {{ 'EDIT_ACTIONS' | translate }}
    </button>
    <button
      *ngIf="!isInteractive"
      type="button"
      class="m-2 border btn btn-sm btn-outline-primary border-primary"
      (click)="onDuplicate(); $event.stopPropagation()"
    >
      {{ 'DUPLICATE' | translate }}
    </button>
    <button
      *ngIf="!isInteractive"
      type="button"
      class="m-2 border btn btn-outline-danger btn-sm border-danger"
      (click)="onDelete(); $event.stopPropagation()"
    >
      {{ 'DELETE_PLAYLIST_ITEM' | translate }}
    </button>
  </div>
</div>

<div
  [hidden]="isSelected"
  class="content-row content-row-collapsed"
  [class.item-disabled]="assetItem.disabled"
  [openDelay]="1000"
  placement="start"
  ngbTooltip="{{ assetItem.disabled ? ('DISABLED' | translate) : '' }}"
  (click)="!readOnly && onRowClick()"
>
  <div class="icons z-1">
    <span
      *ngIf="!assetItem.disabled"
      [ngStyle]="activityStatus.style"
      [ngbTooltip]="activityStatus.status"
      class="status-indicator"
    ></span>
    <ng-container *ngTemplateOutlet="typeIndicator"></ng-container>
  </div>

  <div class="image-container">
    <ng-container
      *ngIf="
        assetItem.type === assetType.Video || assetItem.type === assetType.Image
      "
    >
      <img
        class="content-thumbnail z-1"
        [src]="thumbnailUrl"
        (click)="openPreviewDialog(); $event.stopPropagation()"
      />
    </ng-container>
    <ng-container *ngIf="assetItem.type === assetType.Html">
      <i class="ri-layout-line"></i>
    </ng-container>
  </div>
  <div class="title-might-overflow">
    <p class="d-inline text-nowrap">{{ title }}</p>
  </div>
  <div class="metadata text-center">
    <label *ngIf="!assetItem.disabled" class="detail-info z-1">
      <span>
        <!-- {{ 'DURATION' | translate }}: -->
        <i class="ri-timer-line text-white"></i>
      </span>
      <span>{{ timeDurationString | duration }}</span>
      <span
        *ngIf="!validDurationValue || !validDurationFormat"
        class="text-danger"
        >{{ 'INVALID_DURATION' | translate }}</span
      >
    </label>
  </div>
  <div class="metadata">
    <label
      *ngIf="!assetItem.disabled && dateDuration !== ''"
      class="detail-info z-1"
      ><i class="ri-calendar-todo-line text-white pe-1"></i
      >{{ dateDuration }}</label
    >
  </div>
  <div class="detail-row">
    <div class="detail-row z-1" *ngIf="notAllDays && !assetItem.disabled">
      <ng-container *ngTemplateOutlet="topRightControls"></ng-container>
    </div>
  </div>

  <!-- <div class="metadata px-2" *ngIf="assetItem.disabled">
    <h5
      ngbTooltip="{{ 'PLAYLIST_DISABLE_ITEM_TT' | translate }}"
      class="badge text-bg-danger"
    >
      {{ 'DISABLED' | translate }}
    </h5>
  </div> -->

  <div class="metadata">
    <i
      *ngIf="!readOnly"
      class="delete mdi mdi-delete text-danger z-1"
      (click)="onDelete(); $event.stopPropagation()"
    ></i>
  </div>
</div>

<ng-template #topRightControls>
  <!-- slice pipe to exclude __typename -->
  <div></div>
  <label
    class="day"
    [ngStyle]="day.style"
    *ngFor="let day of scheduleDays | slice : 0 : 7; let i = index"
    (click)="!readOnly && switchDayStatus(i); $event.stopPropagation()"
  >
    {{ day.code }}
  </label>
</ng-template>

<ng-template #typeIndicator>
  <div *ngIf="assetItem.type === assetType.Video">
    <i *ngIf="isSelected" class="ri-video-fill"></i>
    <p class="d-inline-block m-auto px-1">
      {{ isSelected ? ('VIDEO' | translate) : ('VID' | translate) }}
    </p>
  </div>
  <div
    *ngIf="
      assetItem.type === assetType.Image &&
      assetItem.media?.metadata?.format != 'pdf'
    "
  >
    <i *ngIf="isSelected" class="ri-image-fill"></i>
    <p class="d-inline-block m-auto px-1">
      {{ isSelected ? ('IMAGE' | translate) : ('IMG' | translate) }}
    </p>
  </div>
  <div
    *ngIf="
      assetItem.type === assetType.Image &&
      assetItem.media?.metadata?.format === 'pdf'
    "
  >
    <i *ngIf="isSelected" class="ri-file-pdf-2-fill"></i>
    <p class="d-inline-block m-auto px-1">{{ 'PDF' | translate }}</p>
  </div>
  <div
    *ngIf="
      assetItem.type === assetType.Html || assetItem.type === assetType.Iframe
    "
  >
    <i *ngIf="isSelected" class="ri-html5-fill"></i>
    <p class="d-inline-block m-auto px-1">{{ 'HTML' | translate }}</p>
  </div>
</ng-template>
