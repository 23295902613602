import { Injectable } from '@angular/core';
import {
  CreateLayoutGQL,
  CreateLayoutInput,
  DeleteLayoutGQL,
  GetLayoutByIdGQL,
  GetProfileLayoutsGQL,
  LayoutDetailedFragment,
  Maybe,
  UpdateLayoutGQL,
  UpdateLayoutInput,
} from '@designage/gql';
import { QueryRef } from 'apollo-angular';
import { CurrentUserService } from '../current-user/current-user.service';
import { EMPTY, lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutDataService {
  // TODO: refactor type, make it more detailed
  profileLayoutsQuery: QueryRef<any, any> | undefined = undefined;

  constructor(
    private getProfileLayoutsGQL: GetProfileLayoutsGQL,
    private createLayoutGQL: CreateLayoutGQL,
    private updateLayoutGQL: UpdateLayoutGQL,
    private getLayoutByIdGQL: GetLayoutByIdGQL,
    private deleteLayoutByIdGQL: DeleteLayoutGQL,
    private currentUserService: CurrentUserService
  ) {}

  async getCurrentProfileLayouts() {
    const profileId = this.currentUserService.getCurrentProfileId();

    return ((
      await lastValueFrom(
        this.getProfileLayoutsGQL.fetch(
          { profileId },
          { fetchPolicy: 'no-cache' }
        )
      )
    ).data.profile?.layouts || []) as LayoutDetailedFragment[];
  }
  getProfileLayoutsObs(profileId: string) {
    this.profileLayoutsQuery = this.getProfileLayoutsGQL.watch(
      { profileId },
      { fetchPolicy: 'no-cache' } // TODO: might want to change this later to "cache-and-network"
    );

    return this.profileLayoutsQuery.valueChanges;
  }

  getLayoutById(layoutId: string) {
    return this.getLayoutByIdGQL.watch(
      { layoutId },
      { fetchPolicy: 'no-cache' }
    ).valueChanges;
  }

  createLayout(input: CreateLayoutInput) {
    return this.createLayoutGQL.mutate({ input });
  }

  updateLayout(input: UpdateLayoutInput) {
    return this.updateLayoutGQL.mutate({ input });
  }

  deleteLayoutById(layoutId: string) {
    return this.deleteLayoutByIdGQL.mutate({ layoutId });
  }
}
