import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslateModule],
  selector: 'app-delete-from-playlist-dialog',
  templateUrl: './delete-from-playlist-dialog.component.html',
  styleUrls: ['./delete-from-playlist-dialog.component.scss'],
})
export class DeleteFromPlaylistDialogComponent {
  messagePrompt!: string;
}
