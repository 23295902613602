import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { Asset, Maybe, PlaylistType } from '@designage/gql';
import { IAssetContent } from '@desquare/interfaces';
import { SubSink } from 'subsink';
import {
  PlaylistViewService,
  PlaylistEditorService,
  SessionService,
} from '@desquare/services';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AssetRowComponent } from '../asset-row/asset-row.component';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltip, AssetRowComponent],
  selector: 'app-playlist-sequence-manager',
  templateUrl: './playlist-sequence-manager.component.html',
  styleUrls: ['./playlist-sequence-manager.component.scss'],
})
export class PlaylistSequenceManagerComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() simpleUiActive!: boolean;

  @Output() drop = new EventEmitter<Asset[]>();
  @Output() assetId = new EventEmitter<string>();
  @Output() assetContent = new EventEmitter<IAssetContent>();

  assets!: Asset[];
  profileId!: Maybe<string>;
  assetChanging = false;

  get assetChangingMessage() {
    return this.playlistEditorService.assetChangingMessage;
  }

  get playlistType() {
    return this.playlistEditorService.playlistType;
  }

  /** show add sequence in standard playlist, interactive playlist for now only have 1 sequence */
  get showAddSequence() {
    return this.playlistEditorService.playlistType === PlaylistType.Interactive
      ? this.assets.length === 0
      : true;
  }

  constructor(
    private playlistEditorService: PlaylistEditorService,
    private session: SessionService,
    private playlistViewService: PlaylistViewService
  ) {}

  trackByAsset(index: number, asset: Asset): string {
    return asset.id;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.profileId = this.session.profileId();

    // this.assets = this.playlistEditorService.editingSequences;
    this.playlistViewService.initCurrentPlaylistSettings();
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.subs.sink =
      this.playlistEditorService.editingSequencesChange.subscribe(
        (assets: Asset[]) => {
          this.assets = this.playlistEditorService.editingSequences;
        }
      );

    this.subs.sink = this.playlistEditorService.assetMutating.subscribe(
      (value: boolean) => {
        this.assetChanging = value;
      }
    );
  }

  isAnythingPinned() {
    return this.playlistViewService.isAnythingPinned();
  }
  unpinAll() {
    this.playlistViewService.unpinAll();
  }
  addAsset() {
    if (this.profileId) {
      this.playlistEditorService.createAsset(this.profileId, true);
    }
  }

  isCollapsed(assetId: string) {
    return this.assets.length > 2
      ? this.playlistViewService.isAssetCollapsed(assetId)
      : false;
  }
}
