<div class="d-flex flex-column h-100">
  <!-- Upload media button -->
  <button
    type="button"
    (click)="openUploadWidget()"
    class="m-1 btn btn-outline-primary btn-border-dashed"
  >
    {{ 'UPLOAD_MEDIA' | translate }}
  </button>

  <!-- Search form -->
  <form [formGroup]="mediaSearchForm">
    <div class="ms-3 py-2 d-flex justify-content-between">
      <div class="d-flex">
        <!-- <select
          *ngIf="viewModeFormGroup.value.isGridView"
          formControlName="pageSize"
          class="form-select form-select-sm form-select-dark page-size-selector"
        >
          <option disabled>{{ 'PAGE_SIZE' | translate }}</option>
          <option *ngFor="let pageSize of pageSizeOptions" [ngValue]="pageSize">
            {{ pageSize }}
          </option>
        </select> -->

        <app-search-input
          [debounceDelay]="500"
          [parentForm]="mediaSearchForm"
          (search)="search($event)"
        >
        </app-search-input>
      </div>
      <form [formGroup]="viewModeFormGroup">
        <div class="btn-group btn-group-toggle" name="viewMode">
          <input
            class="btn-check"
            type="radio"
            [(formControl)]="viewModeFormGroup.controls.isGridView"
            [value]="false"
            id="tableview"
            autocomplete="off"
          />
          <label class="btn btn-outline-light" for="tableview">
            <i class="ri-list-check"></i>
          </label>
          <input
            class="btn-check"
            type="radio"
            [(formControl)]="viewModeFormGroup.controls.isGridView"
            [value]="true"
            id="gridview"
            autocomplete="off"
          />
          <label class="btn btn-outline-light" for="gridview">
            <i class="ri-grid-fill"></i>
          </label>
        </div>
      </form>
    </div>
  </form>

  <div
    class="pe-4 d-flex justify-content-between align-items-center overflow-hidden filter-bg"
  >
    <div
      class="folders-toggle"
      (click)="folderExplorer = !folderExplorer"
      [ngbTooltip]="folderExplorer ? 'Hide folders' : 'Show folders'"
    >
      <h6 class="px-3">
        <i class="ri-folder-fill"></i>
      </h6>
    </div>
    <!-- filter buttons -->
    <app-media-filter-button-group (filterOutput)="onFilterOutput($event)">
    </app-media-filter-button-group>
  </div>
  <as-split unit="pixel" gutterSize="0" class="h-100 pb-3" useTransition="true">
    <as-split-area size="170" minSize="10" order="1" [visible]="folderExplorer">
      <!-- folder-explorer -->
      <app-folder-explorer
        (selectFolderId)="onSelectFolderId($event)"
      ></app-folder-explorer>
    </as-split-area>
    <as-split-area
      size="*"
      order="2"
      class="d-flex flex-column overflow-hidden h-100"
    >
      <!-- media-gallery -->

      <!-- loader message  -->
      <div *ngIf="loading || loadingDelete" class="gallery-bg">
        <h3
          [hidden]="!loading"
          class="text-center text-muted searching-message"
        >
          {{ 'FETCHING_CONTENTS' | translate }}
        </h3>
        <h3
          [hidden]="!loadingDelete"
          class="text-center text-muted searching-message"
        >
          {{ loaderMessage | translate }}
        </h3>
      </div>

      <!-- media gallery Grid View -->
      <ng-container *ngIf="viewModeFormGroup.value.isGridView">
        <div
          id="media-gallery"
          class="py-3 media-container overflow-auto gallery-bg h-100"
          cdkDropList
          [cdkDropListData]="filteredMedia"
          [cdkDropListSortingDisabled]="true"
          [cdkDropListConnectedTo]="sequenceIds"
        >
          <div
            cdkDrag
            [cdkDragData]="mediaItem"
            *ngFor="
              let mediaItem of filteredMedia
                | slice : (page - 1) * pageSize : page * pageSize
            "
            [ngValue]="mediaItem"
            class="draggable-content"
          >
            <div class="content-drag-placeholder" *cdkDragPlaceholder></div>
            <div class="content-wrapper shadowed-box" [id]="mediaItem.id">
              <img
                loading="lazy"
                *ngIf="mediaItem.type === 'VIDEO'"
                class="picons"
                [src]="getTransformedVideoThumbnailUrl(mediaItem.secureUrl)"
                (click)="openPreviewDialog(mediaItem); $event.stopPropagation()"
              />
              <img
                loading="lazy"
                *ngIf="mediaItem.type === 'IMAGE'"
                class="picons"
                [src]="getTransformedUrl(mediaItem.secureUrl)"
                (click)="openPreviewDialog(mediaItem); $event.stopPropagation()"
              />
              <div
                *ngIf="mediaItem.readableType === 'image template'"
                class="d-flex p-2 position-absolute top-0 start-0"
                ngbTooltip="{{ mediaItem.readableType | titlecase }}"
              >
                <img src="assets/images/template-icon.svg" alt="" />
                <i class="ri-image-fill"></i>
              </div>
              <div
                *ngIf="mediaItem.readableType === 'video template'"
                class="d-flex p-2 position-absolute top-0 start-0"
                ngbTooltip="{{ mediaItem.readableType | titlecase }}"
              >
                <img src="assets/images/template-icon.svg" alt="" />
                <i class="ri-video-fill"></i>
              </div>
              <div
                *ngIf="mediaItem.readableType === 'image'"
                class="p-2 position-absolute top-0 start-0"
                ngbTooltip="{{ mediaItem.readableType | titlecase }}"
              >
                <i class="ri-image-fill"></i>
              </div>
              <div
                *ngIf="mediaItem.readableType === 'video'"
                class="p-2 position-absolute top-0 start-0"
                ngbTooltip="{{ mediaItem.readableType | titlecase }}"
              >
                <i class="ri-video-fill"></i>
              </div>
              <div class="media-title" ngbTooltip="{{ mediaItem.name }}">
                {{ mediaItem.name }}
              </div>
              <div
                ngbDropdown
                container="body"
                #mediaDropDown="ngbDropdown"
                class="dropdown-overlay"
              >
                <a
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  id="mediaDropDown"
                  ngbDropdownToggle
                  aria-expanded="false"
                >
                  <i
                    [ngClass]="
                      mediaDropDown.isOpen()
                        ? 'mdi mdi-dots-horizontal menu-icon-toggle'
                        : 'mdi mdi-dots-horizontal menu-icon'
                    "
                  ></i>
                </a>

                <div
                  ngbDropdownMenu
                  class="dropdown-menu media-dropdown shadowed-box text-white"
                  aria-labelledby="mediaDropDown"
                >
                  <div
                    ngbDropdown
                    #playlistDropDown="ngbDropdown"
                    class="mt-1 dropdown-submenu"
                    placement="right-top left-top"
                  >
                    <a
                      (click)="getPlaylistsByMediaItem(mediaItem)"
                      class="dropdown-toggle playlist-dropdown"
                      id="playlistDropDown"
                      ngbDropdownToggle
                      aria-expanded="false"
                    >
                      <i class="m-1 ri-search-2-line icon-tag"></i>

                      <span>{{ 'FIND_IN_PLAYLISTS' | translate }}</span>
                    </a>

                    <div
                      ngbDropdownMenu
                      class="dropdown-menu playlist-submenu"
                      aria-labelledby="playlistDropDown"
                    >
                      <a
                        *ngIf="!playlists.length; else displayPlaylists"
                        ngbDropdownItem
                        >{{ 'NO_PLAYLISTS_FOUND' | translate }}</a
                      >

                      <ng-template #displayPlaylists>
                        <a
                          (click)="
                            navigateToPlaylist(playlistItem);
                            mediaDropDown.close()
                          "
                          *ngFor="let playlistItem of playlists"
                          ngbDropdownItem
                          [id]="playlistItem.id"
                        >
                          {{ playlistItem.name }}
                        </a>
                      </ng-template>
                    </div>
                  </div>

                  <a ngbDropdownItem (click)="openDeleteMediaDialog(mediaItem)">
                    <i class="m-1 ri-delete-bin-5-line icon-tag"></i>

                    <span>{{ 'DELETE' | translate }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- grid view pager -->
        <ngb-pagination
          *ngIf="total > 0"
          [page]="page"
          [pageSize]="pageSize"
          [collectionSize]="total"
          size="sm"
          [boundaryLinks]="false"
          (pageChange)="onPageChange($event)"
          class="pt-2 pb-0 m-0 pagination-bg"
        ></ngb-pagination>
      </ng-container>

      <!-- media gallery List View -->
      <ng-container *ngIf="!viewModeFormGroup.value.isGridView">
        <designage-data-table
          configId="media-gallery-list"
          [columnSelector]="false"
          [showFilter]="false"
          [showFooter]="false"
          [showMultiSelect]="false"
          [data]="filteredMedia"
          [columns]="desColumns"
          [connectedTableList]="sequenceIds"
          [tableElementId]="'media-gallery-list'"
          [dragDisabled]="false"
          [loading]="loading"
          [alwaysSort]="true"
          (rowClick)="onRowClick($event)"
          [customComponent]="[thumbnail, createdAt]"
          [customDragClass]="'playlistDragDrop'"
        >
        </designage-data-table>

        <ng-template #createdAt let-row>
          <table-dateTime
            [data]="row.createdAt"
            [tooltipPipeArgs]="'medium'"
          ></table-dateTime>
        </ng-template>

        <ng-template #thumbnail let-row>
          <ng-container [ngSwitch]="row.type">
            <ng-container *ngSwitchCase="'IMAGE'">
              <cl-media
                ngbTooltip="{{ row.name }}"
                class="cl-media-image"
                [public-id]="row.publicId"
                mediaType="image"
                loading="lazy"
                width="120"
                height="120"
                resizeMethod="limit"
                quality="auto"
                fetch-format="webp"
              ></cl-media>
            </ng-container>

            <ng-container *ngSwitchCase="'VIDEO'">
              <cl-media
                ngbTooltip="{{ row.name }}"
                class="cl-media-video"
                [public-id]="row.publicId"
                mediaType="videoPoster"
                loading="lazy"
                width="120"
                height="120"
                resizeMethod="limit"
                quality="auto"
                fetch-format="webp"
              ></cl-media>
            </ng-container>
            <div *ngSwitchDefault>
              {{ row | json }}
              <img [src]="placeHolderImage" class="asset-img" />
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
    </as-split-area>
  </as-split>
  <!-- <div class="overflow-hidden h-100">
  </div> -->
</div>
