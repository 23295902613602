<div
  ngbAccordion
  [destroyOnHide]="false"
  class="accordion-custom"
  [class.hide-caret]="assetsCount <= 1"
>
  <!-- (panelChange)="panelChange($event)" -->
  <div [ngbAccordionItem]="asset().id" [collapsed]="false">
    <!-- [disabled]="assetsCount < 1 ? true : false" -->
    <button ngbAccordionButton class="accordion-button">
      <div class="w-100 d-flex align-items-center">
        <!-- (click)="selectAsset()" -->
        <ng-container *ngIf="!isInteractive && assetsCount > 1">
          <!-- Day part scheduled indicator (green or yellow) -->
          <span
            *ngIf="assetsCount > 1"
            [ngStyle]="activityStatus.style"
            [ngbTooltip]="activityStatus.status"
            class="col-1 status-indicator"
          ></span>

          <!-- Day Part name -->
          <div class="col-3 px-2" [class.input-group]="isEditingName">
            <!-- Name when not in edit mode -->
            <div class="sequence-name icon-appear-on-hover">
              <h5
                *ngIf="!isEditingName"
                class="text text-truncate"
                [ngbTooltip]="asset().name"
                (click)="!readOnly ? editName($event) : null"
              >
                {{ asset().name }}
              </h5>
              <span *ngIf="!readOnly" class="icon">
                <i
                  *ngIf="!isEditingName"
                  class="ri-pencil-fill text-primary"
                ></i>
              </span>
            </div>
            <!-- Name when editing it -->
            <input
              autocomplete="off"
              #nameInput
              (click)="$event.stopPropagation(); $event.preventDefault()"
              *ngIf="isEditingName"
              class="form-control form-control-dark w-100"
              [(ngModel)]="asset().name"
              (blur)="updateAssetDetail()"
              (keydown.enter)="updateAssetDetail()"
              (keyup)="$event.preventDefault()"
            />
          </div>

          <!-- Day Part start and end time -->
          <div
            class="col-2 d-flex align-items-baseline"
            ngbTooltip="{{ 'DAYPART_START_TT' | translate }}"
            placement="top"
          >
            <div
              class="d-none d-xl-block me-2 text-truncate"
              [class.start-label]="!readOnly"
            >
              {{ 'START' | translate }}:
            </div>

            <designage-timepicker
              *ngIf="!readOnly; else startTimeText"
              [(time)]="startTimeInputValue"
              [showSecond]="false"
              (click)="$event.stopPropagation()"
              (timeChange)="onStartTimeInputClose(); updateAssetDetail()"
            ></designage-timepicker>

            <ng-template #startTimeText>
              {{ startTimeRawValue | date : 'shortTime' }}
            </ng-template>
            <div *ngIf="isDuplicateStartTime" class="text-danger">
              {{ 'SEQUENCE_START_DUPLICATE_ERROR' | translate }}
            </div>
            <div *ngIf="!asset().startTime" class="text-danger">
              {{ 'START_TIME_FORMAT_ERROR' | translate }}
            </div>
          </div>

          <!-- Day Part run time -->
          <div
            class="col-3 d-flex"
            *ngIf="asset().startTime && asset().endTime"
            ngbTooltip="{{ 'RUNTIME_TT' | translate }}"
          >
            <span class="d-none d-xl-block me-2 text-truncate">
              {{ 'RUNTIME' | translate }}:
            </span>
            <span>
              {{ startTimeRawValue | date : 'shortTime' }} -
              {{ endTimeRawValue | date : 'shortTime' }}
            </span>
          </div>
        </ng-container>
        <!-- Day Part Duration -->
        <div *ngIf="!isInteractive" class="col d-flex justify-content-center">
          <span class="d-none d-xl-block me-2 text-truncate">
            {{
              assetsCount > 1
                ? ('DAY_PART' | translate)
                : ('PLAYLIST' | translate)
            }}
            {{ 'DURATION' | translate }}:</span
          >
          <span>{{ totalDuration | duration }}</span>
        </div>
      </div>
    </button>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-container *ngTemplateOutlet="contentRow"></ng-container>

        <div *ngIf="!readOnly" class="content-footer d-flex">
          <div class="flex-grow-1">
            <button
              type="button"
              class="btn btn-outline-primary border border-primary me-2"
              (click)="addContent(); $event.stopPropagation()"
            >
              {{ ADD_CONTENT | translate }}
            </button>
            <div
              *ngIf="!simpleUiActive"
              ngbDropdown
              container="body"
              class="d-inline-block"
            >
              <button
                *ngIf="!isInteractive"
                type="button"
                class="btn btn-outline-primary border border-primary me-2"
                id="widgetDropdown"
                ngbDropdownToggle
              >
                {{ 'ADD_WIDGET' | translate }}
              </button>
              <div
                ngbDropdownMenu
                class="form-control form-control-dark shadowed-box text-white"
                aria-labelledby="widgetDropdown"
              >
                <button
                  *ngIf="isUserSuperAdmin && !isInteractive"
                  type="button"
                  ngbDropdownItem
                  ngbTooltip="{{ 'ADD_IFRAME_TT' | translate }}"
                  (click)="addIframe(); $event.stopPropagation()"
                >
                  {{ 'ADD_IFRAME' | translate }}
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn delete-btn btn-outline-danger border border-danger me-2"
              (click)="removeAsset(); $event.stopPropagation()"
            >
              {{ DELETE_SEQUENCE | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentRow>
  <div
    style="min-height: 2rem"
    [id]="sequenceId || 'sequenceId'"
    cdkDropList
    [cdkDropListData]="asset().content"
    [cdkDropListSortingDisabled]="readOnly"
    [cdkDropListConnectedTo]="sequenceIds"
    (cdkDropListDropped)="drop($event)"
  >
    <!-- note: this br element was added for the drag and drop, this is to
      increase the container's dropzone height when empty since it is possible 
      to have a dropzone height that is so thin (probably like 1px) that the 
      user cannot drop anything on the container 
    <br />-->

    <h5 class="text-center" *ngIf="asset().content.length === 0">
      {{ 'EMPTY_DAYPART' | translate }}
    </h5>

    <div
      class="draggable position-relative"
      cdkDrag
      [cdkDragData]="content"
      [cdkDragDisabled]="readOnly"
      cdkDragLockAxis="y"
      *ngFor="let content of asset().content; index as i"
    >
      <app-content-row
        [readOnly]="readOnly"
        [isSelected]="isContentOpen(content.id)"
        [isRowPinned]="isContentPinned(content.id)"
        [assetActivityStatus]="activityStatus"
        [assetId]="asset().id"
        [assetItem]="content"
        (deleteAssetItem)="removeContent($event)"
        (duplicateContent)="duplicateContent($event)"
        (replaceContent)="replaceContent($event)"
        (editContent)="editContent($event, $event)"
        [simpleUiActive]="simpleUiActive"
      ></app-content-row>
      <hr *ngIf="asset().content.length > 1" class="content-divider" />
    </div>
  </div>
</ng-template>
