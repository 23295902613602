@if(device(); as device){ @if(device.deviceInfo !== null){

<!-- Device Information -->
<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col" colspan="2">
        {{ 'DEVICE_INFORMATION' | translate }}
      </th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'DEVICE_NAME' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ device.name }}
          </span>
          <copy-to-clipboard [cbcontent]="device.name"></copy-to-clipboard>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ 'LOCATION' | translate }}</td>
      <td>{{ device.location?.name }}</td>
    </tr>
    <br />
    <tr>
      <td>
        {{ 'DEVICE_TYPE' | translate }}
        @if(device.deviceInfo?.modelVersion){
        <span>{{ device.deviceInfo?.modelVersion }}</span>
        }
      </td>
      <td>{{ device.deviceInfo?.applicationType | titlecase }}</td>
    </tr>
    <tr>
      <td>{{ 'DEVICE_MODEL' | translate }}</td>
      <td>{{ device.deviceInfo?.model | titlecase }}</td>
    </tr>

    <tr>
      <td>{{ 'SERIAL' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ device.deviceInfo?.serialNumber }}
          </span>
          <copy-to-clipboard
            [cbcontent]="device.deviceInfo?.serialNumber"
          ></copy-to-clipboard>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ 'FIRMWARE' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ device.deviceInfo?.firmware }}
          </span>
          <copy-to-clipboard
            [cbcontent]="device.deviceInfo?.firmware"
          ></copy-to-clipboard>
        </div>
      </td>
    </tr>
    <tr></tr>
  </tbody>
</table>

<!-- Device Software -->
<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col" colspan="2">
        {{ 'DEVICE_SOFTWARE' | translate }}
      </th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'APPLET_VERSION' | translate }}</td>
      <td>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex">
            @if(currentUserService.isSuperAdmin ||
            currentUserService.isProfileManager){
            <span
              ngbTooltip="{{
                device.signageOSDeviceTiming?.activeAppletVersion ===
                device.deviceInfo?.appletVersion
                  ? ('UP_TO_DATE' | translate)
                  : ('UPDATE_AVAILABLE' | translate)
              }}"
              class="badge"
              [ngClass]="
                device.signageOSDeviceTiming?.activeAppletVersion ===
                device.deviceInfo?.appletVersion
                  ? 'bg-success'
                  : 'bg-warning'
              "
            >
              {{ device.deviceInfo?.appletVersion }}
            </span>
            @if(device.signageOSDeviceTiming?.activeAppletVersion !==
            device.deviceInfo?.appletVersion){
            <span
              class="badge rounded-pill bg-primary cursor-pointer mx-2 text-truncate"
              [ngbPopover]="popoverUpgradeApplet"
              [popoverTitle]="'APPLET_UPDATE_VERSION' | translate"
              ><i class="ri-arrow-up-fill me-1"></i
              >{{ 'UPDATE_AVAILABLE' | translate }}</span
            >}} @else {
            <span class="text-truncate">{{
              device.deviceInfo?.appletVersion
            }}</span>
            }
          </div>
          <copy-to-clipboard
            [cbcontent]="device.deviceInfo?.appletVersion"
          ></copy-to-clipboard>
          <ng-template #popoverUpgradeApplet>
            {{ 'BULK_ACTION_TT_APPLET_UPDATE_VERSION' | translate }}:
            {{ device.signageOSDeviceTiming?.activeAppletVersion }}
            <br />
            <button
              (click)="updateAppletVersion(device)"
              class="my-2 btn btn-sm btn-outline-warning"
            >
              {{ 'CLICK_TO_UPDATE' | translate }}
            </button>
            <br />
            <div class="badge text-warning">
              Warning: this operation will restart playback
            </div>
          </ng-template>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ 'CORE_APP_VERSION' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ device.deviceInfo?.applicationVersion }}
          </span>
          <copy-to-clipboard
            [cbcontent]="device.deviceInfo?.applicationVersion"
          ></copy-to-clipboard>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<!-- Network -->
<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col" colspan="2">
        <div class="d-flex justify-content-between">
          <div>
            {{ 'NETWORK_INFORMATION' | translate }}
          </div>
          @if(device.deviceInfo?.networkAdapters?.length){
          <div
            class="px-1 cursor-pointer bg-secondary rounded-circle"
            (click)="miniMenuOpen = !miniMenuOpen"
            ngbTooltip="{{ 'SHOW_ALL_NETWORK' | translate }}"
          >
            {{ miniMenuOpen ? '-' : '+' }}
          </div>
          }
        </div>
      </th>
      <th scope="col"></th>
    </tr>
  </thead>
  @if(device.deviceInfo){
  <tbody>
    @if(!miniMenuOpen){ @if (
    getNetworkAdaptersUtil(device.deviceInfo).activeAdapters[0]; as adapter){
    <tr>
      <td>{{ 'NETWORK_ACTIVE_INTERFACE' | translate }}</td>
      <td>
        <div class="d-flex">
          {{ adapter.type | titlecase }}
          @if(adapter.type === 'wifi'){
          <wifi-strength-indicator
            class="ps-2"
            [signal]="adapter.wifiStrength"
          ></wifi-strength-indicator>
          }
        </div>
      </td>
    </tr>
    <tr>
      <td>
        {{ 'IP' | translate }}
      </td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate"> {{ adapter.localAddress }} </span
          ><copy-to-clipboard
            [cbcontent]="adapter.localAddress"
          ></copy-to-clipboard>
        </div>
      </td>
    </tr>
    } }@else{ @for(adapter of
    getNetworkAdaptersUtil(device.deviceInfo)?.activeAdapters;track
    adapter.macAdress; let i = $index; let isFirst = $first; let last = $last;
    let c = $count){

    <tr class="text-decoration-underline">
      <td colspan="2">
        {{ 'NETWORK_ADAPTER' | translate }} {{ i + 1 }}
        @if(isFirst){
        <span class="fw-bolder"> {{ 'ACTIVE' | translate }} </span>
        }
      </td>
    </tr>
    <tr>
      <td>{{ 'NAME' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ adapter.name }}
          </span>
          <copy-to-clipboard [cbcontent]="adapter.name"></copy-to-clipboard>
        </div>
      </td>
    </tr>

    <tr>
      <td>{{ 'NETWORK_ACTIVE_INTERFACE' | translate }}</td>
      <td>{{ adapter.type }}</td>
    </tr>
    @if(adapter.type === 'wifi'){
    <tr>
      <td>{{ 'IP_WIFI_STRENGTH' | translate }}</td>
      <td>{{ adapter.wifiStrength }}%</td>
    </tr>
    <tr>
      <td>{{ 'IP_WIFI_SSID' | translate }}</td>
      <td>{{ adapter.wifiSsid }}</td>
    </tr>
    }
    <tr>
      <td>{{ 'IP' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ adapter.localAddress }}
          </span>
          <copy-to-clipboard
            [cbcontent]="adapter.localAddress"
          ></copy-to-clipboard>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ 'IP_NETMASK' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ adapter.netmask }}
          </span>
          <copy-to-clipboard [cbcontent]="adapter.netmask"></copy-to-clipboard>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ 'IP_GATEWAY' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ adapter.gateway }}
          </span>
          <copy-to-clipboard [cbcontent]="adapter.gateway"></copy-to-clipboard>
        </div>
      </td>
    </tr>
    <tr>
      <td>{{ 'IP_DNS' | translate }}</td>
      <td>
        @for(dns of adapter.dns; track dns){
        <div class="mt-1 d-flex justify-content-between">
          <span class="text-truncate">
            {{ dns }}
          </span>
          <copy-to-clipboard [cbcontent]="dns"></copy-to-clipboard>
        </div>
        }
      </td>
    </tr>
    <tr>
      <td>{{ 'NETWORK_MAC' | translate }}</td>
      <td>
        <div class="d-flex justify-content-between">
          <span class="text-truncate">
            {{ adapter.macAddress | uppercase }}
          </span>
          <copy-to-clipboard
            [cbcontent]="adapter.macAddress"
          ></copy-to-clipboard>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        {{ adapter.disabled ? 'DISABLED' : ('ENABLED' | translate) }}
      </td>
      <td></td>
    </tr>
    @if(c > 1 && !isLast){
    <br />
    } } }
  </tbody>
  }
</table>

<!-- Date Time -->
<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col" colspan="2">
        {{ 'TIME_INFORMATION' | translate }}
      </th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'LOCAL_DEVICE_TIME' | translate }}</td>
      @if(count$ | async; as count){
      <td>
        {{
          (device.status?.Status === 1
            ? count
            : device.deviceInfo?.currentTime?.currentDate
          ) | date : 'mediumTime'
        }}
      </td>
      }
    </tr>
    <tr>
      <td>{{ 'DATE' | translate }}</td>
      <td>
        {{ device.deviceInfo?.currentTime?.currentDate | date : 'mediumDate' }}
      </td>
    </tr>
    <tr>
      <td>{{ 'TIME_ZONE' | translate }}</td>
      <td>
        {{ device.deviceInfo?.currentTime?.timezone }} (
        {{ device.timezoneOffset }} )
      </td>
    </tr>
  </tbody>
</table>

<!-- Status -->
<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col" colspan="2">
        {{ 'DEVICE_STATUS' | translate }}
      </th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'STATUS' | translate }}</td>
      <td>
        {{ getStatusEnum(device.status?.Status) || offLineStatus | titlecase }}
      </td>
    </tr>
    <tr>
      <td>
        {{
          getStatusEnum(device.status?.Status) !== 'online'
            ? ('OFFLINE_FOR' | translate)
            : ('PING' | translate)
        }}
      </td>
      <td>
        <span
          ngbTooltip="{{
            (getStatusEnum(device.status?.Status) !== 'online'
              ? device.deviceInfo?.currentTime?.currentDate
              : device.lastPing
            ) | date : 'long'
          }}"
        >
          {{
            (getStatusEnum(device.status?.Status) !== 'online'
              ? device.deviceInfo?.currentTime?.currentDate
              : device.lastPing
            ) | amTimeAgo
          }}
        </span>
      </td>
    </tr>
  </tbody>
</table>

@if(isUserSuperAdmin){
<!-- Debug -->
<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col" colspan="2">
        <div class="d-flex justify-content-between">
          <div>Admin device debug</div>
          <div
            class="px-1 cursor-pointer bg-secondary rounded-circle"
            (click)="debugMiniMenuOpen = !debugMiniMenuOpen"
          >
            {{ debugMiniMenuOpen ? '-' : '+' }}
          </div>
        </div>
      </th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    @if(debugMiniMenuOpen){
    <tr>
      <td>deviceId:</td>
      <td>
        {{ device.id }}
        <copy-to-clipboard [cbcontent]="device.id"></copy-to-clipboard>
      </td>
    </tr>
    <tr>
      <td>data url:</td>
      <td>
        {{ s3playlistsUrl() }}
        <copy-to-clipboard [cbcontent]="s3playlistsUrl()"></copy-to-clipboard>
      </td>
    </tr>
    }
  </tbody>
</table>
} } @else {
<div class="d-flex flex-column justify-content-between">
  <hr />
  <ngx-skeleton-loader
    count="5"
    animation="progress-dark"
    [theme]="{
      'height': '0.5rem',
    }"
  />
  <hr />
  <ngx-skeleton-loader
    count="5"
    animation="progress-dark"
    [theme]="{
      'height': '0.5rem',
    }"
  />
  <hr />
  <ngx-skeleton-loader
    count="5"
    animation="progress-dark"
    [theme]="{
      'height': '0.5rem',
    }"
  />
</div>
} }
