import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  Maybe,
  ResourceType,
  AssetType,
  Media,
  PlaylistForPlaylistListFragment,
  Playlist,
  FolderGroupType,
  User,
  SaveMediaInput,
  SaveMediaFromCloudGQL,
  NewMediaSubscriptionGQL,
  SaveMediaPayload,
  MediaForMediaGalleryFragment,
  GetProfileMediaForMediaGalleryGQL,
  GetProfileMediaForMediaGalleryQuery,
  GetMediaMetadataGQL,
  DeleteMediaGQL,
  GetProfilePlaylistsGQL,
  MediaVisibilityType,
} from '@designage/gql';
import {
  EncryptionService,
  CurrentUserService,
  PlaylistEditorService,
  ToasterService,
  UiDataService,
  FilterService,
  MediaService,
  SessionService,
  FolderService,
} from '@desquare/services';
import { CloudinaryService } from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { SubSink } from 'subsink';
import { environment } from '@desquare/environments';
import { getImageSquaredThumbUrl, getRandomString } from '@desquare/utils';
import moment from 'moment';
import {
  IAssetItemInput,
  IDatatablePageChangeArgs,
  IDatatableRowActivateArgs,
  IDesignageDataTableColumns,
  IMediaFilterButtonGroupOutput,
  IMediaForMediaList,
  ISplit,
  IUiSettings,
} from '@desquare/interfaces';
import { ContentFilters, DatatableRowActivationType } from '@desquare/enums';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { stringToEnumUtil } from '@desquare/utils';
import {
  NgbDropdownModule,
  NgbModal,
  NgbModalModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { domConstants, localStorageKeys } from '@desquare/constants';
import { ThumbnailPreviewDialogComponent } from '../../playlist-sequence/thumbnail-preview-dialog/thumbnail-preview-dialog.component';
import { lastValueFrom, Subscription } from 'rxjs';
import { filter } from 'lodash';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from '@desquare/components/common/src/search-input/search-input.component';
import { MediaFilterButtonGroupComponent } from '../media-filter-button-group/media-filter-button-group.component';
import { AngularSplitModule } from 'angular-split';
import { FolderExplorerComponent } from '@desquare/components/common/src/folder/folder-explorer/folder-explorer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FolderTreeComponent } from '@desquare/components/common/src/folder/folder-tree/folder-tree.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSplitModule,
    NgbModalModule,
    NgbDropdownModule,
    SearchInputComponent,
    NgbTooltip,
    MediaFilterButtonGroupComponent,
    FolderExplorerComponent,
    DragDropModule,
    FolderTreeComponent,
  ],
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
  providers: [FolderService, MediaService],
})
export class MediaGalleryComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() profileId!: Maybe<string>;

  _media: Media[] = [];
  get media() {
    return this._media;
  }
  set media(value: Media[]) {
    this._media = value;
    this.currentUserService.media = value;
  }
  filteredMedia: IMediaForMediaList[] = [];
  uploadedMedia!: Maybe<Media>;
  mediaThumbnail: MediaForMediaGalleryFragment[] = [];
  assetItems: IAssetItemInput[] = [];
  mediaSearchForm!: FormGroup;
  searchText!: string;
  loading = false;
  isUploadDialogOpen = false;
  user!: Maybe<User>;
  // contentFilters = ContentFilters;
  // filterBy!: ContentFilters;
  draggingContents!: Maybe<boolean>;
  items = ['Delete', 'Find in Playlists'];
  defaultTime = moment.unix(10).utc();
  resourceType!: ResourceType;
  // imageFilterSelected!: boolean;
  // videoFilterSelected!: boolean;
  loaderMessage!: string;
  playlists: PlaylistForPlaylistListFragment[] = [];
  loadingDelete = false;
  uiSettings: IUiSettings = {
    sizeName: 'min-zoom',
    piconSizeX: 120,
    piconSizeY: 120,
  };
  lsKey = localStorageKeys.UI_SETTINGS;
  isGridView = true;
  viewModeFormGroup!: FormGroup;
  desColumns: IDesignageDataTableColumns[] = [];
  total = 0;
  page = 1;
  pageSize = 50;
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;
  externalPaging = false;

  getProfileMediasSubscription?: Subscription;

  selectedFolderId: string | null = null;
  folderExplorer = false;

  globalSearch = false;
  mediaFilterButtonOutput: IMediaFilterButtonGroupOutput[] = [];

  constructor(
    private toasterService: ToasterService,
    private getProfileMedias: GetProfileMediaForMediaGalleryGQL,
    private formBuilder: FormBuilder,
    private playlistEditorService: PlaylistEditorService,
    private cloudinaryService: CloudinaryService,
    private translateService: TranslateService,
    private saveMediaGQL: SaveMediaFromCloudGQL,
    private session: SessionService,
    private newMediaSubscription: NewMediaSubscriptionGQL,
    private getMediaMetadataGQL: GetMediaMetadataGQL,
    private modalService: NgbModal,
    private deleteMediaGQL: DeleteMediaGQL,
    private getProfilePlaylistsGQL: GetProfilePlaylistsGQL,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef,
    private filterService: FilterService,
    private folderService: FolderService,
    private mediaService: MediaService
  ) {}

  get hasNoData(): boolean {
    return !this.loading && this.total === 0;
  }

  openUploadWidget() {
    this.cloudinaryService.getUploadWidget().open();
  }

  /**
   * gets the sequence (formerly known as asset) ids. (assetIds = sequenceIds)
   */
  get sequenceIds() {
    return this.playlistEditorService.editingSequences.map((asset) => asset.id);
  }

  ngOnInit() {
    this.initVariables();
    // this.getMedia();
    this.initForm();
    this.initSubscriptions();
    this.setFormState();
    this.folderExplorerVisible();
  }

  ngOnDestroy() {
    this.cloudinaryService.destroyUploadWidget();
    this.getProfileMediasSubscription?.unsubscribe();
    this.subs.unsubscribe();
  }

  folderExplorerVisible() {
    this.folderExplorer =
      this.folderService.getProfileFoldersWithFullPath.length > 0;
  }

  uiSettingsHandler(zoom: string) {
    switch (zoom) {
      case 'min-zoom':
        this.uiSettings.sizeName = 'min-zoom';
        this.uiSettings.piconSizeX = 120;
        this.uiSettings.piconSizeY = 120;
        this.localStorageService.store(this.lsKey, this.uiSettings);
        this.cd.detectChanges();
        break;
      case 'mid-zoom':
        this.uiSettings.sizeName = 'mid-zoom';
        this.uiSettings.piconSizeX = 240;
        this.uiSettings.piconSizeY = 240;
        this.localStorageService.store(this.lsKey, this.uiSettings);
        this.cd.detectChanges();
        break;
      case 'max-zoom':
        this.uiSettings.sizeName = 'max-zoom';
        this.uiSettings.piconSizeX = 360;
        this.uiSettings.piconSizeY = 360;
        this.localStorageService.store(this.lsKey, this.uiSettings);
        this.cd.detectChanges();
        break;
    }
  }

  async initUploadWidget() {
    const folder = this.profileId
      ? `${FolderGroupType.Profile}/${this.profileId}`
      : `${FolderGroupType.User}/${this.user?.id}`;
    const [folderGroupType, idFolder] = folder.split('/');
    const cloudinaryParams = environment.cloudinary;

    let queue = cloudinaryParams.uploadWidgetParameters.text.default.queue;

    const { title_uploading_with_counter, title, upload_more } = queue;

    queue = {
      title: await lastValueFrom(this.translateService.get(title)),
      title_uploading_with_counter: await lastValueFrom(
        this.translateService.get(title_uploading_with_counter)
      ),
      upload_more: await lastValueFrom(this.translateService.get(upload_more)),
    };

    cloudinaryParams.uploadWidgetParameters.folder = folder;
    cloudinaryParams.uploadWidgetParameters.text.default.queue = queue;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cloudinaryService.createUploadWidget(
      cloudinaryParams,
      (error: any, result: any) => {
        if (error) {
          this.toasterService.error('ERROR_CODE_6_5');
          return;
        }

        switch (result.event) {
          case 'success': {
            const {
              public_id,
              url,
              secure_url,
              original_filename,
              resource_type,
            } = result.info;
            const response: SaveMediaInput = {
              folder: idFolder,
              folderGroupType:
                stringToEnumUtil.getFolderGroupType(folderGroupType),
              name: original_filename,
              publicId: public_id,
              resourceType: stringToEnumUtil.getResourceType(resource_type),
              secureUrl: secure_url,
              url,
            };
            this.saveMedia(response);
            break;
          }
          case 'queues-start':
            this.toasterService.info('UPLOADING_MEDIA');
            break;
        }
      }
    );
  }

  async saveMedia(cloudinaryResponse: SaveMediaInput) {
    await lastValueFrom(
      this.mediaService.saveMedia({
        ...cloudinaryResponse,
        dbFolderId: this.selectedFolderId,
      })
    );
  }

  initVariables() {
    this.profileId = this.session.profileId();

    // this.filterBy = ContentFilters.ALL;
    this.searchText = '';
    // this.imageFilterSelected = true;
    // this.videoFilterSelected = true;

    if (this.localStorageService.retrieve(this.lsKey) != null) {
      this.uiSettings = this.localStorageService.retrieve(this.lsKey);
    }

    this.desColumns = [
      {
        fieldName: 'publicId',
        name: 'THUMBNAIL',
        type: 'template',
        templateRef: 'thumbnail',
        visible: 'mandatory',
        style:
          'display:flex;max-height: 120px;max-width: 120px;justify-content: center;',
      },
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        fieldName: 'readableType',
        name: 'TYPE',
        type: 'string',
        visible: 'mandatory',
      },
      {
        fieldName: 'createdAt',
        name: 'CREATED_ON',
        type: 'template',
        templateRef: 'createdAt',
        visible: 'mandatory',
      },
    ];

    this.pageSize = this.currentUserService.preferredPageSize;
  }

  initSubscriptions() {
    if (this.profileId) {
      this.getMedia();
      this.initUploadWidget();
    }

    this.initGlobalSearchSubs();
  }

  initForm() {
    this.mediaSearchForm = this.formBuilder.group({
      search: [null],
      pageSize: [this.pageSize],
      globalSearch: [false],
    });
    this.viewModeFormGroup = this.formBuilder.group({
      isGridView: true,
    });
  }

  setFormState() {
    this.subs.sink =
      this.mediaSearchForm.controls.pageSize.valueChanges.subscribe(
        (value: number) => {
          this.pageSize = value;
          this.page = 1;

          if (value > this.media.length) {
            this.getMedia();
          }

          this.currentUserService.setPreferredPageSize(value);
        }
      );
  }

  // TODO: refactor getMedia to be more declarative than imperative
  // - use more observables
  // - refer getMedia() in media-list component
  getMedia() {
    this.loading = true;
    this.media = [];
    this.filteredMedia = [];
    this.getMediaByProfile(this.mediaFilterButtonOutput);
  }

  getMediaByProfile(filterList?: IMediaFilterButtonGroupOutput[]) {
    if (!this.profileId) return;

    const mediaVisibilityFilters: MediaVisibilityType[] | undefined =
      filterList && filterList.length > 0
        ? filterList?.map(({ mediaVisibility }) => mediaVisibility)
        : undefined;

    const resourceTypeFilters: ResourceType[] | undefined =
      filterList && filterList.length > 0
        ? filterList?.map(({ resourceType }) => resourceType)
        : undefined;

    const getMediaByProfile$ = this.mediaService.getMediaByProfile({
      profileId: this.profileId,
      globalSearch: this.globalSearch,
      filters: {
        name: this.searchText,
        folderId: this.selectedFolderId,
        mediaVisibility: mediaVisibilityFilters,
        resourceType: resourceTypeFilters,
      },
    });
    // .watch(
    //   {
    //     profileId: this.profileId,
    //     filters: {
    //       name: this.globalSearch ? this.searchText : undefined,
    //       folderId: this.globalSearch ? undefined : this.selectedFolderId,
    //       mediaVisibility: mediaVisibilityFilters,
    //       resourceType: resourceTypeFilters,
    //     },
    //   },
    //   {
    //     fetchPolicy: 'cache-and-network',
    //     errorPolicy: 'all',
    //   }
    // );

    this.getProfileMediasSubscription?.unsubscribe();
    this.getProfileMediasSubscription = getMediaByProfile$.subscribe(
      (mediaList) => {
        // const { profile } = data;

        this.loading = false;
        this.media = mediaList;
        // const typeFilteredMedia = this.filterMediaType();

        // client side filter
        if (!this.globalSearch) {
          this.filteredMedia = this.filterMedias(mediaList, filterList);
        } else {
          this.filteredMedia = mediaList;
        }

        this.total = this.filteredMedia.length;

        // if (profile) {
        //   const { total, results } = profile.media;
        //   this.media = results;
        //   // const typeFilteredMedia = this.filterMediaType();

        //   // client side filter
        //   if (!this.globalSearch) {
        //     this.filteredMedia = this.filterMedias(results, filterList);
        //   } else {
        //     this.filteredMedia = results;
        //   }

        //   this.total = this.filteredMedia.length;

        //   // TODO: figure out what this is really for (line 398-404)
        //   // it seems this updates the sequence's contents whenever there is change
        //   // with the media list, this code slows the down the project, since this
        //   // transformation will occur on every change and it applies to all the
        //   // content fetched, the larger the content library the slower this will become
        //   // for now we comment this code since we couldn't think of a reason why
        //   // this code should be here

        //   // this.media.forEach((x) => {
        //   //   if (!x.metadata) {
        //   //     x.metadata = null;
        //   //   }

        //   //   this.setMediaToAssetItem(x);
        //   // });
        // }

        // if (errors) {
        //   this.toasterService.error('MEDIA_NOT_EXIST_ERROR');
        // }
      }
    );

    // query.subscribeToMore({
    //   document: this.newMediaSubscription.document,
    //   variables: { profileId: this.profileId },
    //   updateQuery: (prev, { subscriptionData }) => {
    //     const { profile } = prev;
    //     const res: SaveMediaPayload = subscriptionData.data.newMedia;

    //     if (!profile || !res.isSuccessful || !res.media) {
    //       return prev;
    //     }

    //     const { id, name, media } = profile;
    //     const newMedia = res.media;

    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     const results = [newMedia, ...media.results] as any; // TODO: To investigate on how to handle optional metadata
    //     this.loading = true;
    //     const response: GetProfileMediaForMediaGalleryQuery = {
    //       ...prev,
    //       profile: {
    //         id,
    //         name,
    //         media: {
    //           results,
    //           total: this.total,
    //           __typename: 'PaginatedMedia',
    //         },
    //         __typename: 'Profile',
    //       },
    //     };
    //     this.toasterService.clear().then(() => {
    //       this.toasterService.success('NEW_MEDIA_UPLOADED');
    //       this.loading = false;
    //     });

    //     return response;
    //   },
    // });
  }

  // old filter function
  // filterMediaType() {
  //   if (this.imageFilterSelected && !this.videoFilterSelected) {
  //     return this.media.filter((x) => x.type === ResourceType.Image);
  //   } else if (!this.imageFilterSelected && this.videoFilterSelected) {
  //     return this.media.filter((x) => x.type === ResourceType.Video);
  //   } else {
  //     return this.media;
  //   }
  // }

  filterMedias(medias: Media[], filterList?: IMediaFilterButtonGroupOutput[]) {
    // filter by media filter button group
    const typeFilteredMedias: Media[] =
      !filterList || filterList.length === 0
        ? medias
        : filterList?.flatMap((filterOutput) => {
            let partialMedia: Partial<Media> = {};
            if (filterOutput?.mediaVisibility)
              partialMedia.visibility = filterOutput.mediaVisibility;
            if (filterOutput?.resourceType)
              partialMedia.type = filterOutput.resourceType;

            // combine the filtered list into one list
            return filter(medias, partialMedia);
            // warning: this logic could potentially have duplicates
            // needs thorough testing, if it does happen we can
            // simply add a function after this filter logic that
            // removes duplicates
          });

    // filter by search input field
    const searchFilteredMedia = this.filterService.filterListByName(
      this.searchText,
      typeFilteredMedias
    );

    return searchFilteredMedia;
  }

  // This is the function that creates asset item from a media object
  setMediaToAssetItem(media: Media) {
    const assetItem = this.playlistEditorService.toAssetItem(media);
    this.assetItems.push(assetItem);
  }

  setMedia(media: MediaForMediaGalleryFragment) {
    return {
      ...media,
      id: media.id,
    };
  }

  search(searchText: string) {
    console.log('search:', searchText); // DEBUG
    console.log('globalSearchState:', this.globalSearch); // DEBUG

    this.searchText = searchText;
    this.getMedia();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // event can be any
  // onImageFilterSelect(event: any) {
  //   if (event.target.checked === true) {
  //     this.imageFilterSelected = true;
  //   } else {
  //     this.imageFilterSelected = false;
  //   }
  //   this.filteredMedia = this.filterMediaType();
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // event can be any
  // onVideoFilterSelect(event: any) {
  //   if (event.target.checked === true) {
  //     this.videoFilterSelected = true;
  //   } else {
  //     this.videoFilterSelected = false;
  //   }
  //   this.filteredMedia = this.filterMediaType();
  // }

  onRowClick(args: IDatatableRowActivateArgs) {
    const { column, row, type } = args;
    if (column?.name !== '' && type === DatatableRowActivationType.CLICK) {
      // if row clicked, preview image
      const targetMedia = this.media.find((media) => media.id === row.id);
      if (targetMedia) {
        this.openPreviewDialog(targetMedia);
      }
    }
  }

  onPageChange(page: number) {
    this.page = page;

    if (this.externalPaging) {
      this.getMedia();
    }
  }

  async openDeleteMediaDialog(mediaItem: Media) {
    if (mediaItem) {
      this.loadingDelete = true;
      this.loaderMessage = 'DELETING_MEDIA';

      await this.mediaService.openDeleteMediaDialog({
        mediaList: [mediaItem],
        mediaFolderId: this.selectedFolderId,
      });

      this.loadingDelete = false;
    }
  }

  getPlaylistsByMediaItem(mediaItem: Media) {
    this.playlists = [];
    if (mediaItem?.id && this.profileId) {
      const query = this.getProfilePlaylistsGQL.watch(
        {
          profileId: this.profileId,
          contentId: mediaItem.id,
        },
        {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        }
      );

      this.subs.sink = query.valueChanges.subscribe({
        next: ({ data, loading, errors }) => {
          this.loading = loading;
          const { profile } = data;

          if (profile) {
            this.playlists = profile.playlists;
          } else {
            this.playlists = [];
          }

          if (errors) {
            this.toasterService.error('NO_PLAYLISTS_FOUND');
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('getPlaylistsByMediaItem', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
          this.loading = false;
        },
      });
    }
  }

  setTemporaryAssetItem(mediaItem: Media) {
    const assetItem = {
      id: getRandomString(),
      contentId: mediaItem.id,
      name: mediaItem.name,
      type: this.playlistEditorService.setAssetType(mediaItem.type),
      publicId: mediaItem.publicId,
      campaignEnd: null,
      campaignStart: null,
      days: null,
      duration: null,
      uri: mediaItem.secureUrl,
      sequence: null,
      webpUrl: null,
      mediaItem,
      __typename: this.playlistEditorService.setContentTypeName(mediaItem.type),
    };
    return assetItem;
  }

  openPreviewDialog(mediaItem: Media) {
    const modalRef = this.modalService.open(ThumbnailPreviewDialogComponent, {
      windowClass: 'custom-centered-modal',
      size: 'lg',
    });

    const assetItem = this.setTemporaryAssetItem(mediaItem);

    modalRef.componentInstance.assetItem = assetItem;

    if (assetItem.__typename === 'VideoAsset') {
      this.playlistEditorService.previewPlayToggleTriggered.emit(false);
    }
  }

  navigateToPlaylist(playlist: Playlist) {
    if (playlist) {
      const playlistId = this.encryptionService.encrypt(playlist.id);
      if (this.currentUserService.canManagePlaylist && playlistId) {
        this.router.navigate(['/playlist/manage', playlistId]);
      }
    } else {
      this.toasterService.error('UNKNOWN_ERROR');
    }
  }

  getTransformedUrl(url: string) {
    return getImageSquaredThumbUrl(url);
  }

  getTransformedVideoThumbnailUrl(url: string) {
    const transformedUrl = this.getTransformedUrl(url);

    const IMAGE_FILE_EXTENSION = 'webp';

    // https://cloudinary.com/documentation/video_manipulation_and_delivery#generating_video_thumbnails
    const videoThumbnailUrl = `${transformedUrl.substring(
      0,
      transformedUrl.lastIndexOf('.')
    )}.${IMAGE_FILE_EXTENSION}`;

    return videoThumbnailUrl;
  }

  onSelectFolderId(id: string | null) {
    this.selectedFolderId = id;
    console.log('onSelectFolderId', id);

    // refetch media
    this.getMedia();
  }

  onFilterOutput(event: IMediaFilterButtonGroupOutput[]) {
    this.mediaFilterButtonOutput = event;
    this.getMedia();
  }

  initGlobalSearchSubs() {
    if (this.mediaSearchForm.controls.globalSearch)
      this.subs.sink =
        this.mediaSearchForm.controls.globalSearch.valueChanges.subscribe(
          (value) => {
            this.globalSearch = value;

            // note: this prevents globally fetching everything
            // if searchText value is empty and globalSearch state is true
            // then don't search
            if (!this.searchText && this.globalSearch === true) return;

            this.search(this.searchText);
          }
        );
  }
}
