<div class="modal-header">
  <h4 class="modal-title">{{ 'ADD_IFRAME_TT' | translate }}</h4>
</div>
<as-split direction="horizontal">
  <as-split-area [size]="40">
    <form
      #form="ngForm"
      #imageForm="ngForm"
      class="d-flex justify-content-between flex-column m-5"
    >
      <div class="">
        <div class="form-group mb-4">
          <!-- {{ 'IFRAME_MODAL_IMAGE_NAME' | translate }} -->
          <label class="form-label" for="assetTitle">{{
            'IFRAME_MODAL_PLAYLIST_TITLE' | translate
          }}</label>
          <input
            id="assetTitle"
            name="assetTitle"
            class="form-control form-control-dark content-name"
            autocomplete="off"
            [(ngModel)]="model.assetTitle"
          />
        </div>
        <div class="form-group mb-4">
          <label class="form-label" for="iframeUrl">{{
            'IFRAME_MODAL_URL_TITLE' | translate
          }}</label>
          <div class="input-group w-100" role="group">
            <input
              placeholder="https://www.example.com"
              #iframeUrl="ngModel"
              type="url"
              id="iframeUrl"
              name="iframeUrl"
              class="form-control form-control-dark content-name"
              autocomplete="off"
              [ngModel]="model.iframeUrl"
              (keyup.enter)="reloadIframe(iframeUrl.value)"
              required
              url
            />
            <!-- NOTE: input-group-append and input-group-prepend dropped in BS5: https://getbootstrap.com/docs/5.2/migration/#forms -->
            <!-- <div class="input-group-append"> -->
            <button
              (click)="reloadIframe(iframeUrl.value)"
              type="button"
              class="px-3 btn btn-outline-primary"
            >
              {{ 'GO' | translate }}
            </button>
            <!-- </div> -->
          </div>
          <div
            *ngIf="
              iframeUrl.errors?.url && (iframeUrl.dirty || iframeUrl.touched)
            "
            class="alert alert-danger"
          >
            {{ 'IFRAME_MODAL_URL_VALIDATOR_VALID_URL' | translate }}
          </div>
          <div
            *ngIf="
              iframeUrl.errors?.required &&
              (iframeUrl.dirty || iframeUrl.touched)
            "
            class="alert alert-danger"
          >
            {{ 'IFRAME_MODAL_URL_VALIDATOR_EMPTY' | translate }}
          </div>
        </div>
        <div class="mt-5">
          <label class="form-label">{{
            'IFRAME_MODAL_PREVIEW_SETTINGS' | translate
          }}</label>
          <div class="row border rounded me-1 py-2">
            <label class="form-label">{{ 'RESOLUTION' | translate }}</label>
            <div class="d-flex">
              <div class="form-group mx-1 resolution-input-div">
                <label class="form-label" for="iframeWidth">{{
                  'WIDTH' | translate
                }}</label>
                <div class="input-group">
                  <input
                    type="number"
                    id="iframeWidth"
                    name="iframeWidth"
                    class="form-control form-control-dark content-name"
                    autocomplete="off"
                    required
                    [(ngModel)]="model.iframeWidth"
                    (blur)="setIframeScaleFactor()"
                  />
                  <!-- <div class="input-group-append"> -->
                  <span class="input-group-text pt-2"> px </span>
                  <!-- </div> -->
                </div>
              </div>
              <div class="form-group mx-1 resolution-input-div">
                <label class="form-label" for="iframeHeight">{{
                  'HEIGHT' | translate
                }}</label>
                <div class="input-group">
                  <input
                    type="number"
                    id="iframeHeight"
                    name="iframeHeight"
                    class="form-control form-control-dark content-name"
                    autocomplete="off"
                    required
                    [(ngModel)]="model.iframeHeight"
                    (blur)="setIframeScaleFactor()"
                  />
                  <!-- <div class="input-group-append"> -->
                  <span class="input-group-text pt-2"> px </span>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4 d-flex align-items-start justify-content-end">
        <button
          type="button"
          class="btn btn-outline-warning"
          (click)="cancelPopup()"
        >
          {{ 'CANCEL' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-outline-success ms-2"
          [disabled]="!imageForm.valid"
          (click)="onSave()"
        >
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </form>
  </as-split-area>
  <as-split-area [size]="60">
    <div class="iframeContainer col-8" #iframeContainer>
      <!-- [ngStyle]="iframeContainerScale()" -->
      <div class="iframeDiv" [ngStyle]="setIframeSize()">
        <iframe
          *ngIf="model.iframeUrl !== ''; else emptyWeb"
          #iframe
          class="py-4"
          [src]="model.iframeUrl | safe : 'resourceUrl'"
          frameborder="0"
          sandbox="allow-scripts allow-same-origin"
        ></iframe>
      </div>
    </div>
  </as-split-area>
</as-split>
<!-- <div class="modal-body p-4 d-flex h-100">

  
</div> -->

<div class="modal-footer">
  <p>{{ 'IFRAME_MODAL_DESC' | translate }}</p>
</div>

<ng-template #emptyWeb>
  <div class="emptyWebContainer"></div>
</ng-template>

<ng-template #onCancelModal let-modal>
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ 'WIDGET_MODAL_CLOSE_HEADER' | translate }}
      </h4>
      <button
        type="button"
        class="btn-close btn-close-white"
        aria-label="Close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span>&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'WIDGET_MODAL_CLOSE_PROMPT' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-warning"
        (click)="modal.dismiss('Close click')"
      >
        {{ 'CANCEL' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="modal.dismiss('Close click'); destroyEditor()"
      >
        {{ 'CLOSE' | translate }}
      </button>
    </div>
  </div>
</ng-template>
