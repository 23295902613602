<div class="d-flex justify-content-between">
  <button
    *ngIf="showAddSequence"
    [disabled]="assetChanging"
    type="button"
    (click)="addAsset()"
    class="btn btn-outline-primary btn-block ms-2 mb-2"
  >
    {{
      (playlistType === 'INTERACTIVE'
        ? 'ADD_SEQUENCE_INTERACTIVE'
        : 'ADD_SEQUENCE'
      ) | translate
    }}
  </button>
  <div class="d-flex justify-content-center align-items-center me-3">
    <div
      class="pins"
      [hidden]="!isAnythingPinned()"
      (click)="unpinAll()"
      ngbTooltip="{{ 'UNPIN_ALL' | translate }}"
    >
      <i class="ti ti-pin2 pin-no-1"></i>
      <i class="ti ti-pin2 pin-no-2"></i>
    </div>
  </div>
</div>

<app-loader *ngIf="assetChanging" [message]="assetChangingMessage"></app-loader>
<app-asset-row
  *ngFor="let asset of assets; index as i; trackBy: trackByAsset"
  class="m-1 mb-2"
  [asset]="asset"
  [assetsCount]="assets.length"
  [collapseAll]="isCollapsed(asset.id)"
  [simpleUiActive]="simpleUiActive"
></app-asset-row>
