import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  AspectResizeCropService,
  CurrentUserService,
  EncryptionService,
} from '@desquare/services';
import {
  Maybe,
  GetPlaylistDetailedGQL,
  PlaylistStatus,
  PlaylistDetailedFragment,
} from '@designage/gql';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { cloneDeep } from 'lodash';
import { NgIf } from '@angular/common';
import { PlaylistSettingsComponent } from '../playlist-settings/playlist-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbService } from 'xng-breadcrumb';

enum Tabs {
  SETTINGS,
  DETAILS,
}

@Component({
  standalone: true,
  imports: [NgIf, TranslateModule, PlaylistSettingsComponent],
  selector: 'app-playlist-manage',
  template: `
    <div class="progress progress-bar-custom" *ngIf="loading">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated progress-bar-custom"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ loaderMessage | translate }}
      </div>
    </div>

    <div
      [class.disabled]="loading"
      class="pt-0 overflow-auto container-fluid content-container-card manage-channel-container"
    >
      <app-playlist-settings
        [playlist]="playlist"
        [invokedFrom]="invokedFrom"
        (updatedPlaylist)="playlist = $event"
        (loaderMessage)="loaderMessage = $event"
        (loading)="loading = $event"
        (playlistPublished)="playlistPublished.emit()"
      ></app-playlist-settings>
    </div>
  `,
  styles: [
    `
      .disabled {
        pointer-events: none;
      }

      .progress-bar-custom {
        width: 100%;
        height: 1.5rem;
      }
    `,
  ],
})
export class PlaylistManageComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  playlist!: Maybe<PlaylistDetailedFragment>;
  // GetPlaylistDetailedQuery['playlist']

  loading = false;
  loaderMessage = 'FETCHING_PLAYLIST';
  currentTab!: Tabs;
  tabs = Tabs;
  playlistStatus = PlaylistStatus;

  _inlinePlaylistId: string = '';
  @Input() set inlinePlaylistId(value: string) {
    // this._inlinePlaylistId = value;
    if (value) {
      this.initPlaylistDecrypted(value);
    }
  }

  @Input() invokedFrom?: string;
  @Output() playlistPublished = new EventEmitter<void>();

  constructor(
    private route: ActivatedRoute,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService,
    private getPlaylistGQL: GetPlaylistDetailedGQL,
    private arcService: AspectResizeCropService,
    private breadcrumbService: BreadcrumbService
  ) {}

  get showSettingsNav() {
    return this.currentUserService.canManagePlaylist;
  }

  ngOnInit() {
    this.initVariables();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.currentTab = this.currentUserService.canManagePlaylist
      ? Tabs.SETTINGS
      : Tabs.DETAILS;

    if (!this.inlinePlaylistId) {
      this.subs.sink = this.route.params.subscribe((params) => {
        const encryptedPlaylistId = params.playlistId;
        if (encryptedPlaylistId) {
          this.initPlaylist(encryptedPlaylistId);
        }
      });
    }
  }

  async initPlaylist(id: string) {
    const decryptedId = this.encryptionService.decrypt(id);
    await this.initPlaylistDecrypted(decryptedId);
  }

  async initPlaylistDecrypted(decryptedId: string) {
    this.localStorageService.store(
      localStorageKeys.ACTIVE_PLAYLIST_ID,
      decryptedId
    );
    this.loaderMessage = 'FETCHING_PLAYLIST';
    this.loading = true;
    this.playlist = null;
    /* 
    this optimization is generating an 
    ERROR Invariant Violation: Invariant Violation 
    related to local cache when invoking the update mutation
    /Marco
    
    this.subs.sink = this.getPlaylistGQL
      .watch(
        {
          id: decryptedId,
        },
        {
          fetchPolicy: 'cache-and-network',
        }
      )
      .valueChanges.subscribe(({ loading, data: { playlist } }) => { */
    this.subs.sink = this.getPlaylistGQL
      .fetch({ id: decryptedId }, { fetchPolicy: 'network-only' })
      .subscribe(({ loading, data: { playlist } }) => {
        this.playlist = cloneDeep(playlist);
        this.breadcrumbService.set('@playlistManage', {
          skip: false,
          label: this.playlist?.name,
          info: 'fat',
        });
        if (playlist) {
          this.arcService.setCurrentPlaylistRegions(playlist);
        }
        this.loading = loading;
      });
  }

  canExit() {
    return true;
  }
}
