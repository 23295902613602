import { Component } from '@angular/core';
import { AssetItem, VideoAsset, ImageAsset, AssetType } from '@designage/gql';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getOptimizedUrl } from '@desquare/utils';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
  NgSwitchDefault,
} from '@angular/common';

@Component({
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    NgSwitchDefault,
    TranslateModule,
  ],
  selector: 'app-thumbnail-preview-dialog',
  templateUrl: './thumbnail-preview-dialog.component.html',
  styleUrls: ['./thumbnail-preview-dialog.component.scss'],
})
export class ThumbnailPreviewDialogComponent {
  assetItem!: AssetItem;

  constructor(public modal: NgbActiveModal) {}

  get videoUri() {
    const videoUri = (this.assetItem as VideoAsset).uri;
    return getOptimizedUrl(AssetType.Video, videoUri);
  }

  get imageUri() {
    const imageUri = (this.assetItem as ImageAsset).uri;
    return getOptimizedUrl(AssetType.Image, imageUri);
  }
}
