import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  NgbDate,
  NgbDateStruct,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';

// Reference: https://ng-bootstrap.github.io/#/components/datepicker/api
@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, NgbDatepickerModule],
  selector: 'designage-datepicker',
  template: `<div class="input-group">
    <input
      ngbDatepicker
      class="form-control form-control-dark date-display"
      type="button"
      #d="ngbDatepicker"
      name="dp"
      placeholder="YYYY-MM-DD"
      [disabled]="disabled"
      [value]="date"
      [minDate]="_minDate"
      [maxDate]="_maxDate"
      [startDate]="startDate"
      placement="bottom"
      (click)="d.toggle()"
      (dateSelect)="onDateSelection($event)"
    />
  </div> `,
  styleUrl: './datepicker.component.scss',
})
export class DatepickerComponent {
  @Input() disabled!: boolean;
  @Input()
  set date(value: string) {
    this._date = value ? value : 'YYYY-MM-DD';
    this.startDate = value ? this.getDate(value) : this.getDate('0000-00-00');
  }
  get date() {
    return this._date;
  }
  @Input()
  set minDate(value: string) {
    this._minDate = value ? this.getDate(value) : this.getDate('0000-00-00');
  }
  @Input()
  set maxDate(value: string) {
    this._maxDate = value ? this.getDate(value) : this.getDate('0000-00-00');
  }
  @Output() dateChange = new EventEmitter<string>();

  _date!: string;
  _minDate!: NgbDateStruct;
  _maxDate!: NgbDateStruct;
  startDate!: NgbDateStruct;

  constructor() {}

  onDateSelection(value: NgbDate) {
    this.date = this.getDateString(value);
    this.dateChange.emit(this.date);
  }

  getDate(value: string): NgbDateStruct {
    // YYYY-MM-DD
    const dateElements = value.split('-');
    return {
      year: parseInt(dateElements[0], 10),
      month: parseInt(dateElements[1], 10),
      day: parseInt(dateElements[2], 10),
    };
  }

  getDateString(value: NgbDate): string {
    return `${value.year}-${String(value.month).padStart(2, '0')}-${String(
      value.day
    ).padStart(2, '0')}`;
  }
}
