import { cloneDeep } from 'lodash';

export interface IDeviceCommandItem extends IButtonVisibility {
  label: string;
  iconClassName: string;
  callback: () => any;
}

interface IButtonVisibility {
  show?: boolean;
  disable?: boolean;
}

interface IDeviceCommandsControls {
  reboot?: IButtonVisibility;
  reloadApplet?: IButtonVisibility;
  updateApplet?: IButtonVisibility;
  republishContent?: IButtonVisibility;
  turnOnScreen?: IButtonVisibility;
  turnOffScreen?: IButtonVisibility;
  clearCache?: IButtonVisibility;
  refreshInfo?: IButtonVisibility;
}

export interface IDeviceCommandsComponentOptions {
  controls: IDeviceCommandsControls;
}

export const DeviceCommandsModel: IDeviceCommandsComponentOptions = {
  controls: {
    reboot: {
      disable: true,
      show: false,
    },
    clearCache: {
      disable: true,
      show: false,
    },
    updateApplet: {
      disable: true,
      show: false,
    },
    republishContent: {
      disable: true,
      show: false,
    },
    turnOnScreen: {
      disable: true,
      show: false,
    },
    turnOffScreen: {
      disable: true,
      show: false,
    },
    refreshInfo: {
      disable: true,
      show: false,
    },
  },
};

export function initDeviceCommandsOptions(): IDeviceCommandsComponentOptions {
  return cloneDeep(DeviceCommandsModel);
}
