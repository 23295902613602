import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RGBA } from '@cesdk/cesdk-js';
import { LayoutEditorService } from '@desquare/services';
import { DEFAULT_CANVAS_CONFIG, hexToCesdkRGBA } from '@desquare/utils';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ColorPickerModule,
  ],
  selector: 'app-layout-settings',
  template: `
    <form [formGroup]="detailSettingsFormGroup">
      <h5>&nbsp;</h5>
      <!-- name -->
      <div class="mb-3">
        <label for="name" class="form-label">
          {{ 'NAME' | translate }}
          <span
            *ngIf="
              formControls.name.errors && formControls.name.errors.required
            "
            class="text-danger"
          >
            * {{ 'REQUIRED' | translate }}
          </span>
        </label>
        <input
          type="text"
          class="form-control form-control-sm form-control-dark"
          formControlName="name"
        />
      </div>

      <!-- details -->
      <div class="mb-3">
        <label class="form-label" for="details">{{
          'DESCRIPTION' | translate
        }}</label>
        <textarea
          class="form-control form-control-sm form-control-dark"
          formControlName="description"
          [rows]="4"
        ></textarea>
      </div>

      <hr />

      <h5>{{ 'RESOLUTION' | translate }}</h5>

      <!-- width -->
      <div class="mb-3">
        <label class="form-label" for="width">{{ 'WIDTH' | translate }}</label>
        <input
          type="number"
          class="form-control form-control-sm form-control-dark"
          formControlName="width"
          (focus)="$event.target.select()"
        />
      </div>

      <!-- height -->
      <div class="mb-3">
        <label class="form-label" for="height">{{
          'HEIGHT' | translate
        }}</label>
        <input
          type="number"
          class="form-control form-control-sm form-control-dark"
          formControlName="height"
          (focus)="$event.target.select()"
        />
      </div>

      <hr />

      <!-- background color -->
      <div class="mb-3 d-flex flex-column">
        <label class="form-label" for="backgroundColor">{{
          'BACKGROUND_COLOR' | translate
        }}</label>
        <input
          [value]="color"
          [(colorPicker)]="color"
          [style.background]="color"
          [cpOutputFormat]="'hex'"
        />
      </div>
    </form>
  `,
  styleUrls: ['./details-settings.component.scss'],
})
export class DetailsSettingsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  @Input() isManageMode = false;
  @Input() parentFormGroup?: FormGroup;
  detailSettingsFormGroup!: FormGroup;
  // this is for easy access like using the form controls in the HTML template
  get formControls() {
    return this.detailSettingsFormGroup.controls;
  }

  // color format: hex
  set color(value: string | null) {
    this.formControls.color.setValue(value);
  }
  get color() {
    return this.formControls.color.value;
  }
  getColorInRgba$(): Observable<RGBA | null> {
    return this.formControls.color.valueChanges.pipe(
      map((value) => (!value ? null : hexToCesdkRGBA(value)))
    );
  }

  constructor(
    private formBuilder: FormBuilder,
    private layoutEditorService: LayoutEditorService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initForm() {
    const FORM_GROUP_NAME = 'detailSettingsForm';

    const defaultCanvas = this.layoutEditorService.ceBlock
      ? this.layoutEditorService.getCanvas()
      : undefined;

    this.detailSettingsFormGroup =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        width: [defaultCanvas?.width ?? DEFAULT_CANVAS_CONFIG.width],
        height: [defaultCanvas?.height ?? DEFAULT_CANVAS_CONFIG.height],
        color: [defaultCanvas?.color ?? DEFAULT_CANVAS_CONFIG.color],
      });

    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(
        FORM_GROUP_NAME,
        this.detailSettingsFormGroup
      );
    }
  }

  initSubscriptions() {
    // get only the first value
    this.subs.sink = this.layoutEditorService.canvas$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return (
            prev?.width === curr?.width &&
            prev?.height === curr?.height &&
            prev?.color === curr?.color
          );
        })
      )
      .subscribe((value) => {
        if (value !== null) {
          const { width, height, color } = value;

          this.detailSettingsFormGroup.patchValue(
            {
              width,
              height,
              color,
            },
            { emitEvent: false }
          );
        }
      });

    // update canvas width when form width changes
    this.subs.sink = this.formControls.width.valueChanges.subscribe((value) => {
      this.layoutEditorService.updateCanvas({ width: value });
    });

    // update canvas height when form height changes
    this.subs.sink = this.formControls.height.valueChanges.subscribe(
      (value) => {
        this.layoutEditorService.updateCanvas({ height: value });
      }
    );

    // update canvas color
    this.subs.sink = this.getColorInRgba$().subscribe((value) => {
      if (value) this.layoutEditorService.updateCanvas({ color: value });
    });
  }
}
