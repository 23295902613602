<ng-template #appLoader> </ng-template>
<div *ngIf="versions$ | async as versions">
  <app-loader *ngIf="loading"></app-loader>
  <div *ngIf="!versions?.length">{{ 'NO_VERSIONS' | translate }}</div>
  <div class="py-3">
    <div class="d-flex align-items-center form-group">
      <label class="">{{ 'VERSION' | translate }}</label>
      <div
        ngbDropdown
        #versionDropdown="ngbDropdown"
        class="form-control form-control-dark form-control-sm w-auto px-4 pointer"
      >
        <div
          id="versionDropdown"
          ngbDropdownToggle
          class="d-flex align-items-center"
        >
          <span
            class="badge dropdownItemStatus"
            [class.bg-success]="
              selectedVersion?.status === playlistStatus.Active
            "
            [class.bg-secondary]="
              selectedVersion?.status === playlistStatus.Archived
            "
          >
            {{ selectedVersion?.status | translate }}
          </span>
          <h5 class="my-1 mx-2">
            {{ selectedVersion?.createdAt | date : 'medium' }}
          </h5>
        </div>
        <div
          ngbDropdownMenu
          *ngIf="versions?.length > 1"
          aria-labelledby="versionDropdown"
          class="form-control form-control-dark shadowed-box text-white"
        >
          <div class="dropDownItemContainer">
            <ng-container *ngFor="let version of versions">
              <div
                ngbDropdownItem
                *ngIf="version.id !== selectedVersion?.id"
                class="d-flex align-items-center form-control form-control-dark pointer"
                (click)="
                  setSelectedVersion(version.id);
                  $event.stopPropagation();
                  versionDropdown.close()
                "
              >
                <span
                  class="badge dropdownItemStatus"
                  [class.bg-success]="version.status === playlistStatus.Active"
                  [class.bg-secondary]="
                    version.status === playlistStatus.Archived
                  "
                >
                  {{ version.status | translate }}
                </span>
                <span class="ms-2">{{
                  version?.createdAt | date : 'medium'
                }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-sm btn-outline-success ms-2"
        (click)="revert()"
        ngbTooltip="{{ 'REVERT_TO_VERSION' | translate }}"
      >
        {{ 'REVERT' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-outline-warning ms-2"
        (click)="createCopy()"
        ngbTooltip="{{ 'CREATE_COPY' | translate }}"
      >
        {{ 'COPY' | translate }}
      </button>
    </div>
  </div>
  <div class="gridComment" *ngIf="selectedVersion?.comment">
    <small class="form-text text-muted"
      >{{ 'COMMENT' | translate }}: {{ selectedVersion?.comment }}</small
    >
  </div>
  <div class="card">
    <ng-container
      *ngIf="selectedVersion && selectedVersion?.assets; else noHistory"
    >
      <H4 class="card-header"
        >{{ 'PUBLISHED' | translate }}
        {{ selectedVersion.createdAt | date : 'medium' }}</H4
      >
      <div class="card-body">
        <app-asset-row
          class="asset-row"
          *ngFor="let asset of selectedVersion?.assets"
          [asset]="asset"
          [currentPlaylistStatus]="selectedVersion?.status"
          [readOnly]="true"
          [collapseAll]="true"
        ></app-asset-row>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noHistory>
  <H3>{{ 'NO_HISTORY' | translate }}</H3>
</ng-template>
