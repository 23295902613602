import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AssetItem,
  AssetType,
  Maybe,
  Asset,
  ScheduleDays,
  ImageAsset,
  TransitionEffect,
  LayoutDetailedFragment,
  ChannelPlaylist,
  Channel,
  PlaylistDetailedFragment,
  ChannelsForChannelListFragment,
} from '@designage/gql';
import {
  PlaylistEditorService,
  AspectResizeCropService,
  TransitionService,
  LayoutDataService,
} from '@desquare/services';
import {
  getAssetStatus,
  getAssetItemStatus,
  getBlobURL,
  timer,
  getRandomString,
  screenUtil,
  getOptimizedUrl,
} from '@desquare/utils';
import { IScheduledDayIndicator, IZoneResolution } from '@desquare/interfaces';
import {
  ActivityStatus,
  ResizeCropMethodURIs,
  TransitionEffects,
} from '@desquare/enums';
import { flattenDeep, compact } from 'lodash';
import moment from 'moment';
import { SubSink } from 'subsink';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { environment } from '@desquare/environments';
import { IDeviceResolution } from '@designage/app/device-preview/device-preview-emulate/device-preview-emulate.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PreviewControlsComponent } from '../preview-controls/preview-controls.component';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import { NgStyle } from '@angular/common';

type ResolutionSource = 'SRC_ASSIGNED' | 'SRC_ALL' | 'SRC_GENERIC';

const SIZE_100MB = 100000000; // 100MB
@Component({
  standalone: true,
  imports: [
    NgStyle,
    TranslateModule,
    NgbTooltip,
    PreviewControlsComponent,
    LayoutExplorerComponent,
  ],
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.scss'],
})
export class ContentPreviewComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @ViewChild('video') video!: ElementRef;
  @ViewChild('image1') image1!: ElementRef;
  @ViewChild('image2') image2!: ElementRef;
  @ViewChild('previewContainer') previewContainer!: ElementRef;

  private previewContentValue: AssetItem[] = [];
  noScheduledContent!: boolean;

  get previewContents() {
    return this.previewContentValue;
  }
  @Input() set previewContents(contents: AssetItem[]) {
    this.previewContentValue = contents;
    this.initPreviewContents();
  }

  private _publishedAssets: Asset[] = [];

  get publishedAssets() {
    return this._publishedAssets;
  }
  @Input() set publishedAssets(assets: Asset[]) {
    this._publishedAssets = assets;
    const flattenedContents = flattenDeep(assets.map((x) => x.content));
    this.publishedContents = compact(flattenedContents);
  }

  private _isVisible!: boolean;

  get isVisible() {
    return this._isVisible;
  }
  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
    this.togglePlay(this._isVisible);
  }

  private _previewAssets: Asset[] = [];

  get previewAssets() {
    return this._previewAssets;
  }
  set previewAssets(assets: Asset[]) {
    this._previewAssets = assets;
    const flattenedContents = flattenDeep(assets.map((x) => x.content));
    this.previewContents = compact(flattenedContents);
  }

  @Output() simulateDateTimeChanges = new EventEmitter<moment.Moment>();
  @Input() publishedContents: AssetItem[] = [];
  @Input() showButtons = true;
  @Input() showControls = true;
  @Input() previewButtonText = 'PREVIEW';
  @Input() publishedButtonText = 'PUBLISHED';
  @Input() showPublishedButton = true;
  @Input() noContentText = 'NO_CONTENT_FOUND';
  @Input() isEmulator = false;
  @Input() isTheDetachedPreview = false;
  @Input() isWindowDetached = false;
  @Input() isDevicePreview = false;
  @Input() showSimulateSchedule = false;
  @Input() showAspectRatio = false;
  _channels: Maybe<ChannelsForChannelListFragment>[] = [];
  @Input() set channels(value: Maybe<ChannelsForChannelListFragment>[]) {
    this._channels = value;
    this.choosePreviewSource();
  }
  get channels() {
    return this._channels;
  }

  choosePreviewSource() {
    if (
      (this.selectedChannelRegions || []).length > 0 &&
      this.channels.length > 0
    ) {
      this.previewResolutionSource = 'SRC_ASSIGNED';
    }
    if (
      this.previewResolutionSource === 'SRC_ASSIGNED' &&
      (this.selectedChannelRegions || []).length === 0
    ) {
      this.previewResolutionSource = 'SRC_ALL';
    }
    if (
      this.previewResolutionSource === 'SRC_ALL' &&
      this.channels.length === 0
    ) {
      this.previewResolutionSource = 'SRC_GENERIC';
    }
    /*
    if (this.channels.length > 0 && this.selectedChannelRegions.length > 0) {
      if (this.previewState.selectedChannel != null) {
        this.selectedChannels.find(
          (x) => x?.id === this.previewState.selectedChannel
        ) || this.selectedChannels[0];
      } else {
        this.setSelectedChannel(
          this._selectedChannel?.id || this.selectedChannelRegions[0].channelId
        );
      }
    }*/
  }
  _selectedChannelRegions: Maybe<ChannelPlaylist[]> = [];
  @Input()
  set selectedChannelRegions(value: Maybe<ChannelPlaylist[]>) {
    this._selectedChannelRegions = value;
    this.choosePreviewSource();
  }
  get selectedChannelRegions() {
    return this._selectedChannelRegions;
  }

  @Input() playlist: Maybe<PlaylistDetailedFragment>;
  @Input() isBrowserRes = true;

  get selectedChannels() {
    const selectedChannelIds = (this.selectedChannelRegions || []).map(
      (x) => x.channelId
    );
    return this.channels.filter(
      (x) => !!x && selectedChannelIds.includes(x.id)
    );
  }

  contents!: AssetItem[];
  currentImageUri!: string;
  currentImageDuration!: number;
  currentVideoUri!: string;
  currentClVideoId!: Maybe<string>;
  currentHtmlContent!: string;
  nextContent!: Maybe<AssetItem>;
  isPublishedPlaying = false;
  currentImageName = '';
  durationTimeoutId!: Maybe<number>;
  simulateDateTime!: Maybe<moment.Moment>;
  simulateDate!: string;
  simulateTime!: string;
  enableSimulatePicker = false;
  isMuted = true;
  isPlaying = true;
  previewAssetsToEmulate: Asset[] = [];
  currentWindow!: Maybe<Window>;
  timer: any;
  currentPlayingImageElement!: string;
  previousTransitionEffect!: string;
  previousTransitionDuration!: number;
  showImage = true;
  previewResolutionSources: string[] = [];

  _previewResolutionSource: ResolutionSource = 'SRC_GENERIC';
  get previewResolutionSource() {
    return this._previewResolutionSource;
  }
  set previewResolutionSource(value: ResolutionSource) {
    const isChannelRegionInAssignedList = (
      channelId: string,
      region: string
    ) => {
      return (
        !(this.selectedChannelRegions || [])
          .map((x) => x.channelId)
          .includes(channelId) ||
        !(this.selectedChannelRegions || [])
          .map((x) => x.region)
          .includes(region)
      );
    };

    this._previewResolutionSource = value;
    switch (value) {
      case 'SRC_ASSIGNED':
        if (
          this.selectedChannelRegions &&
          this.selectedChannelRegions.length > 0 &&
          (!this.selectedChannelRegion ||
            !isChannelRegionInAssignedList(
              this.selectedChannelRegion.channelId,
              this.selectedChannelRegion.region || ''
            ))
        ) {
          this.setSelectedChannel(
            this.previewState.selectedChannel ||
              this.selectedChannelRegions[0].channelId
          );
        }

        // this.setPreviewResolution(e);
        break;

      case 'SRC_ALL':
        this.setSelectedLayout(this.layouts[0].id);
        // this.setPreviewResolution(e);
        break;

      case 'SRC_GENERIC':
        this.setPreviewResolution(
          this._previewResolutionSource,
          this.resolutions[0].resolution,
          this.zoneResolution.orientation
        );
        break;

      default:
        break;
    }
  }

  zoneResolution!: IZoneResolution;
  resolutions!: IZoneResolution[];
  selectedResolution!: string;
  selectedPreviewChannel!: Channel;
  layoutExplorerVisible = false;
  layoutExplorerSize = 250;
  isFullscreen = false;
  previewState!: {
    previewResolution: IZoneResolution;
    /** channel id */
    selectedChannel?: string;
  };

  layouts: LayoutDetailedFragment[] = [];
  selectedLayout: Maybe<LayoutDetailedFragment>;
  get selectedChannel() {
    return this.selectedChannels.find(
      (x) => !!x && x.id === this.selectedChannelRegion?.channelId
    );
  }

  selectedChannelRegion: Maybe<ChannelPlaylist>;

  _selectedLayoutRegion = '';
  get selectedLayoutRegion() {
    return this._selectedLayoutRegion;
  }
  set selectedLayoutRegion(value: string) {
    this._selectedLayoutRegion = value;
    this.setPreviewResolution('SRC_ALL', this._selectedLayoutRegion);
  }

  constructor(
    public playlistEditorService: PlaylistEditorService,
    private localStorageService: LocalStorageService,
    public layoutService: LayoutDataService,
    public arcService: AspectResizeCropService,
    private transitionService: TransitionService
  ) {}

  ngOnDestroy() {
    this.clearDetachPreviewValue();
    this.exitDetach();
    this.timer?.clearTimer();
    this.clearWaitDurationTimeout();
    this.subs.unsubscribe();
  }

  getPreviewState() {
    this.previewState = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PREVIEW_RESOLUTION
    );
    if (this.previewState != null) {
      return;
    }
    this.previewState = {
      previewResolution: { ...this.arcService.getPlaylistAspect() },
    };
  }
  ngOnInit() {
    this.getPreviewState();
    this.setResolutions();

    if (this.isTheDetachedPreview) {
      this.zoneResolution = this.previewState.previewResolution;
    } else {
      this.zoneResolution = this.arcService.getPlaylistAspect();
      this.selectedResolution = this.zoneResolution.resolution;
    }

    this.initSubscriptions();

    // if (this.isEmulator || this.isDevicePreview) {
    //   this.initPublishedContents();
    // }
    // this.initDetachedPreview();
  }

  async initSubscriptions() {
    this.layouts = await this.layoutService.getCurrentProfileLayouts();
    this.selectedLayout = this.layouts[0];

    this.previewAssets = this.playlistEditorService.getEditingSequence();

    this.subs.sink =
      this.playlistEditorService.editingSequencesChange.subscribe(
        (assets: Asset[]) => {
          this.previewAssets = assets;
          this.setDetachPreviewValue();
        }
      );

    this.subs.sink =
      this.playlistEditorService.playlistScheduleChanges.subscribe(() => {
        this.initializeContentPreviewer();
      });

    this.subs.sink = this.localStorageService
      .observe(localStorageKeys.SIMULATED_DATE)
      .subscribe(() => {
        this.initDetachedPreview();
      });

    this.subs.sink = this.localStorageService
      .observe(localStorageKeys.PREVIEW_ASSETS_TO_EMULATE)
      .subscribe(() => {
        this.initDetachedPreview();
      });

    this.subs.sink =
      this.playlistEditorService.previewPlayToggleTriggered.subscribe(
        (value: boolean) => {
          this.togglePlay(value);
        }
      );
  }

  get previewSources() {
    if (this.selectedChannels.length === 0) {
      return ['SRC_GENERIC', 'SRC_ALL'];
    } else {
      return ['SRC_ASSIGNED', 'SRC_ALL', 'SRC_GENERIC'];
    }
  }

  getPreviewerDrawerSize() {
    if (this.layoutExplorerVisible) return 0;
    return this.previewResolutionSource === 'SRC_GENERIC'
      ? '-127px'
      : `-${this.layoutExplorerSize + 90}px`;
  }

  getSelectedChannel(id: string) {
    const channel = this.channels.find((x) => x?.id === id);
    if (channel) {
      return channel.name;
    }
    return 'not set';
  }

  setSelectedChannel(id: string) {
    const tmpSelectedChannel = this.channels.find((x) => x?.id === id);
    if (tmpSelectedChannel) {
      this.selectedPreviewChannel = tmpSelectedChannel as Channel;

      this.setSelectedLayout(tmpSelectedChannel.layout?.id || '');
      this.setSelectedLayoutRegion(
        this.selectedChannelRegions?.find((x) => x.channelId === id)?.region ||
          ''
      );

      this.setPreviewResolution(this.previewResolutionSource);
      this.storeActivePreviewResolution();
    }
  }

  setSelectedLayout(id: string) {
    this.selectedLayout = this.layouts.find((x) => x.id === id);
    this.selectedLayoutRegion = '';
  }

  setSelectedLayoutRegion(regionName: string) {
    this.selectedLayoutRegion = regionName;
  }

  setResolutions() {
    // Show only one 16:9 aspect ratio
    this.resolutions = [...this.arcService.zoneResolutions];
  }

  initializeContentPreviewer() {
    if (this.isPublishedPlaying) {
      this.initPublishedContents();
    } else if (this.isTheDetachedPreview) {
      this.initDetachedPreview();
    } else {
      this.initPreviewContents();
    }
    this.setSimulatedDate();
  }

  initPreviewContents() {
    this.clearPreview();

    if (this.previewContents.length) {
      if (!this.image1 || !this.image2)
        setTimeout(() => {
          return this.initPreviewContents();
        }, 1000);
      this.playPreview(true);
    } else {
      this.contents = [];
    }
  }

  initPublishedContents() {
    this.clearPreview();
    if (this.publishedContents.length) {
      this.playPublished(true);
    } else {
      this.contents = [];
    }
  }

  initDetachedPreview() {
    if (this.isTheDetachedPreview) {
      this.previewAssetsToEmulate =
        this.localStorageService.retrieve(
          localStorageKeys.PREVIEW_ASSETS_TO_EMULATE
        ) || [];
      const flattenedContents = flattenDeep(
        this.previewAssetsToEmulate.map((x) => x.content)
      );
      this.initPlay();
      this.playContents(flattenedContents);
    }
  }

  clearContentUri() {
    this.currentImageUri = '';
    this.currentImageName = '';
    this.currentVideoUri = '';
    this.currentHtmlContent = '';
    this.previousTransitionEffect = 'None';
    // this.currentPlayingImageElement = 'image1';
  }

  initPlay() {
    this.clearContentUri();
    this.clearWaitDurationTimeout();
    this.timer?.clearTimer();
  }

  playContents(contents: AssetItem[]) {
    this.contents = contents.sort((a, b) =>
      a.sequence && b.sequence && a.sequence > b.sequence ? 1 : -1
    );
    if (this.contents.length) {
      this.play(this.getPlayableContent() ?? this.contents[0]);
    }
  }

  shouldPlay(content: AssetItem) {
    if (!content) {
      return false;
    }

    if (
      !this.isTheDetachedPreview &&
      this.isPublishedPlaying &&
      (this.playlistEditorService.publishedPlaylistStatus ===
        ActivityStatus.ACTIVE ||
        this.isEmulator)
    ) {
      return this.shouldAssetPlay(content, this.publishedAssets);
    } else if (
      !this.isTheDetachedPreview &&
      !this.isPublishedPlaying &&
      this.playlistEditorService.currentPlaylistStatus === ActivityStatus.ACTIVE
    ) {
      return this.shouldAssetPlay(content, this.previewAssets);
    } else if (
      this.isTheDetachedPreview &&
      this.localStorageService.retrieve(
        localStorageKeys.PLAYLIST_SCHEDULE_STATUS
      ) === ActivityStatus.ACTIVE
    ) {
      return this.shouldAssetPlay(content, this.previewAssetsToEmulate);
    } else if (this.isDevicePreview) {
      return this.shouldAssetPlay(content, this.publishedAssets);
    } else {
      return false;
    }
  }

  shouldAssetPlay(content: AssetItem, assets: Asset[]) {
    const asset = assets.find((x) =>
      x.content?.some((y) => y.id === content.id)
    );
    if (asset && content.days) {
      const storedDate = this.localStorageService.retrieve(
        localStorageKeys.SIMULATED_DATE
      );
      const simulateDateTime =
        this.isTheDetachedPreview && storedDate
          ? moment(storedDate)?.toDate()
          : this.simulateDateTime?.toDate();
      const assetStatus = getAssetStatus(
        asset,
        assets.length,
        simulateDateTime
      );
      const extractedDays = this.extractDays(content.days);
      const activityStatus = getAssetItemStatus(
        content,
        extractedDays,
        { status: assetStatus },
        simulateDateTime
      );

      return activityStatus === ActivityStatus.ACTIVE;
    } else {
      return false;
    }
  }

  private extractDays(days: ScheduleDays) {
    const dayObjects = Object.keys(days);

    return dayObjects.reduce<Partial<IScheduledDayIndicator>[]>(
      (accumulator, currentValue) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isSet = (days as any)[currentValue];

        accumulator.push({
          isSet,
        });

        return accumulator;
      },
      []
    );
  }

  async play(content: AssetItem) {
    this.clearContentUri();

    const nextContentIndex =
      this.contents.findIndex((x) => x.id === content.id) + 1;
    const nextPlayableContent = this.getPlayableContent(nextContentIndex);

    this.nextContent = nextPlayableContent
      ? nextPlayableContent
      : this.getPlayableContent();

    if (this.shouldPlay(content) && this.nextContent) {
      if (!content.__typename) {
        content.__typename = this.getContentTypeName(content.type);
      }

      if (
        content.__typename === 'ImageAsset' &&
        'uri' in content &&
        content.duration
      ) {
        this.showImage = true;
        this.previousTransitionDuration = this.previousTransitionDuration || 0;

        const elementToDisplay = this.getImageElementToDisplay();
        this.prepareContent(elementToDisplay, content);
        this.transition(
          elementToDisplay,
          this.getImageElementToHide(),
          content.transitionEffect || undefined
        );

        this.timer = timer(() => {
          if (this.nextContent) {
            this.currentPlayingImageElement = elementToDisplay;
            this.previousTransitionEffect =
              content.transitionEffect?.name || 'None';
            this.previousTransitionDuration =
              content.transitionEffect?.duration || 0;
            this.play(this.nextContent);
          }
        }, content.duration ?? 0);
      }

      if (
        content.type === AssetType.Html &&
        'htmlContent' in content &&
        content.duration
      ) {
        this.showImage = false;
        this.currentHtmlContent = getBlobURL(content.htmlContent, 'text/html');
        this.timer = timer(() => {
          if (this.nextContent) {
            this.play(this.nextContent);
          }
        }, content.duration ?? 0);
      }
      // Original cloudinary URL version of Video tag
      if (content.__typename === 'VideoAsset' && 'uri' in content) {
        const resizeCropMethod =
          content.resizeCropMethod?.uri || ResizeCropMethodURIs.PAD_URI;
        const mediaSize = content.media?.metadata?.bytes || 0;
        const applyTransformation = mediaSize <= SIZE_100MB ? true : false;
        this.hideImage();
        this.currentVideoUri = content.uri
          ? this.getResourceUrl(
              AssetType.Video,
              content.uri,
              resizeCropMethod,
              applyTransformation
            )
          : environment.assets.placeholderImage;
        await this.video?.nativeElement?.load();
      }

      // New cloudinary public-ID version of Video tag
      // if (content.type === AssetType.Video && 'publicId' in content) {
      //   this.hideImage();
      //   this.currentClVideoId = content.publicId;

      //   // ? getCloudinaryArgs(AssetType.Video, this.zoneResolution, resizeCropMethod)
      //   // : environment.assets.placeholderImage;
      //   await this.clVideo?.nativeElement?.load();
      // }

      // prevent content from playing when paused and toggled published/preview
      if (!this.isPlaying) {
        this.togglePlay(false);
      }
      this.noScheduledContent = false;
    } else {
      this.noScheduledContent = true;
      console.log('playablecontent: ', this.getPlayableContent());

      this.getPlayableContent()
        ? this.play(this.getPlayableContent() ?? this.contents[0])
        : this.clearContentUri();
      await this.waitDuration(1000);
    }
  }

  // slice contents to find next content in the array
  getPlayableContent(index: number = 0) {
    return this.contents.slice(index).find((x) => this.shouldPlay(x));
  }

  waitDuration(ms: number) {
    return new Promise(
      (resolve) => (this.durationTimeoutId = window.setTimeout(resolve, ms))
    );
  }

  // event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onVideoEnded() {
    if (this.nextContent) {
      this.play(this.nextContent);
    }
  }

  clearWaitDurationTimeout() {
    if (this.durationTimeoutId) {
      clearTimeout(this.durationTimeoutId);
    }
  }

  playPublished(hasContentChanges: boolean = false) {
    if (!this.isPublishedPlaying || hasContentChanges) {
      this.isPublishedPlaying = true;
      this.initPlay();
      this.playContents(this.publishedContents);
      this.setDetachPreviewValue();
      console.log('play Published');
    }
  }

  playPreview(hasContentChanges: boolean = false) {
    if (this.isPublishedPlaying || hasContentChanges) {
      this.isPublishedPlaying = false;
      this.initPlay();
      this.playContents(this.previewContents);
      this.setDetachPreviewValue();
    }
  }
  restartPreview() {
    this.initPlay();
    this.playContents(this.previewContents);
    this.setDetachPreviewValue();
  }

  clearPreview() {
    if (this.currentVideoUri) {
      // this.video?.nativeElement?.pause();
      this.video?.nativeElement?.pause();
    }
    this.clearContentUri();
    this.clearWaitDurationTimeout();
  }

  togglePlay(value: boolean) {
    if (!this.showControls) {
      return;
    }

    if (this.isPlaying) {
      if (this.currentVideoUri && !this.video?.nativeElement?.paused) {
        setTimeout(() => {
          this.video?.nativeElement?.pause();
        }, 100);
      } else {
        this.timer?.pause();
      }
    } else {
      if (this.currentVideoUri && this.video?.nativeElement?.paused) {
        this.video?.nativeElement?.play();
      } else {
        this.timer?.play();
      }
    }

    this.isPlaying = value;
  }

  toggleFullScreen(isFullScreenTrigger: boolean) {
    if (isFullScreenTrigger && !this.isFullscreen) {
      this.requestFullScreen();
    } else {
      this.exitFullScreen();
    }
  }

  toggleDetach(value: boolean) {
    if (!this.isWindowDetached) {
      this.detachPreview();
    } else {
      this.currentWindow?.focus();
    }
  }

  requestFullScreen() {
    const previewComponent = this.previewContainer.nativeElement;
    screenUtil.requestFullScreen(previewComponent);
    this.isFullscreen = true;
  }

  exitFullScreen() {
    screenUtil.exitFullScreen();
    this.isFullscreen = false;
  }

  setDetachPreviewValue() {
    const assetsToEmulate = this.isPublishedPlaying
      ? this.publishedAssets
      : this.previewAssets;
    const playlistScheduleStatus = this.isPublishedPlaying
      ? this.playlistEditorService.publishedPlaylistStatus
      : this.playlistEditorService.currentPlaylistStatus;

    this.localStorageService.store(
      localStorageKeys.PREVIEW_ASSETS_TO_EMULATE,
      assetsToEmulate
    );
    this.localStorageService.store(
      localStorageKeys.PLAYLIST_SCHEDULE_STATUS,
      playlistScheduleStatus
    );
  }

  clearDetachPreviewValue() {
    this.localStorageService.clear(localStorageKeys.ASSETS_TO_EMULATE);
    this.localStorageService.clear(localStorageKeys.SIMULATED_DATE);
    this.localStorageService.clear(localStorageKeys.PLAYLIST_SCHEDULE_STATUS);
  }

  setSimulatedDate() {
    if (this.simulateDateTime) {
      this.localStorageService.store(
        localStorageKeys.SIMULATED_DATE,
        this.simulateDateTime
      );

      this.simulateDate = this.simulateDateTime.format('YYYY-MM-DD');
      this.simulateTime = this.simulateDateTime.format('HH:mm');
    } else {
      this.localStorageService.clear(localStorageKeys.SIMULATED_DATE);
    }
  }

  detachPreview() {
    this.currentWindow = window.open(
      `${environment.urls.designageApp}/preview`,
      `preview-${getRandomString()}`,
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=no,
      resizable=no,
      width=640,
      height=640}`
    );

    if (this.currentWindow) {
      this.isWindowDetached = true;
      const intervalTimer = setInterval(() => {
        if (this.currentWindow?.closed) {
          clearInterval(intervalTimer);
          this.isWindowDetached = false;
        }
      }, 1000);
    }
  }

  exitDetach() {
    if (this.isTheDetachedPreview) {
      window.close();
    } else {
      this.currentWindow?.close();
    }
  }

  // event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleSimulatePreview(event: any) {
    setTimeout(() => {
      this.enableSimulatePicker = event.target.checked;
    });
    if (event.target.checked === true) {
      this.simulateDateTime = moment();
      this.simulateDate = this.simulateDateTime.format('YYYY-MM-DD');
      this.simulateTime = this.simulateDateTime.format('HH:mm');
    } else {
      this.simulateDateTime = null;
      this.simulateDate = '';
      this.simulateTime = '';
    }

    this.playlistEditorService.simulateDateTime.emit(
      this.simulateDateTime || moment()
    );
    this.simulateDateTimeChanges.emit(this.simulateDateTime || moment());
    this.initializeContentPreviewer();
  }

  onSimulateDateTimeChange() {
    this.simulateDateTime = moment(`${this.simulateDate} ${this.simulateTime}`);
    this.playlistEditorService.simulateDateTime.emit(this.simulateDateTime);
    this.simulateDateTimeChanges.emit(this.simulateDateTime);

    this.initializeContentPreviewer();
  }

  getImageElementToDisplay() {
    if (this.currentPlayingImageElement === 'image1') {
      return 'image2';
    } else {
      return 'image1';
    }
  }

  getImageElementToHide() {
    if (this.currentPlayingImageElement === 'image1') {
      return 'image1';
    } else {
      return 'image2';
    }
  }

  prepareContent(elementToDisplay: string, content: ImageAsset) {
    const resizeCropMethod =
      content.resizeCropMethod?.uri || ResizeCropMethodURIs.PAD_URI;
    if (this.image1 && elementToDisplay === 'image1') {
      this.image1.nativeElement.src = content.uri
        ? this.getResourceUrl(AssetType.Image, content.uri, resizeCropMethod)
        : environment.assets.placeholderImage;
    } else if (this.image2 && elementToDisplay === 'image2') {
      this.image2.nativeElement.src = content.uri
        ? this.getResourceUrl(AssetType.Image, content.uri, resizeCropMethod)
        : environment.assets.placeholderImage;
    }
  }

  async transition(
    elementToDisplay: string,
    elementToHide: string,
    transition?: TransitionEffect
  ) {
    if (this.image1 && this.image2) {
      if (transition?.name === TransitionEffects.FADE) {
        const duration = (transition?.duration || 0) / 1000;
        await this.transitionService.fade(
          '#'.concat(elementToDisplay),
          '#'.concat(elementToHide),
          duration
        );
      } else {
        await this.transitionService.cut(
          '#'.concat(elementToDisplay),
          '#'.concat(elementToHide)
        );
      }
    }
  }

  hideImage() {
    if (this.image1) this.transitionService.hideElements(['#image1']);
    if (this.image2) this.transitionService.hideElements(['#image2']);
  }

  changePreviewSource(e: 'SRC_ASSIGNED' | 'SRC_ALL' | 'SRC_GENERIC') {
    this.previewResolutionSource = e;
  }

  setPreviewResolution(
    source: 'SRC_ASSIGNED' | 'SRC_ALL' | 'SRC_GENERIC',
    resolution?: string,
    orientation?: string
  ) {
    if (source === 'SRC_GENERIC') {
      const selectedResolution = this.arcService.zoneResolutions.find(
        (res) => res.resolution === resolution
      );
      if (selectedResolution) {
        if (
          orientation === 'portrait' &&
          this.zoneResolution.orientation !== 'portrait' &&
          selectedResolution.height < selectedResolution.width
        ) {
          const width = selectedResolution.height;
          const height = selectedResolution.width;
          selectedResolution.width = width;
          selectedResolution.height = height;
          selectedResolution.aspect = (height / width) * 100;
          selectedResolution.orientation = orientation;
        }
        if (
          orientation === 'landscape' &&
          this.zoneResolution.orientation !== 'landscape' &&
          selectedResolution.height > selectedResolution.width
        ) {
          const width = selectedResolution.height;
          const height = selectedResolution.width;
          selectedResolution.width = width;
          selectedResolution.height = height;
          selectedResolution.aspect = (height / width) * 100;
          selectedResolution.orientation = orientation;
        }

        this.zoneResolution = selectedResolution;
      }
    } else if (source === 'SRC_ASSIGNED') {
      const selectedResolution = this.selectedLayout
        ? this.arcService.getRegionResolution(
            this.selectedLayout,
            this.selectedLayoutRegion
          )
        : null;
      if (selectedResolution) {
        selectedResolution.aspect =
          (selectedResolution.height / selectedResolution.width) * 100;
        this.zoneResolution = selectedResolution;
      }
    } else if (source === 'SRC_ALL') {
      const selectedResolution = this.selectedLayout
        ? this.arcService.getRegionResolution(
            this.selectedLayout,
            this.selectedLayoutRegion
          )
        : null;

      if (selectedResolution) {
        selectedResolution.aspect =
          (selectedResolution.height / selectedResolution.width) * 100;
        this.zoneResolution = selectedResolution;
      }
    }

    this.setPlaylistAspect();
    this.initializeContentPreviewer();
  }

  storeActivePreviewResolution() {
    this.previewState = {
      previewResolution: { ...this.zoneResolution },
      selectedChannel: this.selectedChannel?.id,
    };
    this.localStorageService.store(
      localStorageKeys.ACTIVE_PREVIEW_RESOLUTION,
      this.previewState
    );
  }

  setPlaylistAspect() {
    this.arcService.zoneResolutions.map((res) => {
      res.default = false;
    });
    this.zoneResolution.default = true;
    this.arcService.updatePlaylistAspect(this.zoneResolution);
    // this.playlistEditorService.sequenceTouch.emit(true);
  }

  getContentTypeName(type: AssetType) {
    switch (type) {
      case AssetType.Image:
        return 'ImageAsset';
      case AssetType.Video:
        return 'VideoAsset';
      case AssetType.Html:
        return 'HtmlAsset';
      default:
        return undefined;
    }
  }

  getResourceUrl(
    assetType: AssetType,
    contentUri: string,
    resizeCropMethod: string,
    applyTransformation: boolean = true
  ) {
    let resolution: IZoneResolution;
    if (this.showAspectRatio) {
      resolution = this.zoneResolution;
    } else if (this.isTheDetachedPreview) {
      resolution = this.localStorageService.retrieve(
        localStorageKeys.ACTIVE_PREVIEW_RESOLUTION
      ).previewResolution;
    } else {
      const previewerSettings = this.localStorageService.retrieve(
        localStorageKeys.DEVICE_PREVIEWER_SETTINGS
      );
      const activeDeviceId = this.localStorageService.retrieve(
        localStorageKeys.ACTIVE_DEVICE_ID
      );
      const index = previewerSettings?.findIndex(
        (x: IDeviceResolution) => x.id === activeDeviceId
      );
      resolution = {
        resolution: previewerSettings[index].name,
        width: previewerSettings[index].width,
        height: previewerSettings[index].height,
        aspect: previewerSettings[index].aspect,
        orientation: previewerSettings[index].orientation,
        default: false,
      };
    }

    return applyTransformation
      ? getOptimizedUrl(assetType, contentUri, resolution, resizeCropMethod)
      : getOptimizedUrl(assetType, contentUri);
  }
}
