<div class="main" [style.width]="boundingBox" [style.height]="boundingBox">
  <div
    id="screen"
    class="screen"
    [ngClass]="{ selected: isSelected(''), normal: !isSelected('') }"
    [style.top]="screenDivTop"
    [style.left]="screenDivLeft"
    [style.width]="screenDivWidth"
    [style.height]="screenDivHeight"
  >
    <div *ngIf="regionDivs.length === 0 && showText" class="text-center">
      {{ 'REGION_CANVAS_ROOT' | translate }}
    </div>
    <div
      *ngFor="let region of regionDivs"
      class="region"
      [ngClass]="{
        selected: isSelected(region.regionName),
        normal: !isSelected(region.regionName)
      }"
      [style.top]="region.top"
      [style.left]="region.left"
      [style.width]="region.width"
      [style.height]="region.height"
      (click)="selectRegion(region)"
      [ngbTooltip]="region.regionName"
    >
      <div
        *ngIf="!(isSelected(region.regionName) && selectedPreview) && showText"
        class="text-center"
      >
        {{ region.regionName }}
      </div>
      <app-asset-item-thumbnail
        *ngIf="isSelected(region.regionName) && selectedPreview"
        [assetItem]="selectedPreview"
      ></app-asset-item-thumbnail>
    </div>
  </div>
</div>
