import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ElementRef,
  ViewChild,
  input,
  ChangeDetectionStrategy,
  effect,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { v4 as uuidV4 } from 'uuid';
import {
  Asset,
  ScheduleDays,
  Maybe,
  AssetItem,
  ImageAsset,
  IFrameAsset,
  Media,
  VideoAsset,
  ResourceType,
  PlaylistType,
  MediaDetailedFragment,
  AssetType,
} from '@designage/gql';
import {
  getLocalizedTime,
  getDateTime,
  getISOTime,
  getAssetStatus,
  getActivityStatusColor,
  getRandomString,
  getISOTimeNoTz,
  AssetItemIsVideo,
  AssetItemIsInteractiveTargetVideo,
  AssetItemIsImage,
  AssetItemIsImageOrVideo,
  AssetItemToImageOrVideo,
  dragDropCursorUtil,
} from '@desquare/utils';
import {
  AspectResizeCropService,
  CurrentUserService,
  MediaService,
  PlaylistViewService,
  PlaylistEditorService,
  SessionService,
} from '@desquare/services';
import { SubSink } from 'subsink';
import {
  IScheduledDayIndicator,
  IActivityStatus,
  IInteractiveLayoutModalResponse,
} from '@desquare/interfaces';
import { ActivityStatusColor, MediaSourceTypes } from '@desquare/enums';
import { ActivityStatus, CeCalledFrom } from '@desquare/enums';
import {
  NgbModal,
  NgbAccordionModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { DeleteFromPlaylistDialogComponent } from '../delete-from-playlist-dialog/delete-from-playlist-dialog.component';
import { AddAssetContentDialogComponent } from '@designage/app/asset/add-asset-content-dialog/add-asset-content-dialog.component';
import { trapLetters as _trapLetters } from '@desquare/utils';
import { cloneDeep } from 'lodash';
import { EditAssetContentDialogComponent } from '@designage/app/asset/edit-asset-content-dialog/edit-asset-content-dialog.component';
import { CreativeEditorComponent } from '@designage/app/creative-editor/creative-editor/creative-editor.component';
import _ from 'lodash';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { IframeEditorComponent } from '@designage/app/shared/iframe-editor/iframe-editor.component';
import { InteractiveLayoutManageDialogComponent } from '@designage/app/layout/interactive-layout-manage-dialog/interactive-layout-manage-dialog.component';
import { ContentRowComponent } from '../content-row/content-row.component';
import { DurationPipe } from '@desquare/components/common/src/pipe/duration/duration.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DragDropModule,
    TranslateModule,
    DurationPipe,
    DateProxyPipe,
    NgbAccordionModule,
    NgbDropdownModule,
    ContentRowComponent,
    TimepickerComponent,
  ],
  selector: 'app-asset-row',
  templateUrl: './asset-row.component.html',
  styleUrls: ['./asset-row.component.scss'],
  providers: [MediaService, DateProxyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetRowComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  asset = input.required<Asset>();

  @ViewChild('nameInput') nameInput!: ElementRef;

  @Input() isChild!: boolean;
  @Input() readOnly!: boolean;
  /** this is the SEQUENCE!!~ */
  @Input() simpleUiActive!: boolean;

  @Input() currentPlaylistStatus!: ActivityStatus;
  @Input() collapseAll = false;

  _assetsCount = 0;
  @Input()
  get assetsCount() {
    return this._assetsCount;
  }
  set assetsCount(value: number) {
    this._assetsCount = value;
  }

  /** if there's only 1 sequence, no need to show the name of it */
  get hideAssetName() {
    return this.assetsCount < 2;
  }

  get isInteractive() {
    return this.playlistType === PlaylistType.Interactive;
  }
  get playlistType() {
    return this.playlistEditorService.playlistType;
  }

  /**
   * gets the sequence (formerly known as asset) ids. (assetIds = sequenceIds)
   */
  get sequenceIds() {
    return this.playlistEditorService.editingSequences.map((asset) => asset.id);
  }

  get startTime() {
    return this.asset().startTime
      ? getLocalizedTime(this.asset().startTime)
      : null;
  }

  get endTime() {
    return this.asset().endTime ? getLocalizedTime(this.asset().endTime) : null;
  }

  /**
   * gets the sequence (formerly known as an asset) id
   */
  get sequenceId() {
    return this.asset().id;
  }

  contentStyle = {};
  dateDuration!: string;
  timeDuration!: Maybe<string>;
  defaultScheduleDays!: ScheduleDays;
  scheduleDays!: IScheduledDayIndicator[];
  activityStatus!: IActivityStatus;
  monitorTimer!: NodeJS.Timer;
  startTimeRawValue!: Maybe<moment.Moment>;
  endTimeRawValue!: Maybe<moment.Moment>;
  nameValue!: string;
  isEditingName!: boolean;
  isDuplicateStartTime!: boolean;
  trapLetters = _trapLetters;
  simulateDateTime!: Maybe<moment.Moment>;
  profileId!: Maybe<string>;
  isStartTimeInputOpen!: boolean;
  startTimeInputValue!: string;
  totalDuration!: number;
  /** is this used? from playlistEditorService */
  assetItems!: Maybe<AssetItem[]>;

  constructor(
    private currentUserService: CurrentUserService,
    private playlistEditorService: PlaylistEditorService,
    private modalService: NgbModal,
    private session: SessionService,
    private playlistViewService: PlaylistViewService,
    private mediaService: MediaService,
    private arcService: AspectResizeCropService
  ) {
    // effect(() => {
    //   console.log('asset', this.asset());
    // });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    clearInterval(this.monitorTimer as any);
  }

  ngOnInit() {
    this.profileId = this.session.profileId();
    this.initValues();
    this.initDynamicLabels();
    this.initSubscriptions();
    // console.log('setting Values');

    this.setValues();
  }

  // dynamic labels
  ADD_CONTENT = 'ADD_CONTENT';
  DELETE_SEQUENCE = 'DELETE_SEQUENCE';
  DELETE_SEQUENCE_PROMPT = 'DELETE_SEQUENCE_PROMPT';
  DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_SUCCESS';

  initDynamicLabels() {
    if (this.isInteractive) {
      this.ADD_CONTENT = 'ADD_BACKGROUND';
      this.DELETE_SEQUENCE = 'DELETE_SEQUENCE_INTERACTIVE';
      this.DELETE_SEQUENCE_PROMPT = 'DELETE_SEQUENCE_INTERACTIVE_PROMPT';
      this.DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_INTERACTIVE_SUCCESS';
    }
  }

  async initSubscriptions() {
    this.subs.sink =
      this.playlistEditorService.editingSequencesChange.subscribe(() => {
        this.setValues();
      });

    this.subs.sink =
      this.playlistEditorService.duplicateStartTimeAssets.subscribe(
        (assets: Asset[]) => {
          this.setDuplicateStartTimeStatus(assets);
        }
      );

    this.playlistEditorService.editingAssetItemsChange.subscribe(
      (assetItems: AssetItem[]) => {
        this.assetItems = assetItems;
      }
    );

    this.subs.sink = this.playlistEditorService.simulateDateTime.subscribe(
      (value: Maybe<moment.Moment>) => {
        this.simulateDateTime = value;
      }
    );
  }

  initValues() {
    this.totalDuration = 0;

    this.defaultScheduleDays = {
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
    };

    this.activityStatus = {
      status: ActivityStatus.INACTIVE,
      style: {
        'background-color': ActivityStatusColor.INACTIVE,
      },
    };
  }

  /** set ui form values based on this.asset */
  setValues() {
    this.totalDuration = this.asset().content?.reduce<number>(
      (accumulator, assetItem) => {
        if (assetItem.duration) {
          accumulator += assetItem.duration;
          if (assetItem.__typename === 'ImageAsset') {
            accumulator += assetItem.transitionEffect?.duration || 0;
          }
        }

        return accumulator;
      },
      0
    );

    if (
      this.asset &&
      this.asset().actualStartTime &&
      this.asset().actualEndTime
    ) {
      this.startTimeInputValue =
        this.asset().actualStartTime?.substring(0, 5) || '';
      const localizedStartTime = this.asset().actualStartTime?.substring(0, 5);
      const localizedEndTime = this.asset().actualEndTime?.substring(0, 5);

      if (localizedStartTime) {
        // console.log('localizedStartTime', localizedStartTime);
        this.startTimeRawValue = moment(getDateTime(localizedStartTime));
        // console.log('startTimeRawValue', this.startTimeRawValue);
        this.startTimeInputValue = moment(
          getDateTime(localizedStartTime)
        ).format('HH:mm');
      }

      if (localizedEndTime) {
        this.endTimeRawValue = moment(getDateTime(localizedEndTime));
      }
    } else {
      this.startTimeRawValue = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      this.startTimeInputValue = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('HH:mm');

      this.endTimeRawValue = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
    this.monitorActivityStatus();

    this.setDuplicateStartTimeStatus(
      this.playlistEditorService.duplicateStartTimeAssetsValue
    );
  }

  setDuplicateStartTimeStatus(assets: Asset[]) {
    if (assets.some((x) => x.id === this.asset().id)) {
      this.isDuplicateStartTime = true;
    } else {
      this.isDuplicateStartTime = false;
    }
  }

  monitorActivityStatus() {
    this.setActivityStatus();
    this.monitorTimer = setInterval(() => {
      this.setActivityStatus();
    }, 3000);
  }

  private setActivityStatus() {
    let activityStatus: ActivityStatus;

    if (
      this.playlistEditorService.currentPlaylistStatus ??
      this.currentPlaylistStatus === ActivityStatus.ACTIVE
    ) {
      // supplying currentPlaylistStatus means this component is used by an external parent
      activityStatus =
        getAssetStatus(
          this.asset(),
          this.assetsCount,
          this.simulateDateTime?.toDate()
        ) === ActivityStatus.ACTIVE
          ? ActivityStatus.ACTIVE
          : ActivityStatus.WAITING;
    } else {
      activityStatus = this.playlistEditorService.currentPlaylistStatus;
    }

    activityStatus = this.isDuplicateStartTime
      ? ActivityStatus.INACTIVE
      : activityStatus;
    this.activityStatus = {
      status: activityStatus,
      style: {
        'background-color': getActivityStatusColor(activityStatus),
      },
    };
  }

  onStartTimeInputClose() {
    console.log('onStartTimeInputClose', this.startTimeInputValue);
    // if (this.isStartTimeInputOpen) {
    this.isStartTimeInputOpen = false;
    const validStartTimeFormat = moment(
      this.startTimeInputValue,
      'HH:mm',
      true
    ).isValid();

    if (validStartTimeFormat) {
      const startTimeMoment = moment(this.startTimeInputValue, 'HH:mm');
      this.startTimeRawValue = startTimeMoment;
      this.asset().startTime = getISOTime(startTimeMoment.seconds(0));
      this.asset().actualStartTime = getISOTimeNoTz(startTimeMoment.seconds(0));
      console.log('notz', getISOTimeNoTz(startTimeMoment.seconds(0)));
    } else {
      this.startTimeRawValue = null;
      this.asset().startTime = null;
      this.asset().actualStartTime = '00:00:00';
    }

    this.playlistEditorService.setSequenceValidity();
    // }
  }

  onStartTImeInputOpen() {
    // prevent (close) from triggering when clicking anywhere in the page although picker is not open
    this.isStartTimeInputOpen = true;
  }

  updateAssetDetail() {
    this.isEditingName = false;
    this.playlistEditorService.updateAssetDetails(this.asset());
  }

  // MARCO: I THINK THIS IS NOT DOING ANYTHING, REMOVE IT
  async onDrop() {
    if (this.assetItems) {
      this.assetItems.forEach(async (x) => {
        // this.playlistEditorService.addContent(x, this.asset().id);
        await this.doAddContent(cloneDeep(x));
      });
    }
    this.emitSelectedAssets();
    this.playlistEditorService.editingAssetItems = [];
    this.assetItems = [];
  }

  /** open a popup dialog to add image or video */
  addContent() {
    this.playlistEditorService.closePickers.emit();

    const modalRef = this.modalService.open(AddAssetContentDialogComponent, {
      windowClass: 'cesdk-modal',
      backdrop: 'static',
    });
    modalRef.componentInstance.profileId = this.profileId;
    modalRef.componentInstance.multiSelect = !this.isInteractive;
    modalRef.result.then(
      async (value) => {
        if ('mediaList' in value && value.mediaList?.length) {
          for (const media of value.mediaList) {
            await this.doAddContent(media);
          }
        } else {
          await this.doAddContent(value);
        }
      },
      () => {}
    );
  }

  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  async addIframe() {
    const modalRef = this.modalService.open(IframeEditorComponent, {
      windowClass: 'cesdk-modal',
      // size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.assetId;
    modalRef.componentInstance.assetItemId;

    modalRef.componentInstance.htmlObject.subscribe(
      (iframeContent: IFrameAsset) => {
        console.log(iframeContent);
        this.doAddContent(iframeContent);
      }
    );
  }

  async doAddContent(content: AssetItem, insertIndex?: number) {
    const id = getRandomString(); // uuidV4();
    content.id = id;
    if (this.isInteractive && AssetItemIsImageOrVideo(content)) {
      await this.setAssetItemDimensionsFromMedia(
        AssetItemToImageOrVideo(content)
      );
    }
    await this.playlistEditorService.addContent(
      content,
      this.asset().id,
      insertIndex
    );
    this.playlistViewService.setContentOpenStatus(
      this.asset().id,
      content.id!,
      true
    );
  }

  async setAssetItemDimensionsFromMedia(
    content: ImageAsset | VideoAsset | undefined
  ) {
    if (content?.contentId) {
      const media = content.media?.metadata?.width
        ? content.media
        : await this.mediaService.getMediaById(content.contentId);
      if (media) {
        content.height = media.metadata?.height;
        content.width = media.metadata?.width;
      }
    }

    return content;
  }

  /** removes a SEQUENCE */
  removeAsset() {
    const modal = this.modalService.open(DeleteFromPlaylistDialogComponent);
    modal.componentInstance.messagePrompt = this.DELETE_SEQUENCE_PROMPT;
    modal.result
      .then(() => {
        this.playlistEditorService.deleteAsset(
          this.asset().id,
          this.DELETE_SEQUENCE_SUCCESS
        );
        // TODO: in case of errors this will create an out of sync situation, we delete asset from ui but is present on db!
        this.playlistViewService.deleteAsset(this.asset().id);
      })
      .catch(() => {});
  }

  removeContent(assetItem: AssetItem, emitChangeEvents = true) {
    if (this.asset().content) {
      this.asset().content.splice(this.asset().content.indexOf(assetItem), 1);
      this.playlistViewService.deleteContent(this.asset().id, assetItem.id);
      if (emitChangeEvents) {
        this.emitSelectedAssets();
      }
    }
  }

  // media or interactive layout
  editContent(asset: { assetItem: AssetItem; editActions: boolean }) {
    const { assetItem, editActions } = asset;

    if (this.asset().content) {
      // Target Content to update
      const contentIndexToUpdate = this.asset().content.indexOf(assetItem);
      switch (assetItem.__typename) {
        case 'ImageAsset':
          if (editActions) {
            this.editInteractiveLayout(assetItem);
          } else {
            this.editImageOrVideo(
              MediaSourceTypes.PLAYLIST_IMAGE,
              assetItem,
              contentIndexToUpdate
            );
          }
          break;
        case 'VideoAsset':
          if (editActions) {
            this.editInteractiveLayout(assetItem);
          } else {
            this.editImageOrVideo(
              MediaSourceTypes.PLAYLIST_VIDEO,
              assetItem,
              contentIndexToUpdate
            );
          }
          break;
        case 'IFrameAsset':
          this.editIFrameAsset(assetItem, contentIndexToUpdate);
          break;

        default:
          break;
      }
    }
  }

  replaceContent(oldContent: AssetItem) {
    if (this.asset().content) {
      // Target Content to update
      const contentIndexToUpdate = this.asset().content.indexOf(oldContent);

      this.playlistEditorService.closePickers.emit();

      const modalRef = this.modalService.open(EditAssetContentDialogComponent, {
        size: 'xl',
        backdrop: 'static',
      });

      modalRef.componentInstance.profileId = this.profileId;
      modalRef.componentInstance.multiSelect = false;
      modalRef.result.then(
        (newContent: AssetItem) => {
          this.doReplaceContent(contentIndexToUpdate, newContent, oldContent);
          // notify the save button that a change has been made
          this.emitSelectedAssets();
        },
        () => {}
      );
    }
  }
  doReplaceContent(
    contentIndexToUpdate: number,
    newContent: AssetItem,
    oldContent: AssetItem
  ) {
    console.log(
      'doReplaceContent',
      contentIndexToUpdate,
      newContent,
      oldContent
    );
    const mergedContent = { ...oldContent, ...newContent };
    newContent.id = uuidV4();
    // edit content
    this.playlistEditorService.updateContent(
      contentIndexToUpdate,
      mergedContent,
      this.asset().id
    );
    this.playlistViewService.deleteContent(this.asset().id, oldContent.id);
    this.playlistViewService.setContentOpenStatus(
      this.asset().id,
      newContent.id,
      true
    );
    if (AssetItemIsInteractiveTargetVideo(oldContent)) {
      this.reLinkActionToTarget(oldContent.contentId, newContent.contentId);
    }
  }

  async duplicateContent(assetItem: AssetItem) {
    if (this.asset().content) {
      const newContent = cloneDeep(assetItem);
      // create a new content id
      newContent.id = uuidV4();
      newContent.name = this.getCopyName(newContent.name);
      await this.playlistEditorService.addContent(newContent, this.asset().id);
      this.emitSelectedAssets();
    }
  }

  getCopyName(name: string) {
    let find = name;
    let count = 1;

    while (this.asset().content.findIndex((x) => x.name === find) >= 0) {
      find = `${name}_${count}`;
      count++;
    }
    return find;
  }

  /** wtf rename this! */
  emitSelectedAssets() {
    this.playlistEditorService.sequenceTouch.emit(true);
    this.playlistEditorService.emitEditingSequencesChange();
  }

  editName(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.isEditingName = true;
    setTimeout(() => {
      this.nameInput.nativeElement.select();
    });
  }

  isContentOpen(contentId: string) {
    return this.playlistViewService.isContentOpen(this.asset().id, contentId);
  }

  isContentPinned(contentId: string) {
    return this.playlistViewService.isContentPinned(this.asset().id, contentId);
  }

  async drop(event: CdkDragDrop<unknown[]>) {
    if (this.isInteractive) {
      return;
    }

    console.log('drop', event);

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      // this is the dropped item
      const content = event.item.data;

      // if dropped item is from media-gallery
      if (
        event.previousContainer.id === 'media-gallery' ||
        event.previousContainer.id === 'media-gallery-list'
      ) {
        // TODO: the content type (AssetItem, AssetContent, AssetInput, Media etc.) should be one type
        // atm the type of content in media-gallery is not the same
        // as the content in asset-row, in my opinion they should have
        // consistent types throughout the codebase so we don't have
        // to make unnecessary transformations/mutations
        const transformedContent =
          this.playlistEditorService.toAssetItem(content);
        if (transformedContent) {
          const insertIndex = event.currentIndex;
          await this.doAddContent(transformedContent, insertIndex);
        }
      } else {
        // else its probably from a different sequence
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }

    await this.onDrop();
    dragDropCursorUtil.cursorReset();
  }

  editImageOrVideo(
    mediaType: MediaSourceTypes,
    oldContent: AssetItem,
    index: number
  ) {
    const editingItem = oldContent as ImageAsset;
    const videoEditingItem = oldContent as VideoAsset;

    const modalRef = this.modalService.open(CreativeEditorComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });
    modalRef.componentInstance.calledFrom = CeCalledFrom.PLAYLIST;
    modalRef.componentInstance.mediaId = editingItem.media?.id;
    // following if block should be moved in CreativeEditor component
    if (
      mediaType === (MediaSourceTypes.PLAYLIST_IMAGE || MediaSourceTypes.IMAGE)
    ) {
      modalRef.componentInstance.initialSceneMode = 'Design';
      modalRef.componentInstance.inputImgUrl = editingItem.uri;
    } else if (
      mediaType === (MediaSourceTypes.PLAYLIST_VIDEO || MediaSourceTypes.VIDEO)
    ) {
      modalRef.componentInstance.initialSceneMode = 'Video';
      modalRef.componentInstance.inputVideoUrl = videoEditingItem.uri;
    }
    // Creative Editor should access arcService instead
    modalRef.componentInstance.previewResolution =
      this.arcService.getPlaylistAspect();
    // modalRef.dismiss();
    modalRef.result.then(
      (newMedia?: Media) => {
        if (newMedia) {
          // console.log('oldContent', oldContent);
          // console.log('substitute media in asset item', newMedia);

          if (newMedia.id !== oldContent.contentId) {
            const newContent = cloneDeep(oldContent);
            newContent.id = uuidV4();
            this.setAssetItemMedia(newContent, newMedia);
            this.doReplaceContent(index, newContent, oldContent);
            if (AssetItemIsInteractiveTargetVideo(oldContent)) {
              this.reLinkActionToTarget(
                oldContent.contentId,
                newContent.contentId
              );
            }
            console.log('substituted', newContent);
          } else {
            (oldContent as ImageAsset).uri = newMedia.secureUrl;
          }

          // refresh media-gallery
          this.mediaService.refetchQueries();

          // notify the save button that a change has been made
          this.emitSelectedAssets();
        }
      },
      () => {}
    );
  }
  /** every asset list can have max 1 interactive page background (image or video) */
  getInteractivePageBgItem() {
    for (const item of this.asset().content) {
      if (AssetItemIsImage(item)) {
        const imageAsset = item as ImageAsset;
        if (
          imageAsset.interactiveRegions &&
          imageAsset.interactiveRegions.length > 0
        ) {
          return imageAsset;
        }
      }
      if (AssetItemIsVideo(item)) {
        const videoAsset = item as VideoAsset;
        if (
          videoAsset.interactiveRegions &&
          videoAsset.interactiveRegions.length > 0
        ) {
          return videoAsset;
        }
      }
      return undefined;
    }
  }
  /** when a virtual asset item is substituted or modified its new contentId is synced with its interactive action (to be reevaluated in the future) */
  reLinkActionToTarget(
    oldContentId: Maybe<string>,
    newContentId: Maybe<string>
  ) {
    const interactivePage = this.getInteractivePageBgItem();
    const touchRegions = interactivePage?.interactiveRegions;
    touchRegions?.forEach((region) => {
      if (region?.action?.target && region?.action?.target === oldContentId)
        [(region.action.target = newContentId)];
    });
  }
  setAssetItemMedia(assetItem: AssetItem, media: Media) {
    assetItem.name = media.name;
    assetItem.contentId = media.id;
    if (media.type === ResourceType.Video) {
      if (media.metadata?.duration)
        (assetItem as VideoAsset).duration = media.metadata.duration * 1000;
      (assetItem as VideoAsset).media = media;
      (assetItem as VideoAsset).uri = media.secureUrl;
    } else {
      (assetItem as ImageAsset).media = media;
      (assetItem as ImageAsset).uri = media.secureUrl;
    }
  }

  editInteractiveLayout(oldContent: AssetItem) {
    const imageOrVideoItem = AssetItemToImageOrVideo(oldContent);
    if (!imageOrVideoItem) return;

    const modalRef = this.modalService.open(
      InteractiveLayoutManageDialogComponent,
      {
        backdrop: 'static',
        windowClass: 'cesdk-modal',
        keyboard: false,
      }
    );

    modalRef.componentInstance.source =
      imageOrVideoItem.interactiveLayoutSource || '';
    modalRef.componentInstance.interactiveRegions =
      imageOrVideoItem.interactiveRegions || [];
    modalRef.componentInstance.height = imageOrVideoItem.height;
    modalRef.componentInstance.width = imageOrVideoItem.width;

    if (AssetItemIsImage(imageOrVideoItem)) {
      modalRef.componentInstance.backgroundUrl = imageOrVideoItem.uri;
    } else if (AssetItemIsVideo(imageOrVideoItem)) {
      modalRef.componentInstance.backgroundUrl = `${imageOrVideoItem.uri?.substring(
        0,
        imageOrVideoItem.uri.lastIndexOf('.')
      )}.png`;
    }

    modalRef.result.then(
      async (response?: IInteractiveLayoutModalResponse) => {
        if (response) {
          if (imageOrVideoItem) {
            imageOrVideoItem.interactiveLayoutSource = response.source;
            imageOrVideoItem.interactiveRegions = response.interactiveRegions;
            imageOrVideoItem.width = response.width;
            imageOrVideoItem.height = response.height;

            await this.mapRegionsToTargetAssetItems(
              imageOrVideoItem.interactiveRegions
            );
          }

          this.emitSelectedAssets();
        }
      },
      () => {}
    );
  }
  /** parse interactive regions, add target items to this sequence, remove target items not mapped in regions */
  async mapRegionsToTargetAssetItems(
    interactiveRegions: Maybe<import('@designage/gql').SmilRegion>[]
  ) {
    if (this.asset().content) {
      // remove target videos no longer referenced by touch regions
      for (let i = this.asset().content.length - 1; i >= 0; i--) {
        const assetItem = this.asset().content[i];

        if (AssetItemIsInteractiveTargetVideo(assetItem)) {
          const launchingTouchRegion = interactiveRegions.find(
            (region) =>
              region?.action?.id === assetItem.id &&
              region?.action?.target === assetItem.contentId
          );
          if (!launchingTouchRegion) {
            // touch region has been removed, or target video/ action has been changed
            // let's remove it!
            this.removeContent(assetItem, false);
          }
        }
      }

      // add target videos not yet present

      for (const region of interactiveRegions) {
        if (region?.action?.id && region.action.target) {
          const itemIdx = this.asset().content.findIndex(
            (item) =>
              AssetItemIsInteractiveTargetVideo(item) &&
              item.id === region?.action?.id
          );
          if (itemIdx < 0) {
            // add virtual item
            const videoId = region.action.target;
            const video = await this.mediaService.getMediaById(videoId);
            if (!video) continue;

            const assetItem = this.MediaToVideoAsset(video);
            assetItem.id = region.action.id;

            assetItem.interactiveLayoutSource = 'target';
            await this.playlistEditorService.addContent(
              assetItem,
              this.asset().id
            );
          }
        }
      }
    }
  }
  MediaToVideoAsset(video: MediaDetailedFragment) {
    const { id, name, publicId } = video;
    const d = video?.metadata?.duration || 0;
    const duration = Math.round(d * 1000);

    const videoAsset: VideoAsset = {
      name,
      contentId: id,
      duration,
      type: AssetType.Video,
      __typename: 'VideoAsset',
      uri: video.secureUrl,
      publicId,
    };
    return videoAsset;
  }

  editIFrameAsset(oldContent: AssetItem, index: number) {
    const editingItem = oldContent as IFrameAsset;

    const modalRef = this.modalService.open(IframeEditorComponent, {
      windowClass: 'cesdk-modal',
      // size: 'xl',
      backdrop: 'static',
    });
    // TODO add playlist width and height
    modalRef.componentInstance.iframeUrl = editingItem.uri;
    modalRef.componentInstance.assetTitle = editingItem.name;
    modalRef.componentInstance.assetId;
    modalRef.componentInstance.assetItemId;

    modalRef.componentInstance.htmlObject.subscribe(
      (iframeContent: IFrameAsset) => {
        console.log(iframeContent);
        this.doReplaceContent(index, iframeContent, oldContent);
      }
    );

    modalRef.result.then(() => {
      // refresh media-gallery
      this.mediaService.refetchQueries();
    });
  }
}
