import { FormGroup } from '@angular/forms';
import moment from 'moment';

export class DateValidator {
  static dateRange(date: string, secondDate: string) {
    return (formGroup: FormGroup) => {
      const startDatePristine = formGroup.controls[date].pristine;
      const endDatePristine = formGroup.controls[secondDate].pristine;

      const startDate = formGroup.controls[date].value
        ? moment(formGroup.controls[date].value)
        : null;
      const endDate = formGroup.controls[secondDate].value
        ? moment(formGroup.controls[secondDate].value)
        : null;
      const today = moment().format('YYYY-MM-DD');

      if (
        endDate?.isBefore(today) ||
        (!startDatePristine && endDate?.isBefore(today)) ||
        (!startDatePristine && startDate && endDate?.isBefore(startDate)) ||
        (!endDatePristine && endDate && startDate?.isAfter(endDate))
      ) {
        return { dateRange: true };
      }
      return null;
    };
  }
}
