import { IFullScreen } from '@desquare/interfaces';

// element can be any for vendor-prefix fullscreen function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestFullScreen = (element: any) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  }
};

const exitFullScreen = () => {
  const fullScreenDocument: IFullScreen = document;
  if (fullScreenDocument.exitFullscreen) {
    if (fullScreenDocument.fullscreenElement) {
      fullScreenDocument.exitFullscreen();
    }
  } else if (fullScreenDocument.msExitFullscreen) {
    fullScreenDocument.msExitFullscreen();
  } else if (fullScreenDocument.mozCancelFullScreen) {
    fullScreenDocument.mozCancelFullScreen();
  } else if (fullScreenDocument.webkitExitFullscreen) {
    fullScreenDocument.webkitExitFullscreen();
  }
};

export const screenUtil = {
  requestFullScreen,
  exitFullScreen,
};
