import { RGBA } from '@cesdk/cesdk-js';
import { ILayoutEditorCanvas, IRegionAction } from '@desquare/interfaces';
import { round } from 'lodash';

export interface IUpdateCanvas {
  width?: number;
  height?: number;
  color?: RGBA;
}

export interface IRegionBlock {
  id: number;
  name: string;
  width: number;
  height: number;
  top: number;
  left: number;
  // color: RGBA;
  action?: IRegionAction;
}

export interface IUpdateBlockInput extends IUpdateSelectedBlockInput {
  id: number;
}

/**
 * Refactor this later, this is basically the ISmilRegion interface
 * without the id
 */
export interface IUpdateSelectedBlockInput {
  regionName?: string;
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  zPositionMovement?: ZPositionMovement;
  action?: IRegionAction;
}

export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export const DEFAULT_CANVAS_CONFIG: ILayoutEditorCanvas = {
  width: 1920,
  height: 1080,
  color: '#000000', // black
};

/**
 * Imagine this as a deck of cards in a table where the z-axis points toward where the
 * card is facing up, when moving cards in a deck you have 4 basic movements, moving the
 * card to the TOP of the deck (to front), to the BOTTOM of the deck (to back) and
 * moving the card DOWN below one card (backward), UP above one card (forward).
 */
export enum ZPositionMovement {
  top = 'TOP',
  up = 'UP',
  down = 'DOWN',
  bottom = 'BOTTOM',
}

/**
 * This converts cesdk's RGBA type to Hexadecimal format as a string
 * - the rgba of cesdk have their r g b values from 0 to 1 instead of
 * 0 to 255
 *
 * @param color
 * @returns
 */
export function cesdkRgbaToHex(color: RGBA): string {
  const [r, g, b, a] = color;
  return (
    '#' +
    round(r * 255).toString(16) +
    round(g * 255).toString(16) +
    round(b * 255).toString(16)
  );
}

/**
 * This converts a color in Hexadecimal format to cesdk's RGBA type
 * - the rgba of cesdk have their r g b values from 0 to 1 instead of
 * 0 to 255
 *
 * @param color
 * @returns
 */
export function hexToCesdkRGBA(color: string): RGBA {
  return [
    parseInt(color.slice(1, 3), 16) / 255,
    parseInt(color.slice(3, 5), 16) / 255,
    parseInt(color.slice(5, 7), 16) / 255,
    1, // alpha value is locked to 1 since we currently don't allow transparent background color
  ] as RGBA;
}

/**
 * - Converts HSV/HSB (Hue Saturation Value/Brightness) to RGB (Red Green Blue)
 * - Source: https://stackoverflow.com/a/54024653
 *
 * @param h input range 0 to 360
 * @param s input range 0 to 1
 * @param v input range 0 to 1
 * @returns
 */
export function hsvToRgb(h: number, s: number, v: number) {
  let f = (n: number, k = (n + h / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return { r: f(5), g: f(3), b: f(1) };
}
