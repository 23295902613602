<div class="modal-container">
  <div class="modal-body">
    <span [ngSwitch]="assetItem.__typename">
      <span *ngSwitchCase="'ImageAsset'">
        <ng-container *ngTemplateOutlet="imageAsset"></ng-container>
      </span>
      <span *ngSwitchCase="'VideoAsset'">
        <ng-container *ngTemplateOutlet="videoAsset"></ng-container>
      </span>
      <span *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="unknown"></ng-container>
      </span>
    </span>
  </div>
</div>

<ng-template #imageAsset>
  <img class="modal-content" [src]="imageUri" />
</ng-template>

<ng-template #videoAsset>
  <video
    class="modal-content"
    [src]="videoUri"
    controls="false"
    autoplay
  ></video>
</ng-template>

<ng-template #unknown>
  <h5 class="text-center text-warning">
    {{ 'TYPE_IS_UNSUPPORTED' | translate }}
  </h5>
</ng-template>
