import { Component, Input } from '@angular/core';
import {
  ChannelsForChannelListFragment,
  Device,
  Maybe,
  Playlist,
} from '@designage/gql';
import { DeviceDataService } from '@desquare/services';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistManageDialogComponent } from '@desquare/components/common/src/modals/playlist-manage-dialog.component';
import { Router } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutExplorerComponent } from '../layout-explorer/layout-explorer.component';

@Component({
  standalone: true,
  imports: [NgIf, TranslateModule, NgFor, LayoutExplorerComponent, NgbTooltip],
  selector: 'app-device-connected-links',
  templateUrl: './device-connected-links.component.html',
  styleUrls: ['./device-connected-links.component.scss'],
})
export class DeviceConnectedLinksComponent {
  @Input('links') links!: {
    loading: boolean;
    playlists: Playlist[];
    channel: Maybe<ChannelsForChannelListFragment>;
  };
  @Input() deviceId?: Device;
  @Input() channelId!: string;
  @Input() connection!: 'channel' | 'playlists';

  constructor(
    private deviceDataService: DeviceDataService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  openPlaylistInModal(playlist: Playlist) {
    const modal = this.modalService.open(PlaylistManageDialogComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });

    modal.componentInstance.playlistId =
      playlist.parentPlaylist?.id || playlist.id;

    modal.result.then(
      () => {},
      () => {}
    );
  }

  navigateToChannel(channelId: string) {
    if (channelId) {
      this.deviceDataService.currentUserService.navigateToChannel(channelId);
    }
  }

  getRegion(playlistId: string) {
    return this.links.channel?.playlistRegions?.find(
      (x) => x.playlistId === playlistId
    )?.region;
  }

  navigateToPlaylist(id: string) {
    this.deviceDataService.currentUserService.navigateToPlaylist(id);
  }
  navigateToPlaylistList() {
    this.router.navigate(['/profile/playlists']);
  }
}
