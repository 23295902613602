import { Injectable, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  constructor() {}

  get isIPhoneSize() {
    return window.innerWidth <= 375;
  }

  get isIPhone6Size() {
    return window.innerWidth > 375 && window.innerWidth <= 414;
  }

  get isIPadSize() {
    return window.innerWidth > 414 && window.innerWidth <= 768;
  }

  get isIPadProSize() {
    return window.innerWidth > 768 && window.innerWidth <= 1024;
  }

  get isWindowMobileLarge() {
    return window.innerWidth > 600 && window.innerWidth <= 1024;
  }

  get isWindowMobileSmall() {
    return window.innerWidth <= 600;
  }

  get isSidebarToggleEnabled() {
    return window.innerWidth <= 991;
  }

  get isSearchBarEnabled() {
    return window.innerWidth >= 812;
  }

  get windowWidth() {
    return window.innerWidth;
  }

  get windowHeight() {
    return window.innerHeight;
  }

  scrollToTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
