<div
  *ngIf="asInputField; else popover"
  ngbDropdown
  container="body"
  autoClose="outside"
  (openChange)="onTimeChange($event)"
>
  <span ngbDropdownToggle class="form-control form-control-dark time-display">{{
    getDisplayTime()
  }}</span>
  <div
    ngbDropdownMenu
    class="form-control form-control-dark shadowed-box time-picker"
  >
    <span ngbDropdownItem class="time-picker">
      <ngb-timepicker
        [(ngModel)]="pickerTime"
        [seconds]="showSecond"
        [hourStep]="hourStep"
        [minuteStep]="minuteStep"
        [secondStep]="secondStep"
        size="small"
      ></ngb-timepicker
    ></span>
  </div>
</div>

<ng-template #popover>
  <div
    ngbDropdown
    container="body"
    autoClose="outside"
    placement="bottom"
    (openChange)="onTimeChange($event)"
  >
    <span class="icon" ngbDropdownToggle>
      <i class="ri-pencil-fill text-primary hover-fade-target"></i>
    </span>
    <div
      ngbDropdownMenu
      class="form-control form-control-dark shadowed-box time-picker"
    >
      <span ngbDropdownItem class="time-picker">
        <ngb-timepicker
          [(ngModel)]="pickerTime"
          [seconds]="showSecond"
          [hourStep]="hourStep"
          [minuteStep]="minuteStep"
          [secondStep]="secondStep"
          size="small"
        ></ngb-timepicker
      ></span>
    </div>
  </div>
</ng-template>
