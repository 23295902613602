import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {
  PlaylistStatus,
  GetPlaylistChildQuery,
  GetPlaylistChildGQL,
  GetPlaylistDetailedGQL,
} from '@designage/gql';
import { ExcludeMaybe } from '@desquare/types';
import { ToasterService } from '@desquare/services';
import { Observable, lastValueFrom, map, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AssetRowComponent } from '@designage/app/playlist-sequence/asset-row/asset-row.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbDropdownModule,
    AssetRowComponent,
  ],
  selector: 'app-playlist-version',
  templateUrl: './playlist-version.component.html',
  styleUrls: ['./playlist-version.component.scss'],
})
export class PlaylistVersionComponent implements OnInit {
  @Input() playlistId!: string;
  @Input() activeChannels!: string[];
  @Output() activeChannelsChange = new EventEmitter<string[]>();
  @Output() revertButtonClick = new EventEmitter<string>();
  @Output() createCopyButtonClick = new EventEmitter<string>();

  playlistStatus = PlaylistStatus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedVersion!: any;
  versions: ExcludeMaybe<GetPlaylistChildQuery['playlist']>['childPlaylists'] =
    [];
  versions$: Observable<
    ExcludeMaybe<GetPlaylistChildQuery['playlist']>['childPlaylists']
  > = new Observable();
  loading = true;

  constructor(
    private toasterService: ToasterService,
    private getPlaylistChild: GetPlaylistChildGQL,
    private getPlaylistDetailedGql: GetPlaylistDetailedGQL
  ) {}

  ngOnInit(): void {
    this.versions$ = this.getPlaylistChild
      .watch({ id: this.playlistId }, { fetchPolicy: 'cache-first' })
      .valueChanges.pipe(
        map(({ data, loading, error }) => {
          this.loading = loading;
          if (data.playlist?.childPlaylists?.length) {
            const childPlaylists = [...data.playlist?.childPlaylists];
            childPlaylists.sort((a, b) => {
              return a?.createdAt && b?.createdAt && b.createdAt > a.createdAt
                ? 1
                : -1;
            });
            if (
              this.selectedVersion === undefined &&
              childPlaylists &&
              childPlaylists.length > 1
            )
              this.setSelectedVersion(childPlaylists[1]!.id);
            return childPlaylists;
          }
          if (error) {
            error.graphQLErrors.forEach((gqlError) => {
              this.toasterService.handleGqlError(gqlError);
            });
          }
        }),
        tap((data) => {
          this.versions = data;
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setSelectedVersion(id: string) {
    const {
      data: { playlist },
    } = await lastValueFrom(this.getPlaylistDetailedGql.fetch({ id }));
    if (playlist) {
      this.selectedVersion = playlist;
    }
  }

  revert() {
    this.revertButtonClick.emit(this.selectedVersion?.id);
  }

  createCopy() {
    this.createCopyButtonClick.emit(this.selectedVersion?.id);
  }

  getChildPlaylists() {
    if (!this.playlistId) {
      return;
    }

    // this.versions = [];
    // this.subs.sink = this.getPlaylistChild
    //  .watch({ id: this.playlistId }, { fetchPolicy: 'network-only' })
    //  .valueChanges.subscribe({
    //    next: ({ data, loading }) => {
    //      if (data.playlist?.childPlaylists?.length) {
    //        this.versions = data.playlist?.childPlaylists.sort((a, b) => {
    //          return a?.createdAt && b?.createdAt && b.createdAt > a.createdAt
    //            ? 1
    //            : -1;
    //        });
    //        this.selectedVersion = this.versions[0];
    //        this.activeChannels = this.getActiveChannels();
    //        this.activeChannelsChange.emit(this.activeChannels);
    //        this.loading = loading;
    //      } else {
    //        this.versions = [];
    //      }
    //    },
    //    error: (error: ApolloError) => {
    //      error.graphQLErrors.forEach((gqlError) => {
    //        console.error('getChildPlaylists', gqlError);
    //        this.toasterService.handleGqlError(gqlError);
    //      });
    //    },
    //  });
  }

  getActiveChannels() {
    let activeChannels: string[] = [];
    if (this.versions?.length) {
      for (const playlist of this.versions) {
        if (playlist?.status === PlaylistStatus.Active) {
          activeChannels = [...new Set(playlist.channels.map((x) => x.name))];
          return activeChannels;
        }
      }
    }
    return activeChannels;
  }
}
