import { Injectable } from '@angular/core';
import { gsap } from 'gsap';
import { TransitionEffects } from '@desquare/enums';
@Injectable({
  providedIn: 'root',
})
export class TransitionService {
  constructor() {}

  async fade(
    elementIdToDisplay: string,
    elementIdToHide: string,
    fadeDuration: number
  ) {
    const timeline = gsap.timeline();
    timeline
      .set(elementIdToDisplay, { autoAlpha: 0 })
      .set(elementIdToDisplay, { css: { zIndex: 1 } })
      .set(elementIdToHide, { css: { zIndex: 0 } })
      .to(elementIdToDisplay, { autoAlpha: 1, duration: fadeDuration })
      .set(elementIdToHide, { autoAlpha: 0 });
  }

  async cut(elementIdToDisplay: string, elementIdToHide: string) {
    const timeline = gsap.timeline();
    timeline
      .set(elementIdToDisplay, { autoAlpha: 1 })
      .set(elementIdToHide, { autoAlpha: 0 });
  }

  hideElements(elementIds: string[]) {
    // console.log('Hiding elements', elementIds); // DEBUG

    gsap.set(elementIds, { autoAlpha: 0 });
  }

  getTransitions() {
    return [TransitionEffects.CUT, TransitionEffects.FADE];
  }
}
