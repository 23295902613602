<div class="p-1">
  <ng-container *ngIf="!links.loading">
    <div class="card" *ngIf="connection === 'playlists'">
      <h4 class="card-header text-center">
        {{ 'CONNECTED_PLAYLISTS' | translate }}
      </h4>
      <div class="card-body">
        <table class="table table-sm align-middle mt-2">
          <thead>
            <tr>
              <th scope="col">
                {{ 'REGION' | translate }}
              </th>
              <th scope="col" class="text-center">
                {{ 'PLAYLIST' | translate }}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let playlist of links.playlists; let i = index"
              scope="row"
            >
              <td>
                <app-layout-explorer
                  [layout]="links.channel.layout"
                  [boundingBox]="'40px'"
                  [selected]="getRegion(playlist.id)"
                  [canSelect]="false"
                  [showText]="false"
                ></app-layout-explorer>
              </td>
              <td>
                <div class="text-center text-nowrap">
                  {{ playlist.name }}
                </div>
              </td>
              <td>
                <button
                  class="ms-4 btn btn-sm btn-outline-primary"
                  (click)="openPlaylistInModal(playlist)"
                  [ngbTooltip]="'CONNECTED_PLAYLIST_OPEN_HERE' | translate"
                >
                  {{ 'EDIT' | translate }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div class="mt-3 d-block">
          <button
            class="w-100 btn btn-outline-primary text-nowrap"
            (click)="navigateToPlaylistList()"
          >
            <i class="ri-play-list-line"></i>
            <span class="ms-2">
              {{ 'CONNECTED_PLAYLIST_GOTO' | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="connection === 'channel'">
      <h4 class="card-header text-center">
        {{ 'CONNECTED_CHANNEL' | translate }}
      </h4>
      <div class="card-body">
        <p class="text-center">{{ 'CONNECTED_CHANNEL_TT' | translate }}</p>
        <div
          class="card-text w-100 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="w-100 d-flex align-items-center justify-content-center">
            <table class="table table-sm align-middle mt-2">
              <thead>
                <tr>
                  <th scope="col">
                    {{ 'LAYOUT' | translate }}
                  </th>
                  <th scope="col" class="text-center">
                    {{ 'CHANNEL' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr scope="row">
                  <td>
                    <app-layout-explorer
                      class="flex-grow-1"
                      [layout]="links.channel.layout"
                      [boundingBox]="'40px'"
                      [canSelect]="false"
                      [showText]="false"
                    ></app-layout-explorer>
                  </td>
                  <td>
                    <button
                      class="w-100 ms-2 btn btn-sm btn-outline-primary text-nowrap"
                      (click)="navigateToChannel(links.channel?.id)"
                      [ngbTooltip]="'CONNECTED_CHANNEL_GOTO' | translate"
                    >
                      {{ links.channel.name }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="links.loading"
    class="d-flex align-items-center justify-content-center"
  >
    <div class="spinner-border spinner-border-sm" role="status"></div>
  </div>

  <div
    *ngIf="!links.loading && links.playlists.length === 0"
    class="d-flex align-items-center justify-content-center"
  >
    <i class="ri-error-warning-line"></i>
    <div class="p-2">
      {{ 'CONNECTED_PLAYLISTS_NOT_FOUND' | translate }}
    </div>
  </div>
</div>
