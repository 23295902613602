/* eslint-disable @typescript-eslint/no-explicit-any */
// source: https://stackoverflow.com/a/43245004

export const timer = (callback: any, duration: number) => {
  let timerId!: number;
  let startTime!: number;
  let remainingTime = duration;

  function pause() {
    clearTimer();
    if (startTime) {
      remainingTime -= Date.now() - startTime;
    }
  }

  function play() {
    startTime = Date.now();
    clearTimer();
    timerId = window.setTimeout(callback, remainingTime);
  }

  function clearTimer() {
    if (timerId) {
      clearTimeout(timerId);
    }
  }
  play();

  return {
    pause,
    play,
    clearTimer,
  };
};
