import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { AssetType, IFrameAsset } from '@designage/gql';
import { PlaylistEditorService } from '@desquare/services';
import { v4 as uuidV4 } from 'uuid';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSplitModule } from 'angular-split';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, AngularSplitModule],
  selector: 'app-iframe-editor',
  templateUrl: './iframe-editor.component.html',
  styleUrls: ['./iframe-editor.component.scss'],
})
export class IframeEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('iframeContainer', { static: true }) iframeContainer!: ElementRef;
  @ViewChild('iframe', { static: false }) iframe!: ElementRef;
  @ViewChild('form') ngForm!: NgForm;
  @ViewChild('onCancelModal') onCancelModal!: ElementRef;
  @ViewChild('previewImage') previewImage!: ElementRef;
  @Input() assetId!: string;
  @Input() assetItemId!: string;
  @Input() assetTitle = 'External web content';
  @Input() iframeUrl = '';
  @Output() htmlObject: EventEmitter<IFrameAsset> = new EventEmitter();
  capturedImage!: string;

  model = {
    assetTitle: '',
    iframeUrl: '',
    iframeWidth: 1920,
    iframeHeight: 1080,
  };

  containerWidth!: string;
  containerHeight!: string;
  iframeScaleFactor!: number;
  iframeOrigin!: string;

  constructor(
    private playlistEditorService: PlaylistEditorService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.model.assetTitle = this.assetTitle;
    this.model.iframeUrl = this.iframeUrl;
  }

  ngAfterViewInit(): void {
    this.setIframeScaleFactor();
  }

  toggleEdit() {
    const content = this.playlistEditorService.getContentById(
      this.assetItemId,
      this.assetId
    );
    if (content?.__typename === 'IFrameAsset') {
      content.uri = this.model.iframeUrl;
      this.playlistEditorService.updateContentById(
        this.assetItemId,
        content,
        this.assetId
      );
      this.playlistEditorService.sequenceTouch.emit(true);
      this.playlistEditorService.emitEditingSequencesChange();
    }
  }

  setIframeScaleFactor() {
    this.containerWidth = this.iframeContainer.nativeElement.offsetWidth;
    this.containerHeight = this.iframeContainer.nativeElement.offsetHeight;
    const scaleX = +this.containerWidth / this.model.iframeWidth;
    const scaleY = +this.containerHeight / this.model.iframeHeight;
    this.iframeScaleFactor = scaleX <= scaleY ? scaleX : scaleY;
    this.iframeOrigin =
      this.model.iframeWidth < this.model.iframeHeight ? 'top' : 'left top';
    this.renderer.setStyle(
      this.iframeContainer.nativeElement,
      'transform',
      `scale(${this.iframeScaleFactor})`
    );
    this.renderer.setStyle(
      this.iframeContainer.nativeElement,
      'transform-origin',
      this.iframeOrigin
    );
  }

  iframeContainerScale() {
    const transform = {
      'transform-origin': this.iframeOrigin,
      transform: `scale(${this.iframeScaleFactor})`,
    };
    console.log(transform);
    return transform;
  }

  setIframeSize() {
    const styles = {
      width: `${this.model.iframeWidth}px`,
      height: `${this.model.iframeHeight}px`,
    };
    return styles;
  }

  reloadIframe(url: string) {
    console.log('newUrl', url);
    this.model.iframeUrl = url;
  }

  onSave() {
    const iframeContent: IFrameAsset = {
      __typename: 'IFrameAsset',
      uri: this.model.iframeUrl,
      name: this.model.assetTitle,
      type: AssetType.Iframe,
      contentId: uuidV4(),
    };
    this.htmlObject.emit(iframeContent);
    this.destroyEditor();
  }

  cancelPopup() {
    this.modalService.open(this.onCancelModal, {
      size: 'lg',
    });
  }

  /** completely close editor without updating */
  destroyEditor() {
    this.activeModal.close();
  }
}
