import { DeviceInfo, NetworkInterface } from '@designage/gql';
import _ from 'lodash';

export const getNetworkAdaptersUtil = (
  deviceInfo: DeviceInfo
): { activeAdapters: NetworkInterface[]; activeAdapter?: NetworkInterface } => {
  // Parse for network Interfaces
  // If SMIL 2.2.7 or later
  const activeAdapters = deviceInfo.networkAdapters as NetworkInterface[];

  // Is Wifi active?
  const wifiActive = activeAdapters.find(
    (el) => el && !_.isEmpty(el?.wifiSsid) && !_.isEmpty(el?.localAddress)
  );

  // If Wifi is active, put it first
  if (wifiActive) {
    const wifiIndex = activeAdapters.indexOf(wifiActive);
    activeAdapters.unshift(...activeAdapters.splice(wifiIndex, 1));
  } else {
    // If no wifi, put Ethernet first
    const wifiInactive = activeAdapters.find((el) => el && el?.type === 'wifi');
    if (wifiInactive) {
      const wifiIndex = activeAdapters.indexOf(wifiInactive);
      activeAdapters.push(...activeAdapters.splice(wifiIndex, 1));
    }
  }

  if (activeAdapters.length > 0) {
    const activeAdapter = activeAdapters[0];
    return { activeAdapters, activeAdapter: activeAdapter };
  }
  return { activeAdapters };
};

export const getNetworkInfoUtil = (deviceInfo: DeviceInfo) => {};
