import {
  AssetItem,
  AssetType,
  ImageAsset,
  Maybe,
  VideoAsset,
} from '@designage/gql';

export function AssetItemIsImage(assetItem: Maybe<AssetItem>) {
  return (
    assetItem?.__typename === 'ImageAsset' ||
    assetItem?.type === AssetType.Image
  );
}

export function AssetItemIsVideo(assetItem: Maybe<AssetItem>) {
  return (
    assetItem?.__typename === 'VideoAsset' ||
    assetItem?.type === AssetType.Video
  );
}

export function AssetItemIsImageOrVideo(assetItem: Maybe<AssetItem>) {
  return AssetItemIsImage(assetItem) || AssetItemIsVideo(assetItem);
}

export function AssetItemToImageOrVideo(assetItem: Maybe<AssetItem>) {
  const image = AssetItemIsImage(assetItem)
    ? (assetItem as ImageAsset)
    : undefined;
  const video = AssetItemIsVideo(assetItem)
    ? (assetItem as VideoAsset)
    : undefined;
  return image || video;
}

export function AssetItemIsHtml(assetItem: Maybe<AssetItem>) {
  return (
    assetItem?.__typename === 'HtmlAsset' || assetItem?.type === AssetType.Html
  );
}

export function AssetItemIsIFrame(assetItem: Maybe<AssetItem>) {
  return (
    assetItem?.__typename === 'IFrameAsset' ||
    assetItem?.type === AssetType.Iframe
  );
}

export function AssetItemIsInteractiveTargetVideo(assetItem: Maybe<AssetItem>) {
  const video = AssetItemToVideo(assetItem);
  return video ? video.interactiveLayoutSource === 'target' : false;
}

export function AssetItemToVideo(assetItem: Maybe<AssetItem>) {
  if (AssetItemIsVideo(assetItem)) {
    return assetItem as VideoAsset;
  }
  return undefined;
}
