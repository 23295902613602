<div class="d-flex flex-column h-100 overflow-auto">
  <!-- Simulate date and time -->
  @if(showSimulateSchedule){
  <div class="d-flex align-items-center mt-2 p-2">
    <input
      type="checkbox"
      class="checkbox-date me-1"
      (change)="toggleSimulatePreview($event)"
    />
    <label class="form-check-label mt-2 mb-2 me-2"
      >{{ 'SIMULATE_PREVIEW_LABEL' | translate }}:</label
    >
    <designage-datepicker
      [disabled]="!enableSimulatePicker"
      [(date)]="simulateDate"
      (dateChange)="onSimulateDateTimeChange()"
    >
    </designage-datepicker>
    @if(enableSimulatePicker){
    <designage-timepicker
      [(time)]="simulateTime"
      [showSecond]="false"
      (timeChange)="onSimulateDateTimeChange()"
    ></designage-timepicker>
    }
  </div>
  }
  <hr />

  <!-- Width and height -->
  <div class="d-flex align-items-center p-1 pb-2 position-realtive">
    <div class="d-flex justify-content-evenly flex-fill">
      <div>
        <span> {{ 'WIDTH' | translate }}: {{ zoneResolution.width }}px </span>
      </div>
      <div>
        <span> {{ 'HEIGHT' | translate }}: {{ zoneResolution.height }}px </span>
      </div>
    </div>
    <div
      class="position-absolute end-0 p-1 me-3 text-white rounded-circle cursor-pointer"
      (click)="restartPreview()"
      ngbTooltip="{{ 'PREVIEW_RESET' | translate }}"
      placement="start"
    >
      <i class="ri-arrow-go-back-line"></i>
    </div>
  </div>
  @if(!contents?.length){
  <h5 class="content-text">
    {{ 'NO_ADDED_ITEM_TO_PREVIEW' | translate }}
  </h5>
  } @if(contents?.length && !noScheduledContent && (showImage || currentVideoUri
  || currentHtmlContent)){
  <div
    #previewContainer
    class="preview-container"
    [class.new-window]="isEmulator || isTheDetachedPreview"
    [class.preview]="!isEmulator && !isTheDetachedPreview"
    [class.relative-position]="showAspectRatio"
  >
    <div
      tabindex="-1"
      class="preview-window border border-dark"
      [ngStyle]="{
        'padding-bottom':
          zoneResolution.orientation === 'landscape' && !layoutExplorerVisible
            ? zoneResolution.aspect + '%'
            : 'unset',
        bottom:
          zoneResolution.orientation === 'portrait' ||
          isFullscreen ||
          layoutExplorerVisible
            ? '0'
            : 'unset'
      }"
    >
      <img
        id="image1"
        #image1
        (click)="togglePlay(!isPlaying)"
        class="content"
        [class.show]="showImage"
        [class.detached-view]="isTheDetachedPreview"
      />
      <img
        id="image2"
        #image2
        (click)="togglePlay(!isPlaying)"
        class="content"
        [class.show]="showImage"
        [class.detached-view]="isTheDetachedPreview"
      />
      @if(currentVideoUri){
      <video
        [muted]="isMuted"
        #clVideo
        id="video"
        (click)="togglePlay(!isPlaying)"
        (ended)="onVideoEnded($event)"
        class="content"
        [class.show]="showImage"
        [src]="currentVideoUri"
        autoplay="true"
        [class.detached-view]="isTheDetachedPreview"
      ></video>
      } @if(currentHtmlContent){
      <iframe
        class="content"
        scrolling="yes"
        [src]="currentHtmlContent | safe : 'resourceUrl'"
      ></iframe>
      } @if(showControls){
      <app-preview-controls
        [ngStyle]="isFullscreen ? { bottom: '10px' } : {}"
        class="preview-controls"
        (mute)="isMuted = $event"
        (fullScreen)="toggleFullScreen($event)"
        (detach)="toggleDetach($event)"
        (playback)="togglePlay($event)"
        [isTheDetachedPreview]="isTheDetachedPreview"
        [isWindowDetached]="isWindowDetached"
        [isPlaying]="isPlaying"
      ></app-preview-controls>
      }
    </div>
  </div>
  } @else { @if(noScheduledContent){
  <div
    class="preview-window border border-dark h-100 d-flex align-items-center"
    [ngStyle]="{
      'padding-bottom':
        zoneResolution.orientation === 'landscape' && !layoutExplorerVisible
          ? zoneResolution.aspect + '%'
          : 'unset',
      bottom:
        zoneResolution.orientation === 'portrait' ||
        isFullscreen ||
        layoutExplorerVisible
          ? '0'
          : 'unset'
    }"
  >
    <h2 class="text-center px-3">
      {{ 'NO_ITEM_PLAYLIST_TO_PLAY' | translate }}
    </h2>
  </div>
  }@else{
  <div
    class="preview-window border border-dark h-100 d-flex align-items-center"
    [ngStyle]="{
      'padding-bottom':
        zoneResolution.orientation === 'landscape' && !layoutExplorerVisible
          ? zoneResolution.aspect + '%'
          : 'unset',
      bottom:
        zoneResolution.orientation === 'portrait' ||
        isFullscreen ||
        layoutExplorerVisible
          ? '0'
          : 'unset'
    }"
  >
    <app-loader class="flex-grow-1" [showMessage]="'Preparing media'" />
  </div>
  } }

  <!-- Preview settings -->
  <ng-container>
    <div class="d-flex flex-column align-items-center">
      @if(previewResolutionSource === 'SRC_ASSIGNED' && selectedLayout){
      <div class="d-flex">
        <span class="mx-2 text-truncate">
          {{ 'CHANNEL' | translate }}: {{ selectedPreviewChannel?.name }}
        </span>
        <span class="mx-2 text-truncate">
          {{ 'REGION' | translate }}:
          {{
            selectedLayoutRegion === ''
              ? ('REGION_CANVAS_ROOT' | translate)
              : selectedLayoutRegion
          }}
        </span>
      </div>
      }

      <!-- Preview Settings Drawer -->
      <div class="m-2 overflow-hidden d-flex flex-column w-100">
        <div
          class="row col-12 ms-0 preview-settings-drawer rounded-top"
          [style.margin-top]="getPreviewerDrawerSize()"
          style="transition: margin 0.5s ease"
        >
          <!-- Preview Settings panel -->
          <div class="pt-3 row col-12">
            <!-- Set resolution source -->
            <div class="mb-2 px-2 row align-items-center flex-nowrap">
              <div
                class="pe-1 text-truncate col-3 text-end"
                [ngbTooltip]="ttPreviewSourceContent"
                tooltipClass="wide-tooltip-class"
              >
                {{ 'PREVIEW_RESOLUTION_SOURCE' | translate }}:
              </div>
              <select
                class="form-control form-control-dark form-select col-9 flex-fill"
                (change)="changePreviewSource($event.target.value)"
              >
                @for(previewSource of previewSources; track previewSource){
                <option
                  value="{{ previewSource }}"
                  [selected]="previewSource === previewResolutionSource"
                >
                  {{ previewSource | translate }}
                </option>
                }
              </select>

              <ng-template #ttPreviewSourceContent>
                {{ 'PREVIEW_RESOLUTION_SOURCE_TT_L1' | translate }} <br /><br />
                {{ 'PREVIEW_RESOLUTION_SOURCE_TT_L2' | translate }} <br />
                {{ 'PREVIEW_RESOLUTION_SOURCE_TT_L3' | translate }} <br />
                {{ 'PREVIEW_RESOLUTION_SOURCE_TT_L4' | translate }} <br />
              </ng-template>
            </div>

            <!-- Assigned Channels Layout and region selector -->
            @if(previewResolutionSource === 'SRC_ASSIGNED'){
            <div class="px-2 mb-1 row align-items-center flex-nowrap">
              <div class="pe-1 text-truncate col-3 text-end">
                {{ 'PREVIEW_CHANNEL' | translate }}:
              </div>
              <select
                class="form-control form-control-dark form-select col-9 flex-fill"
                (change)="setSelectedChannel($event.target.value)"
              >
                @for(channel of selectedChannels; track channel.id){
                <option
                  [value]="channel.id"
                  [selected]="selectedPreviewChannel?.id === channel.id"
                >
                  {{ channel.name }}
                </option>
                }
              </select>
            </div>
            <!-- Info about assigned region -->
            <div class="pt-1 px-2 row align-items-center flex-nowrap">
              <div class="pe-1 text-truncate col-3 text-end">
                {{ 'PREVIEW_REGION' | translate }}:
              </div>
              <div class="px-3 col-9 flex-fill">
                {{
                  selectedLayoutRegion === ''
                    ? ('REGION_CANVAS_ROOT' | translate)
                    : selectedLayoutRegion
                }}
              </div>
            </div>
            }

            <!-- All Layouts region selector -->
            <!-- Select Layout -->
            @if(previewResolutionSource === 'SRC_ALL'){
            <div class="px-2 mb-1 row align-items-center flex-nowrap">
              <div class="pe-1 text-truncate col-3 text-end">
                {{ 'PREVIEW_LAYOUTS' | translate }}:
              </div>
              <select
                class="form-control form-control-dark form-select col-9 flex-fill"
                (change)="setSelectedLayout($event.target.value)"
              >
                @for(layout of layouts; track layout.id){
                <option
                  value="{{ layout.id }}"
                  [selected]="selectedLayout?.id === layout.id"
                >
                  {{ layout.name }}
                  <!-- {{ selectedLayout.id }} -->
                </option>
                }
              </select>
            </div>
            }
            <!-- Select region -->
            @if(previewResolutionSource === 'SRC_ALL' && !!selectedLayout){
            <div class="px-2 row align-items-center flex-nowrap">
              <div class="pe-1 text-truncate col-3 text-end">
                {{ 'PREVIEW_REGION' | translate }}:
              </div>
              <select
                class="form-control form-control-dark form-select col-9 flex-fill"
                (change)="setSelectedLayoutRegion($event.target.value)"
              >
                <option [value]="''" [selected]="selectedLayoutRegion === ''">
                  {{ 'REGION_CANVAS_ROOT' | translate }}
                </option>
                @for(region of selectedLayout.source.regionBlocks; track
                region.regionName){
                <option
                  [value]="region.regionName"
                  [selected]="selectedLayoutRegion === region.regionName"
                >
                  {{ region.regionName }}
                </option>
                }
              </select>
            </div>
            }

            <!-- Generic resolutions -->
            <!-- Select resolution -->
            @if(previewResolutionSource === 'SRC_GENERIC'){
            <div class="px-2 row align-items-center flex-nowrap mb-1">
              <div class="pe-1 text-truncate col-3 text-end">
                {{ 'PREVIEW_LAYOUT' | translate }}:
              </div>
              <select
                class="form-control form-control-dark form-select col-9 flex-fill"
                (change)="
                  selectedResolution = $event.target.value;
                  setPreviewResolution(
                    previewResolutionSource,
                    $event.target.value,
                    zoneResolution.orientation
                  )
                "
              >
                @for(resolution of resolutions; track resolution.resolution){
                <option
                  value="{{ resolution.resolution }}"
                  [selected]="
                    resolution.resolution === zoneResolution.resolution
                  "
                >
                  {{ resolution.resolution | translate }}
                </option>
                }
              </select>
            </div>
            }
            <!-- Set orientation -->
            @if(previewResolutionSource === 'SRC_GENERIC'){
            <div class="px-2 row align-items-center flex-nowrap mb-4">
              <div class="pe-1 text-truncate col-3 text-end">
                {{ 'SCREEN_ORIENTATION' | translate }}:
              </div>
              <select
                class="form-control form-control-dark form-select col-9 flex-fill"
                (change)="
                  setPreviewResolution(
                    previewResolutionSource,
                    selectedResolution,
                    $event.target.value
                  )
                "
              >
                <option
                  value="landscape"
                  [selected]="zoneResolution.orientation === 'landscape'"
                >
                  {{ 'ORIENTATION_LANDSCAPE' | translate }}
                </option>
                <option
                  value="portrait"
                  [selected]="zoneResolution.orientation === 'portrait'"
                >
                  {{ 'ORIENTATION_PORTRAIT' | translate }}
                </option>
              </select>
            </div>
            }
          </div>

          <!-- Layout Explorer -->
          <div class="p-2 d-flex">
            <!-- Assigned Channels -->
            @if(previewResolutionSource === 'SRC_ASSIGNED'){
            <div
              class="d-flex justify-content-center align-items-center flex-grow-1"
              [class.layout-selector-hidden]="!layoutExplorerVisible"
            >
              <!-- Select which region to get resolution from -->
              @if(previewResolutionSource === 'SRC_ASSIGNED' && selectedLayout){
              <app-layout-explorer
                class="px-2"
                [layout]="selectedLayout"
                [canSelect]="false"
                [selected]="selectedLayoutRegion"
                [boundingBox]="layoutExplorerSize + 'px'"
                [showText]="true"
              ></app-layout-explorer>
              }@else {
              <div
                [style.height.px]="layoutExplorerSize"
                class="m-2 d-flex align-items-center justify-content-center"
              >
                <h5>{{ 'NO_LAYOUT_ASSIGNED_TO_CHANNEL' | translate }}</h5>
              </div>
              }
            </div>
            }

            <!-- All Layouts -->
            @if(previewResolutionSource === 'SRC_ALL' && !!selectedLayout){
            <div
              class="d-flex justify-content-center align-items-center"
              [class.layout-selector-hidden]="!layoutExplorerVisible"
            >
              <!-- Select which region to get resolution from -->
              <app-layout-explorer
                [layout]="selectedLayout"
                canSelect="true"
                [(selected)]="selectedLayoutRegion"
                [boundingBox]="layoutExplorerSize + 'px'"
              ></app-layout-explorer>
            </div>
            }

            <!-- Layout Info -->
            @if(selectedLayout && previewResolutionSource !== 'SRC_GENERIC'){
            <div
              [class.layout-selector-hidden]="!layoutExplorerVisible"
              class="px-2 d-flex flex-column justify-content-center"
            >
              <div>
                {{ 'PREVIEW_LAYOUT' | translate }}:
                {{ selectedLayout?.name }}
              </div>
              <div class="pt-1">
                <!-- {{ 'PREVIEW_CHANNEL_RESOLUTION' | translate }}: -->
                {{ selectedLayout?.source?.canvas?.width }}px
                {{ selectedLayout?.source?.canvas?.height }}px
              </div>
              <br />
              <div>
                {{ 'PREVIEW_REGION' | translate }}:
                {{
                  selectedLayoutRegion === ''
                    ? ('REGION_CANVAS_ROOT' | translate)
                    : selectedLayoutRegion
                }}
              </div>
              <div class="pt-1">
                {{ 'PREVIEW_REGION_RESOLUTION' | translate }}:
                {{ zoneResolution.width }}px, {{ zoneResolution.height }}px
              </div>
            </div>
            }
          </div>
        </div>
        <!-- Layout Switcher -->
        <div
          class="py-2 d-flex justify-content-center cursor-pointer layout-button rounded-bottom"
          (click)="layoutExplorerVisible = !layoutExplorerVisible"
        >
          {{ 'PREVIEW_SETTINGS' | translate }}
          <span class="fs-4 mx-3">
            @if(layoutExplorerVisible){
            <i class="ri-arrow-down-circle-fill"></i>} @else {
            <i class="ri-arrow-up-circle-fill"></i>
            }
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
