// event can be any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trapLetters(event: any) {
  // allows only number and colon(:)
  const charCode = event.query ? event.query : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 58)) {
    return false;
  }

  return true;
}
