import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import {
  AssetItem,
  AssetType,
  ImageAsset,
  Maybe,
  PlaylistType,
  ScheduleDays,
  VideoAsset,
} from '@designage/gql';
import {
  ActivityStatusColor,
  ActivityStatus,
  ResizeCropMethods,
  TransitionEffects,
  ResizeCropMethodURIs,
} from '@desquare/enums';
import {
  IActivityStatus,
  IScheduledDayIndicator,
  IZoneResolution,
} from '@desquare/interfaces';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import {
  AssetItemIsHtml,
  AssetItemIsIFrame,
  AssetItemIsImage,
  AssetItemIsInteractiveTargetVideo,
  AssetItemIsVideo,
  getAssetItemStatus,
  getBlobURL,
  getDateValue,
  getOptimizedUrl,
  scheduleDaysFromGql,
} from '@desquare/utils';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbModal,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { DeleteFromPlaylistDialogComponent } from '../delete-from-playlist-dialog/delete-from-playlist-dialog.component';
import {
  PlaylistEditorService,
  AspectResizeCropService,
  PlaylistViewService,
  TransitionService,
} from '@desquare/services';
import { SubSink } from 'subsink';
import {
  trapLetters as _trapLetters,
  getActivityStatusColor,
} from '@desquare/utils';
import { cloneDeep, clone } from 'lodash';
import { ThumbnailPreviewDialogComponent } from '../thumbnail-preview-dialog/thumbnail-preview-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { DurationPipe } from '@desquare/components/common/src/pipe/duration/duration.pipe';
import { FormsModule } from '@angular/forms';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';

const SIZE_100MB = 100000000; // 100MB

type AssetItemContent = AssetItem & { webpUrl: string };

const inputDateFormat = 'YYYY-MM-DD';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbAccordionModule,
    NgbTooltip,
    TranslateModule,
    DurationPipe,
    NgbDropdownModule,
    DatepickerComponent,
  ],
  selector: 'app-content-row',
  templateUrl: './content-row.component.html',
  styleUrls: ['./content-row.component.scss'],
  providers: [DateProxyPipe],
})
export class ContentRowComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() readOnly = false;
  /** this indicates OPEN/CLOSED status */
  @Input() isSelected = false;
  @Input() isRowPinned = false;

  @Input() assetActivityStatus!: IActivityStatus;

  /** assetItem is the MEDIA */
  @Input() assetItem!: AssetItem;
  /** this is the SEQUENCE ID / DAYPART */
  @Input() assetId!: string;

  @Input() simpleUiActive!: boolean;

  @Output() rowClick = new EventEmitter<AssetItem>();
  @Output() editContent = new EventEmitter<{
    assetItem: AssetItem;
    editActions: boolean;
  }>();
  @Output() updateContentItem = new EventEmitter<AssetItem>();
  @Output() replaceContent = new EventEmitter<AssetItem>();
  @Output() deleteAssetItem = new EventEmitter<AssetItem>();
  @Output() duplicateContent = new EventEmitter<AssetItem>();

  @ViewChild('nameInput') nameInput!: ElementRef;

  private isFromCalendarUpdate = false;
  private isFromDaySelectorUpdate = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeDurationMoment: any;
  minimumTime = moment.unix(1).utc();
  resolutionUnitLabel = 'px';
  style = {};
  _campaignStart!: Maybe<moment.Moment>;
  _campaignEnd!: Maybe<moment.Moment>;
  scheduleDays!: IScheduledDayIndicator[];
  validDurationFormat = true;
  validCampaignStartFormat = true;
  validCampaignEndFormat = true;
  validDurationValue = true;
  isEditingName!: boolean;
  isStartDateChecked!: boolean;
  isEndDateChecked!: boolean;
  isResizeCropChecked!: boolean;
  campaignEndInput!: Maybe<string>;
  campaignStartInput!: Maybe<string>;
  timeDurationInput!: string;
  specialCharacters = ['(', ')', ' ', '-', '#', ':'];
  transitionEffect!: string;
  transitionDuration!: number;
  contentDuration!: number;
  showTransition!: boolean;
  showResizeCrop!: Boolean;
  transitionEffects!: string[];
  startTimeInputValue!: string;
  showEffectDurationError = false;
  resizeCrop!: string;
  resizeCropMethods!: string[];
  resizeCropMethod!: string;
  disableResizeCrop = false;
  disabled = false;

  readonly defaultScheduleDays: ScheduleDays = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    __typename: 'ScheduleDays',
  };

  defaultActivityStatus: IActivityStatus = {
    status: ActivityStatus.INACTIVE,
    style: {
      'background-color': ActivityStatusColor.INACTIVE,
    },
  };

  get activityStatus(): IActivityStatus {
    const activityStatus = getAssetItemStatus(
      this.assetItem,
      this.scheduleDays,
      this.assetActivityStatus,
      this.simulateDateTime?.toDate()
    );

    return {
      status: activityStatus,
      style: {
        'background-color': getActivityStatusColor(activityStatus),
      },
    };
  }

  assetType = AssetType;

  thumbnailUri!: Maybe<string>;
  previewUri!: Maybe<string>;
  htmlContent!: Maybe<string>;
  contents: AssetItemContent[] = [];
  simulateDateTime!: Maybe<moment.Moment>;
  htmlUrl!: Maybe<string>;
  isDurationPickerOpen = false;
  trapLetters = _trapLetters;

  private getDateInstance(input: Maybe<moment.Moment | string>): Maybe<Date> {
    try {
      if (!input) {
        return null;
      } else {
        return getDateValue(input);
      }
    } catch {
      return null;
    }
  }

  get campaignStartDate() {
    return this.getDateInstance(this.assetItem.campaignStart);
  }

  get campaignEndDate() {
    return this.getDateInstance(this.assetItem.campaignEnd);
  }

  get isImage() {
    // original (deprecated)
    // assetItem.type === assetType.Image
    return AssetItemIsImage(this.assetItem);
  }

  get isVideo() {
    // assetItem.type === assetType.Video
    return AssetItemIsVideo(this.assetItem);
  }

  get isHtml() {
    // assetItem.type === assetType.Html
    return AssetItemIsHtml(this.assetItem);
  }

  get isIFrame() {
    return AssetItemIsIFrame(this.assetItem);
  }

  get isInteractive() {
    return this.playlistType === PlaylistType.Interactive;
  }

  get isInteractionTargetMedia() {
    return AssetItemIsInteractiveTargetVideo(this.assetItem);
  }

  get playlistType() {
    return this.playlistEditorService.playlistType;
  }

  constructor(
    private datePipe: DateProxyPipe,
    private modalService: NgbModal,
    private playlistEditorService: PlaylistEditorService,
    private transitionService: TransitionService,
    private arcService: AspectResizeCropService,
    private playlistViewService: PlaylistViewService
  ) {}

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initSubscriptions() {
    this.subs.sink = this.playlistEditorService.simulateDateTime.subscribe(
      (value: Maybe<moment.Moment>) => {
        this.simulateDateTime = value;
      }
    );
  }

  get title() {
    return this.assetItem.name;
  }

  //TODO: WHY THE FUCK is this called COLLAPSED???
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set timeDurationString(value: any) {
    this.assetItem.duration = value ?? 0;
  }

  get timeDurationString() {
    return this.assetItem.duration ?? 0;
  }

  /** returns a descriptive label of campaign start/end */
  get dateDuration() {
    const startDate =
      this.assetItem.campaignStart && this.validCampaignStartFormat
        ? this.transformDate(this.assetItem.campaignStart)
        : '';
    const endDate =
      this.assetItem.campaignEnd && this.validCampaignEndFormat
        ? this.transformDate(this.assetItem.campaignEnd)
        : '';

    return startDate && endDate
      ? startDate + ' >> ' + endDate
      : startDate
      ? startDate + ' >> '
      : endDate
      ? '>> ' + endDate
      : '';
  }

  get campaignStart() {
    return this.campaignStartDate ? moment(this.campaignStartDate) : null;
  }

  get campaignEnd() {
    return this.campaignEndDate ? moment(this.campaignEndDate) : null;
  }

  private getUri(resolution?: IZoneResolution): Maybe<string> {
    switch (this.assetItem.type) {
      case AssetType.Video: {
        const videoThumbnail = `${this.uri?.substring(
          0,
          this.uri.lastIndexOf('.')
        )}.webp`;
        return getOptimizedUrl(AssetType.Image, videoThumbnail, resolution);
      }
      case AssetType.Image: {
        const resizeCropMethod =
          this.assetItem.__typename === 'ImageAsset'
            ? this.assetItem.resizeCropMethod?.uri
            : ResizeCropMethodURIs.PAD_URI;
        return 'uri' in this.assetItem && this.assetItem.uri
          ? getOptimizedUrl(
              AssetType.Image,
              this.assetItem.uri,
              resolution,
              resizeCropMethod
            )
          : '';
      }

      default:
        return null;
    }
  }

  private getHtmlUrl() {
    return this.htmlContent ? getBlobURL(this.htmlContent, 'text/html') : '';
  }
  get uri() {
    return 'uri' in this.assetItem ? this.assetItem.uri : '';
  }
  get thumbnailUrl() {
    switch (this.assetItem.type) {
      case AssetType.Image:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });
        break;
      case AssetType.Video:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });
        break;
      default:
        return '';
    }
  }

  private initValues() {
    this.contentDuration = (this.assetItem.duration || 0) / 1000;
    this.htmlContent =
      'htmlContent' in this.assetItem ? this.assetItem.htmlContent : null;
    this.transitionEffects = this.transitionService.getTransitions();
    this.resizeCropMethods = this.arcService.getResizeCropMethods();

    switch (this.assetItem.type) {
      case AssetType.Image:
        // Pass only the height for now.

        this.showResizeCrop = true;
        this.showTransition = true;
        if (this.assetItem.__typename === 'ImageAsset') {
          this.transitionEffect =
            this.assetItem.transitionEffect?.name?.toUpperCase() ||
            TransitionEffects.CUT;
          this.transitionDuration =
            this.assetItem.transitionEffect?.duration || 0;
          this.transitionDuration = this.transitionDuration / 1000; // stored in ms
          this.resizeCropMethod =
            this.assetItem.resizeCropMethod?.name?.toUpperCase() ||
            ResizeCropMethods.NONE_BEAUTY;
        }
        break;
      case AssetType.Video:
        // Pass only the height for now.
        this.showResizeCrop = true;
        this.showTransition = false;
        if (this.assetItem.__typename === 'VideoAsset') {
          const mediaSize = this.assetItem.media?.metadata?.bytes || 0; // bytes
          if (mediaSize <= SIZE_100MB) {
            this.resizeCropMethod =
              this.assetItem.resizeCropMethod?.name?.toUpperCase() ||
              ResizeCropMethods.NONE_BEAUTY;
          } else {
            this.resizeCropMethod = '';
            this.disableResizeCrop = true;
          }
        }
        break;
      case AssetType.Html:
        this.htmlUrl = this.getHtmlUrl();
        this.showResizeCrop = false;
        this.showTransition = false;
        break;
      case AssetType.Iframe:
        this.showResizeCrop = false;
        this.showTransition = false;
        break;
      default:
        throw new Error(`Unidentified asset item type ${this.assetItem.type}`);
    }
    this.setResizeCropCheckbox();

    if (!this.assetItem.days) {
      this.assetItem.days = cloneDeep(this.defaultScheduleDays);
    }

    this.isStartDateChecked = this.assetItem.campaignStart ? true : false;
    this.isEndDateChecked = this.assetItem.campaignEnd ? true : false;

    this.scheduleDays = this.extractDays(this.assetItem.days);

    this.timeDurationMoment = moment.unix(this.timeDurationString / 1000).utc();

    this.timeDurationInput = moment(this.timeDurationMoment).format('HH:mm:ss');
    this.campaignStartInput = this.campaignStart
      ? this.campaignStart?.format('YYYY-MM-DD')
      : '';
    this.campaignEndInput = this.campaignEnd
      ? this.campaignEnd?.format('YYYY-MM-DD')
      : '';
  }

  private transformDate(dateString: string) {
    return this.datePipe.transform(dateString, 'shortDate');
  }

  private getDayIndicatorStyle(isSet: boolean): Record<string, string> {
    return {
      'background-color': isSet ? '#178863' : '',
      border: isSet ? '1px solid #178863' : '1px solid #f1556c',
    };
  }

  private extractDays(days: ScheduleDays) {
    return scheduleDaysFromGql(days, this.getDayIndicatorStyle);
  }

  onRowPin() {
    if (this.assetItem.id) {
      this.isRowPinned = !this.isRowPinned;
      this.playlistViewService.setAssetContentPinned(
        this.assetId,
        this.assetItem.id,
        this.isRowPinned
      );
      this.playlistEditorService.closePickers.emit();
    }
  }

  onRowClick() {
    if (this.assetItem.id) {
      this.isSelected = !this.isSelected;
      this.playlistViewService.setContentOpenStatus(
        this.assetId,
        this.assetItem.id,
        this.isSelected
      );
      this.playlistEditorService.closePickers.emit();
    }
  }

  setCampaignStart(reset?: boolean) {
    if (reset) {
      this.campaignStartInput = this.isStartDateChecked
        ? moment(new Date()).format(inputDateFormat)
        : '';

      if (!this.isStartDateChecked) {
        this.assetItem.days = cloneDeep(this.defaultScheduleDays);
        this.scheduleDays = this.extractDays(this.assetItem.days);
      }
    }
    console.log('setCampaignStart', this.campaignStartInput);
    if (this.campaignStartInput) {
      this.validCampaignStartFormat = moment(
        this.campaignStartInput,
        inputDateFormat,
        true
      ).isValid();
      const date = this.validCampaignStartFormat
        ? new Date(this.campaignStartInput).toISOString().split('.')[0]
        : null;

      console.log('setCampaignStart date', date);

      this.assetItem.campaignStart = date || null; // ?.toISOString() || '';
    } else {
      this.campaignStartInput = null;
      this.validCampaignStartFormat = true;
      this.assetItem.campaignStart = null;
    }

    this.campaignStartInput = this.campaignStart
      ? this.campaignStart?.format(inputDateFormat)
      : '';

    this.emitSelectedAssets();
  }
  onCampaignStartClose(date?: Maybe<string>) {
    if (date) this.campaignStartInput = date;

    console.log('onCampaignStartClose', date);
    this.isFromCalendarUpdate = true;

    this.setCampaignStart();
    this.isFromCalendarUpdate = false;
    this.playlistEditorService.setSequenceValidity();
  }

  setCampaignEnd(reset?: boolean) {
    if (reset) {
      this.campaignEndInput = this.isEndDateChecked
        ? moment(new Date()).format(inputDateFormat)
        : '';
      if (!this.isEndDateChecked) {
        this.assetItem.days = cloneDeep(this.defaultScheduleDays);
        this.scheduleDays = this.extractDays(this.assetItem.days);
      }
    }
    if (this.campaignEndInput) {
      this.validCampaignEndFormat = moment(
        this.campaignEndInput,
        inputDateFormat,
        true
      ).isValid();
      const date = this.validCampaignEndFormat
        ? `${
            new Date(this.campaignEndInput).toISOString().split('T')[0]
          }T23:59:59`
        : null;

      console.log('setCampaignEnd date', date);

      this.assetItem.campaignEnd = date;
    } else {
      this.campaignEndInput = null;
      this.validCampaignEndFormat = true;
      this.assetItem.campaignEnd = null;
    }

    this.campaignEndInput = this.campaignEnd
      ? this.campaignEnd?.format(inputDateFormat)
      : '';

    this.emitSelectedAssets();
  }
  onCampaignEndClose(date?: Maybe<string>) {
    if (date) this.campaignEndInput = date;

    this.isFromCalendarUpdate = true;

    this.setCampaignEnd();

    this.isFromCalendarUpdate = false;
    this.playlistEditorService.setSequenceValidity();
  }

  switchDayStatus(index: number) {
    this.isFromDaySelectorUpdate = true;
    const scheduledDay = this.scheduleDays[index];

    scheduledDay.isSet = !this.scheduleDays[index].isSet;
    scheduledDay.style = this.getDayIndicatorStyle(scheduledDay.isSet);

    if (this.assetItem.days) {
      this.assetItem.days[scheduledDay.key] = scheduledDay.isSet;
    }

    this.emitSelectedAssets();
    this.isFromDaySelectorUpdate = false;
  }

  onBlur() {
    this.emitSelectedAssets();
  }

  onDelete() {
    const modal = this.modalService.open(DeleteFromPlaylistDialogComponent);
    modal.componentInstance.messagePrompt = 'DELETE_CONTENT_PROMPT';
    modal.result
      .then(() => {
        this.deleteAssetItem.emit(this.assetItem);
      })
      .catch(() => {});
  }

  onEditContent(editActions: boolean = false) {
    if (this.assetItem)
      this.editContent.emit({
        assetItem: this.assetItem,
        editActions: editActions,
      });
  }
  onDisableSwitch() {
    this.assetItem.disabled = !this.assetItem.disabled;
    this.emitSelectedAssets();
    console.log('onDisableSwitch', this.assetItem);
  }

  onEditInteractiveActions() {
    if (this.assetItem)
      this.editContent.emit({
        assetItem: this.assetItem,
        editActions: true,
      });
  }

  onReplaceContent() {
    this.replaceContent.emit(this.assetItem);
  }

  onDuplicate() {
    this.duplicateContent.emit(this.assetItem);
  }

  onDurationInputClose() {
    // if (this.isDurationPickerOpen) {
    this.isDurationPickerOpen = false;
    this.validDurationFormat = moment(
      this.timeDurationInput,
      'HH:mm:ss',
      true
    ).isValid();

    if (this.validDurationFormat) {
      this.validDurationValue = this.timeDurationInput !== '00:00:00';
      const rawTime = moment(this.timeDurationInput, 'HH:mm:ss');
      this.timeDurationMoment = moment.isMoment(rawTime) ? rawTime : null;
      this.timeDurationString =
        this.timeDurationMoment?.valueOf() -
        this.timeDurationMoment.startOf('day').valueOf();
    } else {
      this.timeDurationString = null;
    }
    console.log('timeDurationString', this.timeDurationString);

    this.emitSelectedAssets();
    this.playlistEditorService.setSequenceValidity();
    this.contentDuration = (this.assetItem.duration || 0) / 1000;
    // }
  }

  onDurationInputOpen() {
    // prevent (close) from triggering when clicking anywhere in the page although picker is not open
    this.isDurationPickerOpen = true;
  }

  emitSelectedAssets() {
    this.playlistEditorService.sequenceTouch.emit(true);
    this.playlistEditorService.emitEditingSequencesChange();
  }

  resetDpCampaignEndDateBlocks() {
    const campaignEndClone = clone(this.campaignEndInput);

    this.campaignEndInput = null;

    setTimeout(() => {
      this.campaignEndInput =
        this.campaignStartInput &&
        campaignEndClone &&
        this.campaignStartInput > campaignEndClone
          ? this.campaignStartInput
          : campaignEndClone;
    });
  }

  resetDpCampaignStartDateBlocks() {
    const campaignStartClone = clone(this.campaignStartInput);

    this.campaignStartInput = null;

    setTimeout(() => {
      this.campaignStartInput = campaignStartClone;
    });
  }

  openPreviewDialog() {
    const modalRef = this.modalService.open(ThumbnailPreviewDialogComponent, {
      windowClass: 'custom-centered-modal',
      size: 'lg',
    });

    modalRef.componentInstance.assetItem = this.assetItem;

    if (this.assetItem.__typename === 'VideoAsset') {
      this.playlistEditorService.previewPlayToggleTriggered.emit(false);
    }
  }

  toggleEdit() {
    this.isEditingName = false;
  }

  editName() {
    this.isEditingName = true;
    setTimeout(() => {
      this.nameInput.nativeElement.select();
    });
  }

  onCheckBoxStartDate() {
    this.isStartDateChecked = !this.isStartDateChecked;
    console.log('oncheck');

    this.setCampaignStart(true);

    this.playlistEditorService.setSequenceValidity();
  }

  onCheckBoxEndDate() {
    this.isEndDateChecked = !this.isEndDateChecked;
    this.setCampaignEnd(true);

    this.playlistEditorService.setSequenceValidity();
  }

  onCheckBoxResizeCrop() {
    this.isResizeCropChecked = !this.isResizeCropChecked;
    if (!this.isResizeCropChecked) {
      this.setResizeCrop(ResizeCropMethods.NONE_BEAUTY);
    }
  }

  get notAllDays() {
    return this.scheduleDays.some((day) => day.isSet === false);
  }

  setSelectedTransition(selectedTransition: string) {
    if (this.assetItem && this.assetItem.__typename === 'ImageAsset') {
      this.transitionDuration =
        selectedTransition !== TransitionEffects.CUT ? 1 : 0;
      this.transitionEffect = selectedTransition;
      this.assetItem.transitionEffect = {
        name: this.transitionEffect,
        duration: this.transitionDuration * 1000,
        __typename: 'TransitionEffect',
      };
      this.emitSelectedAssets();
    }
  }

  setTransitionDuration(duration: number) {
    if (duration > this.contentDuration) {
      return;
    }
    if (this.assetItem && this.assetItem.__typename === 'ImageAsset') {
      this.transitionDuration = duration;
      this.assetItem.transitionEffect = {
        name: this.transitionEffect,
        duration: this.transitionDuration * 1000,
        __typename: 'TransitionEffect',
      };
      this.emitSelectedAssets();
    }
  }
  setResizeCropCheckbox() {
    if (this.resizeCropMethod === ResizeCropMethods.NONE_BEAUTY || null) {
      this.isResizeCropChecked = false;
    } else {
      this.isResizeCropChecked = true;
    }
  }
  setResizeCrop(selectedResizeCrop: string) {
    // TODO: revisit later when we have typescript v4.9
    // the type assertions below can be improved by changing them
    // to use the 'satisfies' operator instead

    if (this.assetItem && this.assetItem.type === AssetType.Image) {
      const imageAssetItem = this.assetItem as ImageAsset;

      this.resizeCropMethod = selectedResizeCrop;
      this.setResizeCropCheckbox();
      const methodUri = this.arcService.getResizeCropUri(
        AssetType.Image,
        this.resizeCropMethod
      );
      imageAssetItem.resizeCropMethod = {
        name: this.resizeCropMethod,
        uri: methodUri,
        __typename: 'ResizeCropMethod',
      };
      this.emitSelectedAssets();
      this.isFromCalendarUpdate = false;
      this.playlistEditorService.setSequenceValidity();
    }
    if (this.assetItem && this.assetItem.type === AssetType.Video) {
      const videoAssetItem = this.assetItem as VideoAsset;

      this.resizeCropMethod = selectedResizeCrop;
      this.setResizeCropCheckbox();
      const methodUri = this.arcService.getResizeCropUri(
        AssetType.Video,
        this.resizeCropMethod
      );
      videoAssetItem.resizeCropMethod = {
        name: this.resizeCropMethod,
        uri: methodUri,
        __typename: 'ResizeCropMethod',
      };
      this.emitSelectedAssets();
      this.isFromCalendarUpdate = false;
      this.playlistEditorService.setSequenceValidity();
    }
  }
}
